import * as React from 'react';
import { connect } from 'react-redux';
import Redux, { bindActionCreators } from 'redux';
import { ModalDialog, ButtonGroup, Button } from '@clublabs/ace-component-library';
import { tagCancelledPolicyPayBillAction } from './TaggingHelper';
import { RootState } from '../../redux/store/rootReducer';
import { setErrorState } from '../../redux/store/Actions/error.actions';
import { TaggingSource } from 'shared';

export interface RequiredProps {
  onClick: (hasLapseAccidents: boolean) => void;
  openModal: boolean;
  description?: any;
  actionMessage?: any;
  taggingSource: TaggingSource;
}

const mapStateToProps = (rootState: RootState) => {
  return {
    userState: rootState.userState,
    analytics: rootState.analyticsState,
    errors: rootState.errorState,
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return bindActionCreators(
    {
      setErrorState,
    },
    dispatch
  );
};

export type CancelledPolicyModalProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps;

const CancelledPolicyModal: React.FunctionComponent<CancelledPolicyModalProps> = (props: CancelledPolicyModalProps) => {
  const onClose = () => {
    return true;
  };

  const onClickhandler = (value: boolean, taggingSource: TaggingSource) => {
    props.onClick(value);

    //tag cancel policy yes/no
    const analytics = props.analytics;
    if (analytics) {
      value
        ? tagCancelledPolicyPayBillAction(analytics.model, taggingSource, 'yes')
        : tagCancelledPolicyPayBillAction(analytics.model, taggingSource, 'no');
    }
  };

  const buttonGroup = (
    <ButtonGroup>
      <Button id="btnCancelledYes" color="primary" onClick={() => onClickhandler(true, props.taggingSource)}>
        Yes
      </Button>
      <Button id="btnCancelledNo" color="primary" onClick={() => onClickhandler(false, props.taggingSource)}>
        No
      </Button>
    </ButtonGroup>
  );

  return (
    <ModalDialog
      actionButtons={buttonGroup}
      description={props.description}
      actionMessage={props.actionMessage}
      onClose={() => onClose}
      openModal={props.openModal}
      transitionDuration={200}
      id="cancelledPolicyModal"
      disableBackdropClick={false}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelledPolicyModal);
