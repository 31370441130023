import {
  CustomerDetailsResponseService,
  CustomerDetails,
  CustomerDetailsProduct,
} from '../models/customerDetailsModel';
import { ProductType } from '../contracts/ProductType';
import { MembershipTypeTemp, RoleCodes } from '../common/enums';
import { TaggingModel } from '../models/taggingModel';

export const taggingMapper = (customerDetailsResponseService: CustomerDetailsResponseService): TaggingModel => {
  const { customerDetails } = customerDetailsResponseService;
  const membershipProduct = customerDetails.products.find(
    (product) => product.type?.toUpperCase() === ProductType.MEMBERSHIP
  );

  const model: TaggingModel = {
    channel: 'my account', // hard-coded
    clubcode: 'string', // provided by client
    bills_due: mapProductsBillDue(customerDetails.products),
    cta: 'string', // provided by client
    flow: (membershipProduct?.isInRenewal || membershipProduct?.isInArrears) ? 'renewal' : 'midterm',
    isauthenticated: true, // hard-coded
    mem_myaccountalert: membershipProduct?.pitReturnMessage ?? '',
    mem_autopay: Boolean(membershipProduct?.hasAutoPay),
    mem_billplan: membershipProduct?.billPlan?.toLowerCase() ?? '',
    mem_level: customerDetails.membershipInfo ? mapMembershipLevel(customerDetails.membershipInfo.membershipType) : '',
    mem_role: customerDetails.membershipInfo ? mapRoleCode(customerDetails.membershipInfo.roleCode) : '',
    page: 'string', // provided by client
    sessionId: 'string', // provided by client
    customerid: customerDetails.customerId,
    state: mapMembersResidenceState(customerDetails),
    error: customerDetails.emptyAuthorizations ? 'insurance authorization flags not received' : '',
  };

  return model;
};

const mapMembershipLevel = (membershipType: string): string => {
  if (membershipType) {
    const membershipEnum = MembershipTypeTemp.findByCode(membershipType);
    const membershipLevel: string = membershipEnum?.description?.trim()?.toLowerCase();
    return (membershipLevel && membershipLevel !== 'unknown') ? membershipLevel : '';
  }

  return '';
};

const mapRoleCode = (membershipRoleCode: string): string => {
  let roleCode = '';

  switch (membershipRoleCode) {
    case RoleCodes.PRIMARY:
      roleCode = 'primary';
      break;
    case RoleCodes.ADULT_ASSOCIATE:
      roleCode = 'associate';
      break;
    case RoleCodes.DEPENDENT_ASSOCIATE_01:
    case RoleCodes.DEPENDENT_ASSOCIATE_02:
    case RoleCodes.DEPENDENT_ASSOCIATE_03:
    case RoleCodes.DEPENDENT_ASSOCIATE_04:
    case RoleCodes.DEPENDENT_ASSOCIATE_05:
      roleCode = 'dependent';
      break;
  }

  return roleCode;
};

const mapProductsBillDue = (products: CustomerDetailsProduct[]): string[] => {
  const billsDue: string[] = [];

  const productBillsDue = products.filter((product) => product && product.hasMinimumAmountDue);

  productBillsDue.forEach((product) => {
    if (product && product.type) {
      billsDue.push(product.type.toLowerCase());
    }
  });

  return billsDue;
};

const mapMembersResidenceState = (customerDetails: CustomerDetails): string => {
  let state = '';
  if (
    customerDetails &&
    customerDetails.person &&
    customerDetails.person.residenceAddress &&
    customerDetails.person.residenceAddress.address &&
    customerDetails.person.residenceAddress.address.state
  ) {
    state = customerDetails.person.residenceAddress.address.state;
  }
  return state.toLowerCase();
};
