import { TaggingModel } from '../models/taggingModel';
import { AnalyticsResponseApp } from '../models/analyticsModel';
import { httpResponseHandlerFixture } from './httpResponseHandlerFixture';
import { aceMetaFixture } from './aceMetaFixture';

export const taggingFixture: TaggingModel = {
  channel: 'my account',
  clubcode: 'string',
  bills_due: ['membership', 'watercraft', 'home', 'auto'],
  cta: 'string',
  flow: 'renewal',
  isauthenticated: true,
  mem_myaccountalert: 'string',
  mem_autopay: true,
  mem_billplan: 'mp',
  mem_level: 'plus',
  mem_role: 'primary',
  page: 'string',
  sessionId: 'string',
  customerid: '116310020099992',
  state: 'tx',
};

export const taggingFixtureResponseApp: AnalyticsResponseApp = {
  aceMeta: aceMetaFixture,
  httpResponseHandler: httpResponseHandlerFixture,
  analytics: taggingFixture,
};
