import * as actions from '../Actions/analytics.actions';
import { TaggingModel } from 'shared';

export interface AnalyticsState {
  model: TaggingModel;
}

export const initialState = {
  model: {
    channel: '',
    clubcode: '',
    bills_due: [],
    flow: '',
    isauthenticated: false,
    mem_myaccountalert: '',
    mem_autopay: false,
    mem_billplan: '',
    mem_level: '',
    mem_role: '',
    page: '',
    sessionId: '',
    customerid: '',
    state: '',
  },
};

export const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ANALYTICS:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
