import { AceMeta } from 'shared/contracts/AceMeta';

export enum LogType {
  METRICS = "METRICS",
  WEB = "WEB",
}
export interface LoggerRequestApp {
  message: string;
  level: string;
  logType: LogType;
  aceMeta: AceMeta;
  stack?: unknown;
  data: {
    [key: string]: any;
  } | string;
}

export type LoggerContractApp = (request: LoggerRequestApp) => Promise<void>;
