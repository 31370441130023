interface StyleProps {
  root: any;
  content: any;
  messageContainer: any;
  refIdContainer: any;
  iconContainer: any;
  title: any;
  button: any;
}

const errorCompStyles = (): StyleProps => ({
  root: {
    '& * .Headline': {
      color: 'rgb(218, 41, 28)',
    },
  },
  content: {
    display: 'flex',
    paddingBottom: 25,
  },
  messageContainer: {
    paddingLeft: 8,
    display: 'flex',
  },
  refIdContainer: {
    paddingLeft: 8,
    marginBottom: 25,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    color: 'red',
  },
  button: {
    width: '100%',
  },
});

export default errorCompStyles;
