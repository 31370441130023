import {
  ConfigContractApp,
  ConfigRequestApp,
  ConfigResponseApp,
  configFixtureApp,
  configFixture,
  configHeaderFooterFixtureCA,
  configHeaderFooterFixtureEC,
  configBillPlanDescriptions,
} from 'shared';

import { RemoteGetFull } from '../fetchers';

export const ConfigRemoteApp = (endpoint: string): ConfigContractApp => {
  return async (request) => {
    const serviceResp = await RemoteGetFull<ConfigRequestApp, ConfigResponseApp>(endpoint)(request);
    const response = {
      aceMeta: {
        region: serviceResp.headers?.['x-ace-aws-region'],
        testRegion: serviceResp.headers?.['x-ace-test-region'],
        correlationId: serviceResp.headers?.['x-ace-correlation-id'],
        sessionId: serviceResp.headers?.['x-ace-session-id'],
        requestId: serviceResp.headers?.['x-ace-request-id'],
      }, 
      ...serviceResp.data,
    };
    return response;
  }
}

export const ConfigStubApp = (_endpoint: string): ConfigContractApp => {
  return async (request) => {
    let response = configFixtureApp;
    if (request) {
      switch (request.club) {
        case '004':
          response = {
            ...configFixture['004'],
            ...configHeaderFooterFixtureCA,
            ...configFixtureApp,
            ...configBillPlanDescriptions,
          };
          break;
        case '252':
          response = {
            ...configHeaderFooterFixtureEC,
            ...configFixtureApp,
            ...configBillPlanDescriptions,
            ...configFixture['252'],
          };
          break;
      }
    }
    return { data: response };
  };
};
