import * as React from 'react';
import { ModalDialog, ButtonGroup, Button } from '@clublabs/ace-component-library';

export interface SecurityModalProps {
  onClick: (open: boolean) => void;
  openModal: boolean;
  description?: any;
  actionMessage?: any;
}

const SecurityModal: React.FunctionComponent<SecurityModalProps> = ({ onClick, openModal, description }) => {
  return (
    <ModalDialog
      actionButtons={
        <ButtonGroup>
          <Button id="securityModalClose" color="primary" onClick={() => onClick(false)}>
            OK
          </Button>
        </ButtonGroup>
      }
      description=""
      actionMessage={description}
      onClose={() => onClick(false)}
      openModal={openModal}
      transitionDuration={200}
      disableBackdropClick={false}
      id="securityModal"
    />
  );
};

export default SecurityModal;
