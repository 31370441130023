import * as actions from '../Actions/modal.actions';
import { SecurityModalType, TaggingSource, FormPostKeyValue, Notification } from 'shared';

export interface ModalState {
  securityModal: boolean;
  securityModalDescription: string | JSX.Element;
  securityModalMessage: string;
  securityModalType: SecurityModalType;
  cancelledPolicyModal: boolean;
  cancelledPolicyDescription: string;
  cancelledPolicyMessage: string;
  taggingSource: TaggingSource;
  extraFields: FormPostKeyValue[];
  payBillUrl: string;
  cancelPaymentModal: boolean;
  cancelPaymentConfirmationModal: boolean;
  cancelPaymentSuccess: boolean;
  cardData?: Notification;
}

export const initialState: ModalState = {
  securityModal: false,
  securityModalDescription: '',
  securityModalMessage: '',
  securityModalType: SecurityModalType.MEMBERSHIP,
  cancelledPolicyModal: false,
  cancelledPolicyDescription: '',
  cancelledPolicyMessage: '',
  cancelPaymentModal: false,
  cancelPaymentConfirmationModal: false,
  cancelPaymentSuccess: false,
  taggingSource: TaggingSource.NotificationCard,
  extraFields: [],
  payBillUrl: ''
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_MODAL:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
