import React from 'react';
import { ACEPrimaryTheme } from '@clublabs/ace-component-library';
import { withStyles, WithStyles } from '@material-ui/core';
import maintenanceStyles from './MaintenanceStyles';

export interface MaintenanceProps extends WithStyles<typeof maintenanceStyles> {
  helpPhoneNumber: string;
  roadsideUrl: string;
  headerLogoUrl: string;
}

export const Maintenance = (props: MaintenanceProps) => {
  return (
    <ACEPrimaryTheme>
      <div className={props.classes.header}>
        <img src={props.headerLogoUrl} height="56px;" title="Home" alt="AAA" />
      </div>
      <div className={props.classes.content}>
        <h3 className={props.classes.title}>Thank you for visiting AAA.</h3>

        <div className={props.classes.text}>
          <p>
            ‌This service is undergoing routine maintenance (Sundays: 3-5 a.m. Pacific, all other days: 2:30-3:30 a.m.
            Pacific).
          </p>
          <p>We apologize for the inconvenience and appreciate your patience.</p>
          <p>
            If you need Roadside Assistance, please call <span>{props.helpPhoneNumber}</span> or{' '}
            <u>
              <a href={props.roadsideUrl}>request service online</a>
            </u>{' '}
            or through the Auto Club App.
          </p>
        </div>
      </div>
    </ACEPrimaryTheme>
  );
};

export default withStyles(maintenanceStyles, { withTheme: true })(Maintenance);
