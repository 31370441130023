import {
  InsuranceOptionsContractApp,
  InsuranceOptionsRequestApp,
  InsuranceOptionsResponseApp,
  insuranceOptionsResponseAppFixture
} from 'shared';
import { RemotePost } from '../fetchers';

export const InsuranceOptionsRemoteApp = (endpoint: string): InsuranceOptionsContractApp =>
  RemotePost<InsuranceOptionsRequestApp, InsuranceOptionsResponseApp>(endpoint);

export const InsuranceOptionsStubApp =
  (endpoint: string): InsuranceOptionsContractApp => async () => insuranceOptionsResponseAppFixture;
