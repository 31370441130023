const maintenanceStyles = () => ({
  header: {
    backgroundColor: '#eaeaea',
    padding: '5vh 0 0 10vh',
  },
  content: {
    padding: '4vh 10vh 0 10vh',
  },
  title: {
    color: '#32567d',
    fontSize: 20,
    'font-weight': 'bold',
  },
  text: {
    '& p': {
      fontSize: 22,
      lineHeight: '50px',
      color: '#8d8c84',
    },
  },
});

export default maintenanceStyles;
