import LogCannon from '@clublabs/log-cannon';
import { AxiosError } from 'axios';
import { utility, ServiceError, getCircularReplacer } from 'utils';

/**
 *  This function handles errors for remote calls, which can be AppLayer, Cache, Config or Enterprise API
 *
 *  The correlationId is available via the header 'X-Correlation-Id'
 *  (see fetchers.correlationIdHelper)
 */
export const handleServiceResponseError = <T>(error: AxiosError | Error): Promise<T> => {
  const serviceError: ServiceError = new ServiceError('High', '', 'Axios Error', 'Node', [], 'SomeId', 'CustomerId');

  if (!error) {
    return Promise.reject(serviceError);
  }

  const axiosError = error as AxiosError; //AxiosError extends Error

  if (axiosError.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    serviceError.ComponentErrorCode = axiosError.response.status.toString();
    if (axiosError.response.data) {
      if (axiosError.response.data.error) {
        serviceError.ErrorMessage = axiosError.response.data.error; // { error: message } is what DP will return
      } else {
        serviceError.ErrorMessage = JSON.stringify(axiosError.response.data);
      }
    }
  } else if (axiosError.request) {
    // The request was made but no response was received
    serviceError.ErrorMessage = `No response from ${utility.stripUrl(
      axiosError.config.url
    )}. errorMessage: ${JSON.stringify(axiosError.message)}.`;
    // `axiosError.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    serviceError.ErrorMessage += JSON.stringify(axiosError.request, getCircularReplacer());
    serviceError.ComponentErrorCode = axiosError.code || axiosError.message;
  } else if (axiosError.message) {
    serviceError.ErrorMessage = axiosError.message;
  }

  // serviceError.CustomerId = httpContext.get('customerId') != null ? httpContext.get('customerId') : 'no-customer-id';
  serviceError.CustomerId = 'no-customer-id'; // httpContext not available on client, figure out another way
  serviceError.SessionId =
    axiosError.config && axiosError.config.headers ? axiosError.config.headers['X-Correlation-Id'] : undefined;

  LogCannon.error('serviceError', serviceError);
  return Promise.reject(serviceError);
};
