import {
  CustomerV2ContractApp,
  customerDetailsWithClaimsFixture,
  CustomerV2RequestApp,
  CustomerV2ResponseApp,
  aceMetaFixture,
  taggingFixture,
} from 'shared';
import { RemotePost } from '../fetchers';

export const CustomerV2RemoteApp = (endpoint: string): CustomerV2ContractApp =>
  RemotePost<CustomerV2RequestApp, CustomerV2ResponseApp>(endpoint);

export const CustomerV2StubApp =
  (_endpoint: string): CustomerV2ContractApp =>
  async () => ({
    aceMeta: aceMetaFixture,
    data: {
      customerDetails: customerDetailsWithClaimsFixture,
      awarenessCards: [],
      notifications: [],
    },
    analytics: taggingFixture,
  });
