import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import { handleServiceResponseError } from './handleServiceResponseError';
import { logger, WebUtils } from 'utils';
import { getEnvironmentFromHost } from '@clublabs/log-cannon';
import { v4 as uuid } from 'uuid';

export const httpInstance = axios.create();
httpInstance.defaults.headers.common = {};
httpInstance.defaults.headers.common['Accept'] = 'application/json';
httpInstance.defaults.headers.common['Content-Type'] = 'application/json';

httpInstance.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (error: any) => {
    const loginUrl = sessionStorage.getItem('loginUrl');
    //redirect to login either network error or there's error response and response status is 401 or 403
    if (
      loginUrl &&
      // (error.message === 'Network Error' ||
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
      // )
    ) {
      WebUtils.externalLinkHandler(loginUrl, false, true).catch(() => {});
    }
    return Promise.reject(error);
  }
);

const logInterceptorInfo = (endpoint, payload) => {
  const dataRegion = sessionStorage.getItem('dataRegion');
  logger.info(`${endpoint}`, {
    function: 'logInterceptorInfo',
  });
  const env = getEnvironmentFromHost();
  if (env !== 'prod' && env !== 'stage') {
    if (dataRegion) {
      httpInstance.defaults.headers.common['x-ace-test-region'] = dataRegion;
    }
    // add debug here..., should only be turned on in lower environments or for troubleshooting
    logger.debug(`${JSON.stringify(payload)} `, { endpoint: endpoint });
  }
};

export const fetchers = {
  post: <Response>(endpoint, data?, config?: AxiosRequestConfig): AxiosPromise<Response> => {
    return httpInstance.post(endpoint, data, config);
  },
  get: <Response>(endpoint, config?: AxiosRequestConfig): AxiosPromise<Response> => {
    return config ? httpInstance.get(endpoint, config) : httpInstance.get(endpoint);
  },
  del: <Response>(endpoint, config?: AxiosRequestConfig): AxiosPromise<Response> => {
    return config ? httpInstance.delete(endpoint, config) : httpInstance.delete(endpoint);
  },
  put: <Response>(endpoint, data?: any, config?: AxiosRequestConfig): AxiosPromise<Response> => {
    return httpInstance.put(endpoint, data, config);
  },
};

export type AsyncFunction<I, O> = (inputs: I) => Promise<O>;

export function RemotePost<RequestBody, Response>(endpoint: string): AsyncFunction<RequestBody, Response> {
  return async (requestBody: RequestBody) => {
    logInterceptorInfo(endpoint, requestBody);
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'X-Ace-Correlation-Id': uuid(),
      },
    }
    try {
      const response = await fetchers.post<Response>(endpoint, requestBody, requestConfig);
      return response.data;
    } catch (error: any) {
      return handleServiceResponseError(error);
    }
  };
}

export type GetAsyncFunction<I, O> = (inputs?: I) => Promise<O>;

export function RemoteGet<RequestBody, Response>(endpoint: string): GetAsyncFunction<RequestBody, Response> {
  return async (requestBody?: RequestBody) => {
    logInterceptorInfo(endpoint, requestBody);
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'X-Ace-Correlation-Id': uuid(),
      },
    };
    try {
      if (requestBody != null) {
        requestConfig.params = requestBody;
        const response = await fetchers.get<Response>(endpoint, requestConfig);
        return response.data;
      } else {
        const response = await fetchers.get<Response>(endpoint, requestConfig);
        return response.data;
      }
    } catch (error: any) {
      return handleServiceResponseError(error);
    }
  };
}

export function RemoteGetFull<RequestBody, Response>(endpoint: string): GetAsyncFunction<RequestBody, AxiosResponse> {
  return async (requestBody?: RequestBody) => {
    logInterceptorInfo(endpoint, requestBody);
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'x-ace-correlation-id': uuid(),
      },
    };
    try {
      if (requestBody) {
        if ((requestBody as any).aceMeta) {
          requestConfig.headers['x-ace-correlation-id'] = (requestBody as any).aceMeta.correlationId ?? uuid();
        }
        requestConfig.params = requestBody;
        const response = await fetchers.get<AxiosResponse>(endpoint, requestConfig);
        return response;
      } else {
        const response = await fetchers.get<AxiosResponse>(endpoint, requestConfig);
        return response;
      }
    } catch (error: any) {
      return handleServiceResponseError(error);
    }
  };
}

export type DelAsyncFunction<I, O> = (inputs?: I) => Promise<O>;

export function RemoteDelete<RequestBody, Response>(endpoint: string): DelAsyncFunction<RequestBody, Response> {
  return async (requestBody?: RequestBody) => {
    logInterceptorInfo(endpoint, requestBody);
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'X-Ace-Correlation-Id': uuid(),
      },
    };
    try {
      if (requestBody != null) {
        requestConfig.params = requestBody;
        const response = await fetchers.del<Response>(endpoint, requestConfig);
        return response.data;
      } else {
        const response = await fetchers.del<Response>(endpoint, requestConfig);
        return response.data;
      }
    } catch (error: any) {
      return handleServiceResponseError(error);
    }
  };
}

export function RemotePut<RequestBody, Response>(endpoint: string): AsyncFunction<RequestBody, Response> {
  return async (requestBody: RequestBody) => {
    logInterceptorInfo(endpoint, requestBody);
    const requestConfig: AxiosRequestConfig = {
      headers: {
        'X-Ace-Correlation-Id': uuid(),
      },
    };
    try {
      const response = await fetchers.put<Response>(endpoint, requestBody, requestConfig);
      return response.data;
    } catch (error: any) {
      return handleServiceResponseError(error);
    }
  };
}
