import {
  UpdateUserPrefRequestApp,
  UpdateUserPrefResponseApp,
  UpdateUserPreferenceContractApp,
  httpResponseHandlerFixture,
} from 'shared';
import { RemotePost } from '../fetchers';

export const UpdateUserPreferenceRemoteApp = (endpoint: string): UpdateUserPreferenceContractApp =>
  RemotePost<UpdateUserPrefRequestApp, UpdateUserPrefResponseApp>(endpoint);

export const UpdateUserPreferenceStubApp =
  (endpoint: string): UpdateUserPreferenceContractApp =>
  async () => ({ httpResponseHandler: httpResponseHandlerFixture });
