import { handleMetrics, IMetrics } from './handleMetrics';

export class Timer {
  //TODO: clean up and refactor class
  
  time: number = new Date().getTime();

  source: string;

  sessionId: string;

  policyNumber: string;

  // MR-188 Guy's Logger, add boolean to identify logging source ? ? ?
  // For UI, logging should call Guy's Logger
  // For App, logging should use console.log
  // This flag should be passed to handleMetrics
  constructor(source, sessionId: string, policyNumber?: string) {
    this.source = source;
    this.sessionId = sessionId;
    this.policyNumber = policyNumber ?? 'no-policyNumber';
  }

  startTimer(): IMetrics {
    const now = new Date();
    this.time = now.getTime();

    const metric: IMetrics = {
      source: this.source,
      description: `${this.source}-Start`,
      sessionId: this.sessionId,
      start: now.toString(),
    };

    if (this.source === 'insuranceApi') metric.policyNumber = this.policyNumber;

    handleMetrics(metric);
    return metric;
  }

  endTimer(): IMetrics {
    const now = new Date();
    const metric: IMetrics = {
      source: this.source,
      description: `${this.source}-End`,
      end: now.toString(),
      responseTime: (now.getTime() - this.time) / 1000,
      sessionId: this.sessionId,
    };

    if (this.source === 'insuranceApi') metric.policyNumber = this.policyNumber;

    handleMetrics(metric);
    return metric;
  }
}
