import {
  BillingSummaryResponseEnterprise,
  BillingSummaryResponseService,
  BillingSummaryRequestService,
  BillingSummaryDetail,
  BillingSummary,
  ProductBillingSummary,
} from '../models/billingSummaryModel';

export const billingSummaryDetailMembershipFixture: BillingSummaryDetail = {
  productId: '252 12345678 00',
  productIdLast3Digits: ' 00',
  productType: 'Membership',
  productTypeDetail: 'PLUS',
  status: 'VALD',
  statusDescription: 'Valid',
  billPlan: 'AM',
  billPlanDescription: 'Monthly PayPlan',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '7.33',
  minimumDue: '7.33',
  isInRenewal: false,
  isInArrears: false,
  amount: '7.33',
  billingDate: '2021-12-24',
  accountType: 'C',
  last4Digits: '1234',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: true,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};

export const billingSummaryDetailWatercraftFixture: BillingSummaryDetail = {
  productId: 'TXW123456789',
  productIdLast3Digits: '789',
  productType: 'Watercraft',
  productTypeDetail: 'WTR',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXW3',
  billPlanDescription: 'Three(3) Payment Plan',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '8.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '8.33',
  billingDate: '2019-11-23',
  accountType: 'C',
  last4Digits: '1234',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};

export const billingSummaryDetailHomeFixture: BillingSummaryDetail = {
  productId: 'THO123456789',
  productIdLast3Digits: '789',
  productType: 'Home',
  productTypeDetail: 'HOB',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Three(3) Payment Plan',
  hasAutoPay: true,
  currentBillHasAutopay: false,
  totalBalance: '9.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '9.33',
  billingDate: '2019-11-23',
  accountType: '',
  last4Digits: '1234',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};

const billingSummaryDetailHomeFixtureTHO: BillingSummaryDetail = {
  productId: 'THO3456789',
  productIdLast3Digits: '789',
  productType: 'Home',
  productTypeDetail: 'HOB',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Three(3) Payment Plan',
  hasAutoPay: true,
  currentBillHasAutopay: false,
  totalBalance: '9.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '9.33',
  billingDate: '2019-11-23',
  accountType: '',
  last4Digits: '1234',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};

const billingSummaryDetailHomeFixtureTHOFullData: BillingSummaryDetail = {
  productId: 'THO3456789',
  productIdLast3Digits: '789',
  productType: 'Home',
  productTypeDetail: 'HOB',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Three(3) Payment Plan',
  hasAutoPay: true,
  currentBillHasAutopay: false,
  totalBalance: '9.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '9.33',
  billingDate: '2019-11-23',
  accountType: '',
  last4Digits: '1234',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
  lastPaymentAccountInfo: {
    accountType: 'E',
    accountLastFourDigits: '6989',
    accountHolderName: 'KAREN HAVRILLA',
    accountExpirationDate: '',
    cardType: '',
    cardDebitOrCreditIndicator: '',
  },
};

export const billingSummaryDetailAutoFixture: BillingSummaryDetail = {
  productId: 'TPA123456789',
  productIdLast3Digits: '789',
  productType: 'Auto',
  productTypeDetail: 'PPA',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'CAA9',
  billPlanDescription: 'Nine (9) Payment Plan',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '10.33',
  minimumDue: '10.33',
  isInRenewal: false,
  isInArrears: false,
  amount: '10.33',
  billingDate: '2022-02-09',
  accountType: 'D',
  last4Digits: '1234',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
};

const billingSummaryDetailAutoFixture2: BillingSummaryDetail = {
  productId: 'CAC123456789',
  productIdLast3Digits: '789',
  productType: 'Auto',
  productTypeDetail: 'PPA',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXMI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  currentBillHasAutopay: false,
  totalBalance: '10.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '10.33',
  billingDate: '2019-11-23',
  accountType: 'D',
  last4Digits: '1234',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};

export const billingSummaryDetailCondoFixture: BillingSummaryDetail = {
  productId: 'THO987654321',
  productIdLast3Digits: '789',
  productType: 'Home',
  productTypeDetail: 'CON',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '10.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '9.33',
  billingDate: '2019-11-23',
  last4Digits: '',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};

export const billingSummaryDetailTenantFixture: BillingSummaryDetail = {
  productId: 'THO3456789',
  productIdLast3Digits: '789',
  productType: 'Home',
  productTypeDetail: 'TEN',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  hasAutoPay: true,
  currentBillHasAutopay: false,
  totalBalance: '9.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '9.33',
  billingDate: '2019-11-23',
  last4Digits: '',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};

export const billingSummaryDetailRentalDwellingFixture: BillingSummaryDetail = {
  productId: 'THO23456789',
  productIdLast3Digits: '789',
  productType: 'Home',
  productTypeDetail: 'RDP',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '9.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '9.33',
  billingDate: '2019-11-23',
  last4Digits: '',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};

export const billingSummaryDetailCEAFixture: BillingSummaryDetail = {
  productId: 'CEA123456789',
  productIdLast3Digits: '789',
  productType: 'Earthquake',
  productTypeDetail: 'HOB',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '9.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '9.33',
  billingDate: '2019-11-23',
  last4Digits: '',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};

export const billingSummaryDetailPUPFixture: BillingSummaryDetail = {
  productId: 'CAU123456789',
  productIdLast3Digits: '789',
  productType: 'PUP',
  productTypeDetail: 'PUP',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '9.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '9.33',
  billingDate: '2019-11-23',
  last4Digits: '',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};
export const billingSummaryDetailLifeFixture: BillingSummaryDetail = {
  productId: '1122334455',
  productIdLast3Digits: '789',
  productType: 'Life',
  productTypeDetail: 'HOB',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '9.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '9.33',
  billingDate: '2019-11-23',
  last4Digits: '',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
};
export const billingSummaryDetailAutoWithPITFixture: BillingSummaryDetail = {
  productId: 'TPA123456789',
  productIdLast3Digits: '789',
  productType: 'Auto',
  productTypeDetail: 'PPA',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'CAA9',
  billPlanDescription: 'Nine (9) Payment Plan',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '447.23',
  minimumDue: '10.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '400.00', // this will change after multi PIT implementation ? ? ?
  billingDate: '2020-02-09',
  accountType: '', //will not look at this field for PIT scenario
  last4Digits: '', //will not look at this field for PIT scenario
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: true,
  hasPastDue: true,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [
      {
        pitPaymentDate: '2020-02-19',
        pitPaymentType: 'BCHK',
        pitPaymentAmount: '400.00',
      },
      {
        pitPaymentDate: '2020-02-19',
        pitPaymentType: 'C',
        pitPaymentAmount: '47.23',
      },
    ],
    pitReturnMessage: 'PAYMENT INQUIRY SUCCESSFUL',
    pitTotalPaymentAmount: '447.23',
  },
  isPolicyCancelled: false,
  isPolicyReinstatable: true,
  lastMoneyDate: undefined,
  policyExpiryDate: undefined,
  reinstatementMinimumDue: undefined,
  cnpDate: undefined,
  trnpDate: undefined,
  isPendingCancellation: false,
  isPendingNonRenewal: false,
  lastPaymentAccountInfo: {
    accountType: 'E',
    accountLastFourDigits: '6989',
    accountHolderName: 'KAREN HAVRILLA',
    accountExpirationDate: '',
    cardType: '',
    cardDebitOrCreditIndicator: '',
  },
};
export const billingSummaryDetailAutoPolicyStarFixture: BillingSummaryDetail = {
  productId: 'A22208351',
  productIdLast3Digits: '351',
  productType: 'Auto',
  productTypeDetail: 'Auto',
  status: 'NORM',
  statusDescription: '',
  billPlan: '',
  billPlanDescription: '',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '0.00',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '0.00',
  billingDate: '',
  last4Digits: '',
  hasBalanceDue: false,
  hasPaymentScheduled: false,
  hasPastDue: false,
  hasPaymentInTransit: false,
  hasMinimumAmountDue: false,
  isMonthly: false,
  accountType: '',
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: '',
    pitTotalPaymentAmount: '0.00',
  },
};
export const billingSummaryDetailHomePolicyStarFixture: BillingSummaryDetail = {
  productId: 'P51212171',
  productIdLast3Digits: '171',
  productType: 'Home',
  productTypeDetail: 'Home',
  status: 'NORM',
  statusDescription: '',
  billPlan: '',
  billPlanDescription: '',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '0.00',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '0.00',
  billingDate: '',
  last4Digits: '',
  hasBalanceDue: false,
  hasPaymentScheduled: false,
  hasPastDue: false,
  hasPaymentInTransit: false,
  hasMinimumAmountDue: false,
  isMonthly: false,
  accountType: '',
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: '',
    pitTotalPaymentAmount: '0.00',
  },
};

export const billingSummaryCNPAutoFixture: BillingSummaryDetail = {
  productId: 'TPA123456789',
  productIdLast3Digits: '789',
  productType: 'Auto',
  productTypeDetail: 'PPA',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXMI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  currentBillHasAutopay: false,
  totalBalance: '10.33',
  minimumDue: '10.33',
  isInRenewal: false,
  isInArrears: false,
  amount: '10.33',
  billingDate: '2019-11-23',
  accountType: 'D',
  last4Digits: '1234',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: true,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
  isPendingCancellation: true,
  isPendingNonRenewal: false,
  cnpDate: '2020-07-03',
  trnpDate: '',
};

export const billingSummaryTRNPAutoFixture: BillingSummaryDetail = {
  productId: 'TPA123456789',
  productIdLast3Digits: '789',
  productType: 'Auto',
  productTypeDetail: 'PPA',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXMI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  currentBillHasAutopay: false,
  totalBalance: '10.33',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '10.33',
  billingDate: '2019-11-23',
  accountType: 'D',
  last4Digits: '1234',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: true,
  hasMinimumAmountDue: true,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
  isPendingCancellation: false,
  isPendingNonRenewal: true,
  cnpDate: '',
  trnpDate: '2020-07-02',
};

export const billingSummaryDetailPolicyStarMembershipFixture: BillingSummaryDetail = {
  productId: '065 18520360 00',
  productIdLast3Digits: ' 00',
  productType: 'Membership',
  productTypeDetail: 'CLASSIC',
  status: 'VALD',
  statusDescription: 'Valid',
  billPlan: 'AM',
  billPlanDescription: 'Annual One time Payment',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '0.00',
  minimumDue: '0.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '0.00',
  billingDate: '2020-10-01',
  accountType: '',
  last4Digits: '',
  isMonthly: false,
  hasPaymentScheduled: false,
  hasPaymentInTransit: false,
  hasPastDue: false,
  hasMinimumAmountDue: false,
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  hasBalanceDue: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: 'PAYMENT NOT FOUND',
    pitTotalPaymentAmount: '0.00',
  },
  reinstatementMinimumDue: undefined,
  lastMoneyDate: undefined,
  policyExpiryDate: undefined,
  isPolicyCancelled: false,
  isPendingCancellation: false,
  isPendingNonRenewal: false,
  cnpDate: undefined,
  trnpDate: undefined,
};

export const billingSummaryDetailAutoPolicyStarCNPFixture: BillingSummaryDetail = {
  productId: 'A12345678',
  productIdLast3Digits: '678',
  productType: 'Auto',
  productTypeDetail: 'Auto',
  status: 'NORM',
  statusDescription: '',
  billPlan: '',
  billPlanDescription: '',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '440.00',
  minimumDue: '10.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '94.00',
  billingDate: '2020-07-18',
  last4Digits: '',
  hasBalanceDue: true,
  hasPaymentScheduled: false,
  hasPastDue: false,
  hasPaymentInTransit: false,
  hasMinimumAmountDue: true,
  isMonthly: false,
  accountType: '',
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: '',
    pitTotalPaymentAmount: '0.00',
  },
  policyExpiryDate: '2020-12-30',
  reinstatementMinimumDue: '',
  lastMoneyDate: '',
  isPolicyCancelled: false,
  isPendingCancellation: true,
  isPendingNonRenewal: false,
  cnpDate: '2020-07-18',
  trnpDate: '',
};

export const billingSummaryDetailHomePolicyStarCNPFixture: BillingSummaryDetail = {
  productId: 'P12345678',
  productIdLast3Digits: '678',
  productType: 'Home',
  productTypeDetail: 'Home',
  status: 'NORM',
  statusDescription: '',
  billPlan: '',
  billPlanDescription: '',
  hasAutoPay: false,
  currentBillHasAutopay: false,
  totalBalance: '895.00',
  minimumDue: '10.00',
  isInRenewal: false,
  isInArrears: false,
  amount: '228.75',
  billingDate: '2020-08-02',
  last4Digits: '',
  hasBalanceDue: true,
  hasPaymentScheduled: false,
  hasPastDue: false,
  hasPaymentInTransit: false,
  hasMinimumAmountDue: true,
  isMonthly: false,
  accountType: '',
  isCreditCardExpiring: false,
  isCreditCardInvalid: false,
  isNoCardOnFile: false,
  reinstatementMinimumDue: '',
  lastMoneyDate: '',
  policyExpiryDate: '2020-08-02',
  isPolicyCancelled: false,
  isPendingCancellation: false,
  isPendingNonRenewal: false,
  cnpDate: '',
  trnpDate: '',
  paymentsInTransit: {
    pitPayments: [],
    pitReturnMessage: '',
    pitTotalPaymentAmount: '0.00',
  },
};

export const billingSummaryFixture: BillingSummary = {
  billingSummaryDetails: [
    billingSummaryDetailMembershipFixture,
    billingSummaryDetailWatercraftFixture,
    billingSummaryDetailHomeFixture,
    billingSummaryDetailAutoFixture,
  ],
};

export const billingSummaryFixtureDuplicatePolicies: BillingSummary = {
  billingSummaryDetails: [
    billingSummaryDetailMembershipFixture,
    billingSummaryDetailHomeFixtureTHO,
    billingSummaryDetailHomeFixtureTHOFullData,
  ],
};

export const billingSummaryFixtureNoInsurance: BillingSummary = {
  billingSummaryDetails: [billingSummaryDetailMembershipFixture],
};

export const billingSummaryFixtureCantMakePayment: BillingSummary = {
  billingSummaryDetails: [
    billingSummaryDetailMembershipFixture,
    billingSummaryDetailHomeFixture,
    billingSummaryDetailAutoFixture,
  ],
};

export const billingSummaryFixtureAllProducts: BillingSummary = {
  billingSummaryDetails: [
    billingSummaryDetailMembershipFixture,
    billingSummaryDetailWatercraftFixture,
    billingSummaryDetailHomeFixture,
    billingSummaryDetailAutoFixture,
    billingSummaryDetailLifeFixture,
    billingSummaryDetailPUPFixture,
    billingSummaryDetailCEAFixture,
    billingSummaryDetailRentalDwellingFixture,
    billingSummaryDetailTenantFixture,
    billingSummaryDetailCondoFixture,
  ],
};

export const billingSummaryDetailAutoCanceledPolicyFixture: BillingSummaryDetail = {
  ...billingSummaryDetailAutoFixture,
  productId: 'TPA123456789',
  status: 'CANC',
  statusDescription: 'CANC',
  isPolicyCancelled: true,
  lastMoneyDate: '9999-01-01',
  reinstatementMinimumDue: '1.00',
  hasMinimumAmountDue: false,
  hasPaymentInTransit: false,
  hasPaymentScheduled: false,
  hasPastDue: false,
};

export const billingSummaryFixtureCanceledPolicy: BillingSummary = {
  billingSummaryDetails: [billingSummaryDetailMembershipFixture, billingSummaryDetailAutoCanceledPolicyFixture],
};

export const billingSummaryFixturePIT: BillingSummary = {
  billingSummaryDetails: [billingSummaryDetailAutoWithPITFixture, billingSummaryDetailHomeFixture],
};

export const billingSummaryPolicyStarFixture: BillingSummary = {
  billingSummaryDetails: [billingSummaryDetailAutoPolicyStarFixture, billingSummaryDetailHomePolicyStarFixture],
};

export const billingSummaryFixtureCNP: BillingSummary = {
  billingSummaryDetails: [billingSummaryDetailMembershipFixture, billingSummaryCNPAutoFixture],
};

export const billingSummaryFixtureTRNP: BillingSummary = {
  billingSummaryDetails: [billingSummaryDetailMembershipFixture, billingSummaryTRNPAutoFixture],
};

export const billingSummaryFixturePolicyStarCNP: BillingSummary = {
  billingSummaryDetails: [
    billingSummaryDetailPolicyStarMembershipFixture,
    billingSummaryDetailAutoPolicyStarCNPFixture,
    billingSummaryDetailHomePolicyStarCNPFixture,
  ],
};

// Service
export const billingSummaryRequestServiceFixture: BillingSummaryRequestService = {
  customerId: '116310020099992',
};
export const billingSummaryResponseServiceFixture: BillingSummaryResponseService = {
  billingSummary: billingSummaryFixture,
};
export const billingSummaryPITResponseServiceFixture: BillingSummaryResponseService = {
  billingSummary: billingSummaryFixturePIT,
};
export const billingSummaryCNPResponseServiceFixture: BillingSummaryResponseService = {
  billingSummary: billingSummaryFixtureCNP,
};
export const billingSummaryTRNPResponseServiceFixture: BillingSummaryResponseService = {
  billingSummary: billingSummaryFixtureTRNP,
};
export const billingSummaryPolicyStarCNPResponseServiceFixture: BillingSummaryResponseService = {
  billingSummary: billingSummaryFixturePolicyStarCNP,
};
export const billingSummaryDuplicateResponseServiceFixture: BillingSummaryResponseService = {
  billingSummary: billingSummaryFixtureDuplicatePolicies,
};
export const billingSummaryRankingFixture: BillingSummaryResponseService = {
  billingSummary: {
    billingSummaryDetails: [
      billingSummaryDetailMembershipFixture,
      billingSummaryDetailHomeFixture,
      billingSummaryDetailAutoFixture2,
      billingSummaryDetailTenantFixture,
      billingSummaryDetailCondoFixture,
      billingSummaryDetailAutoFixture,
    ],
  },
};

// Enterprise
const MEMBERSHIP_6202521234567800 = {
  productId: '252 12345678 00',
  productType: 'Membership',
  productTypeDetail: 'PLUS',
  status: 'VALD',
  statusDescription: 'Valid',
  billPlan: 'MP',
  billPlanDescription: 'Monthly PayPlan',
  hasAutoPay: true,
  isPaymentDueAutoPay: false,
  totalBalance: '7.33',
  minimumDue: '7.33',
  dueDate: '2019-11-23',
  lastPayment: '82.00',
  lastPaymentDate: '',
  isInRenewal: true,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '00',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'C',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '01/01/2022',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'C',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
  autopayAccountInfo: {
    accountType: 'CCRD',
    accountLastFourDigits: '9849',
    accountExpirationDate: '2022-07-31',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
  },
};
const WATERCRAFT_TXW123456789 = {
  productId: 'TXW123456789',
  productType: 'Watercraft',
  productTypeDetail: 'WTR',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXW3',
  billPlanDescription: 'Three(3) Payment Plan',
  hasAutoPay: false,
  isPaymentDueAutoPay: false,
  totalBalance: '8.33',
  minimumDue: '8.33',
  dueDate: '2019-11-23',
  lastPayment: '      -89.04',
  lastPaymentDate: '2018-11-25',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'C',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '01/01/2022',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'C',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
};
const HOME_HOB_THO123456789 = {
  productId: 'THO123456789',
  productType: 'Home',
  productTypeDetail: 'HOB',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Three(3) Payment Plan',
  hasAutoPay: true,
  isPaymentDueAutoPay: false,
  totalBalance: '9.33',
  minimumDue: '9.33',
  dueDate: '2019-11-23',
  lastPayment: '   -2,221.00',
  lastPaymentDate: '2019-04-29',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: '',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '07/01/2023',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: '',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
};
const HOME_CON_THO987654321 = {
  productId: 'THO987654321',
  productType: 'Home',
  productTypeDetail: 'CON',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  isPaymentDueAutoPay: true,
  totalBalance: '10.33',
  minimumDue: '10.33',
  dueDate: '2019-11-23',
  lastPayment: '     -314.28',
  lastPaymentDate: '2019-09-10',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'D',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '07/01/2023',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
};
const HOME_TEN_THO3456789 = {
  productId: 'THO3456789',
  productType: 'Home',
  productTypeDetail: 'TEN',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  isPaymentDueAutoPay: true,
  totalBalance: '10.33',
  minimumDue: '10.33',
  dueDate: '2019-11-23',
  lastPayment: '     -314.28',
  lastPaymentDate: '2019-09-10',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'D',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '07/01/2023',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
};
const HOME_RDP_THO23456789 = {
  productId: 'THO23456789',
  productType: 'Home',
  productTypeDetail: 'RDP',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  isPaymentDueAutoPay: true,
  totalBalance: '10.33',
  minimumDue: '10.33',
  dueDate: '2019-11-23',
  lastPayment: '     -314.28',
  lastPaymentDate: '2019-09-10',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'D',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '07/01/2023',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
};
const AUTO_PPA_TPA123456789 = {
  productId: 'TPA123456789',
  productType: 'Auto',
  productTypeDetail: 'PPA',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXMI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  isPaymentDueAutoPay: false,
  totalBalance: '10.33',
  minimumDue: '10.33',
  dueDate: '2019-11-23',
  lastPayment: '     -314.28',
  lastPaymentDate: '2019-09-10',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  reinstatementMinimumDue: '1.00',
  lastMoneyDate: '9999-01-01',
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'D',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '07/01/2023',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
  autopayAccountInfo: {
    //won"t be returned for non auto pay
    accountType: 'CCRD',
    accountLastFourDigits: '9638',
    accountExpirationDate: '2022-07-31',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
  },
};
const AUTO_CAC_CAC123456789 = {
  productId: 'CAC123456789',
  productType: 'Auto Insurance',
  productTypeDetail: 'PPA',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  isPaymentDueAutoPay: true,
  totalBalance: '10.33',
  minimumDue: '10.33',
  dueDate: '2019-11-23',
  lastPayment: '     -314.28',
  lastPaymentDate: '2019-09-10',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'D',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '07/01/2023',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
};
const AUTO_POLICYSTAR_A22208351: ProductBillingSummary = {
  productId: 'A22208351',
  productType: 'Auto',
  productTypeDetail: 'Auto',
  status: 'NORM',
  statusDescription: '',
  billPlan: '',
  billPlanDescription: '',
  hasAutoPay: false, // value is "N"
  totalBalance: '0.00',
  minimumDue: '0.00',
  dueDate: '',
  lastPayment: '568.00',
  isInRenewal: false, // not in Enterprise API
  isInArrears: false, // not in Enterprise API
  isPastDue: false, // not in Enterprise API
  lastPaymentDate: '2019-12-23',
  // policyExpirationDate: "2020-07-01",
  // cancelForNonPaymentDate: "",
  // terminateRenewalForNonPaymentDate: "",
  // lastMoneyDate: "",
  // reinstatementMinimumDue: "",
  //scheduledPayment: { },  // not in Enterprise API
  //PIT: {},                // not in Enterprise API
  lastPaymentAccountInfo: {
    accountType: '',
    accountLastFourDigits: '',
    accountHolderName: '',
    accountExpirationDate: '',
    cardType: '',
    cardDebitOrCreditIndicator: '',
  },
};
const HOME_POLICYSTAR_A22208351: ProductBillingSummary = {
  productId: 'P51212171',
  productType: 'Home',
  productTypeDetail: 'Home',
  status: 'NORM',
  statusDescription: '',
  billPlan: '',
  billPlanDescription: '',
  hasAutoPay: false,
  totalBalance: '0.00',
  minimumDue: '0.00',
  dueDate: '',
  lastPayment: '688.00',
  isInRenewal: false, // not in Enterprise API
  isInArrears: false, // not in Enterprise API
  isPastDue: false, // not in Enterprise API
  lastPaymentDate: '2019-05-28',
  // policyExpirationDate: "2020-07-01",
  // cancelForNonPaymentDate: "",
  // terminateRenewalForNonPaymentDate: "",
  // lastMoneyDate: "",
  // reinstatementMinimumDue: "",
  // scheduledPayment: {},
  // PIT: {},
  lastPaymentAccountInfo: {
    accountType: '',
    accountLastFourDigits: '',
    accountHolderName: '',
    accountExpirationDate: '',
    cardType: '',
    cardDebitOrCreditIndicator: '',
  },
};
const LIFE_1122334455 = {
  productId: '1122334455',
  productType: 'Life Insurance',
  productTypeDetail: 'LIF',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  isPaymentDueAutoPay: true,
  totalBalance: '10.33',
  minimumDue: '10.33',
  dueDate: '2019-11-23',
  lastPayment: '     -314.28',
  lastPaymentDate: '2019-09-10',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'D',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '07/01/2023',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
};
const EARTHQUAKE_CEA123456789 = {
  productId: 'CEA123456789',
  productType: 'Earthquake',
  productTypeDetail: 'CON',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  isPaymentDueAutoPay: true,
  totalBalance: '10.33',
  minimumDue: '10.33',
  dueDate: '2019-11-23',
  lastPayment: '     -314.28',
  lastPaymentDate: '2019-09-10',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'D',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '07/01/2023',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
};
const PUP_CAU123456789 = {
  productId: 'CAU123456789',
  productType: 'PUP',
  productTypeDetail: 'PUP',
  status: 'NORM',
  statusDescription: 'NORM',
  billPlan: 'TXHI',
  billPlanDescription: 'Eleven(11) Payment Plan',
  hasAutoPay: true,
  isPaymentDueAutoPay: true,
  totalBalance: '10.33',
  minimumDue: '10.33',
  dueDate: '2019-11-23',
  lastPayment: '     -314.28',
  lastPaymentDate: '2019-09-10',
  isInRenewal: false,
  isInArrears: false,
  isPastDue: false,
  scheduledPayment: {
    scheduledDate: '',
    submitDate: '',
    sequenceNumber: '',
    processingDate: '',
    cancelDate: '',
    type: '',
    amount: '0.00',
    processingCode: '',
    accountLastFourDigits: '',
  },
  PIT: {
    payments: [],
    returnCode: '210',
    returnMessage: 'PAYMENT NOT FOUND',
  },
  lastPaymentAccountInfo: {
    accountType: 'D',
    accountLastFourDigits: '1234',
    accountHolderName: 'KENT Z CLARK',
    accountExpirationDate: '07/01/2023',
    cardType: 'VSN',
    cardDebitOrCreditIndicator: 'D',
    billableFlag: 'Y',
    eligibleFlag: 'E',
    authorizationPending: 'N',
    panStatusIndicator: 'A',
    IsCreditCardExpiring: false,
    isNoCardOnFile: false,
    isCreditCardInvalid: false,
  },
};
export const billingSummaryResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  // Fixture used
  productBillingSummaries: [
    MEMBERSHIP_6202521234567800,
    WATERCRAFT_TXW123456789,
    HOME_HOB_THO123456789,
    AUTO_PPA_TPA123456789,
    {
      productId: 'TPA123456789',
      productType: 'Auto',
      productTypeDetail: 'PPA',
      status: 'CANC',
      statusDescription: '',
      billPlan: 'TXMI',
      billPlanDescription: 'Eleven(11) Payment Plan',
      hasAutoPay: true,
      isPaymentDueAutoPay: false,
      totalBalance: '10.33',
      minimumDue: '10.33',
      dueDate: '2019-11-23',
      lastPayment: '-314.28',
      lastPaymentDate: '2019-09-10',
      isInRenewal: false,
      isInArrears: false,
      isPastDue: false,
      reinstatementMinimumDue: '1.00',
      lastMoneyDate: '9999-01-01',
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'D',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '07/01/2023',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'D',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
    },
    {
      productId: '',
      productType: 'Home',
      productTypeDetail: '',
      status: 'CANC',
      statusDescription: '',
      billPlan: 'TXMI',
      billPlanDescription: 'Eleven(11) Payment Plan',
      hasAutoPay: true,
      isPaymentDueAutoPay: false,
      totalBalance: '10.33',
      minimumDue: '10.33',
      dueDate: '2019-11-23',
      lastPayment: '     -314.28',
      lastPaymentDate: '2019-09-10',
      isInRenewal: false,
      isInArrears: false,
      isPastDue: false,
      reinstatementMinimumDue: '1.00',
      lastMoneyDate: '9999-01-01',
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'D',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '07/01/2023',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'D',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
    },
  ],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '2019-11-23',
    monthlyPaymentAmount: '7.33',
    totalPastDue: '',
    payments: [
      {
        paymentDueDate: '11/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
      {
        paymentDueDate: '12/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
    ],
  },
};

export const billingSummaryAllProductsResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  productBillingSummaries: [
    MEMBERSHIP_6202521234567800,
    WATERCRAFT_TXW123456789,
    HOME_HOB_THO123456789,
    AUTO_PPA_TPA123456789,
    HOME_CON_THO987654321,
    HOME_TEN_THO3456789,
    HOME_RDP_THO23456789,
    AUTO_CAC_CAC123456789,
    LIFE_1122334455,
    EARTHQUAKE_CEA123456789,
    PUP_CAU123456789,
  ],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '2019-11-23',
    monthlyPaymentAmount: '7.33',
    totalPastDue: '',
    payments: [
      {
        paymentDueDate: '11/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
      {
        paymentDueDate: '12/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
    ],
  },
};

export const billingSummaryNoInsuranceProductsResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  productBillingSummaries: [MEMBERSHIP_6202521234567800],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '2019-11-23',
    monthlyPaymentAmount: '7.33',
    totalPastDue: '',
    payments: [
      {
        paymentDueDate: '11/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
      {
        paymentDueDate: '12/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
    ],
  },
};

export const billingSummaryPolicyStarResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  productBillingSummaries: [MEMBERSHIP_6202521234567800, AUTO_POLICYSTAR_A22208351, HOME_POLICYSTAR_A22208351],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '2019-11-23',
    monthlyPaymentAmount: '7.33',
    totalPastDue: '',
    payments: [
      {
        paymentDueDate: '11/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
      {
        paymentDueDate: '12/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
    ],
  },
};

export const billingSummaryPITsResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  productBillingSummaries: [
    {
      productId: 'TPA123456789',
      productType: 'Auto',
      productTypeDetail: 'PPA',
      status: 'NORM',
      statusDescription: 'NORM',
      billPlan: 'CAA9',
      billPlanDescription: 'Nine (9) Payment Plan',
      hasAutoPay: false,
      totalBalance: '447.23',
      minimumDue: '10.00',
      dueDate: '2020-02-09',
      lastPayment: '-500.00',
      lastPaymentDate: '2019-04-25',
      isInRenewal: false,
      isInArrears: false,
      isPastDue: true,
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [
          {
            paymentDate: '2020-02-19',
            paymentType: 'BCHK',
            paymentStatus: 'PIT',
            paymentAmount: '400.00',
          },
          {
            paymentDate: '2020-02-19',
            paymentType: 'C',
            paymentStatus: 'PIT',
            paymentAmount: '47.23',
          },
        ],
        returnCode: '000',
        returnMessage: 'PAYMENT INQUIRY SUCCESSFUL',
      },
      lastPaymentAccountInfo: {
        accountType: 'E',
        accountLastFourDigits: '6989',
        accountHolderName: 'KAREN HAVRILLA',
        accountExpirationDate: '',
        cardType: '',
        cardDebitOrCreditIndicator: '',
      },
    },
  ],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '',
    monthlyPaymentAmount: '',
    totalPastDue: '',
    payments: [],
  },
};

export const billingSummaryCancelledPolicyResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  productBillingSummaries: [
    {
      productId: '252 12345678 00',
      productType: 'Membership',
      productTypeDetail: 'CLSC',
      status: 'VALD',
      statusDescription: 'Valid',
      billPlan: 'MP',
      billPlanDescription: 'Monthly PayPlan',
      hasAutoPay: true,
      isPaymentDueAutoPay: true,
      totalBalance: '7.33',
      minimumDue: '7.33',
      dueDate: '2019-11-23',
      lastPayment: '82.00',
      lastPaymentDate: '',
      isInRenewal: true,
      isInArrears: false,
      isPastDue: false,
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '00',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'C',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '01/01/2022',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'C',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
    },
    {
      productId: 'TXW123456789',
      productType: 'Watercraft',
      productTypeDetail: 'WTR',
      status: 'NORM',
      statusDescription: 'NORM',
      billPlan: 'TXW3',
      billPlanDescription: 'Three(3) Payment Plan',
      hasAutoPay: false,
      isPaymentDueAutoPay: true,
      totalBalance: '8.33',
      minimumDue: '8.33',
      dueDate: '2019-11-23',
      lastPayment: '      -89.04',
      lastPaymentDate: '2018-11-25',
      isInRenewal: false,
      isInArrears: false,
      isPastDue: false,
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'C',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '01/01/2022',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'C',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
    },
    {
      productId: 'CAA123456789',
      productType: 'Auto',
      productTypeDetail: 'PPA',
      status: 'CANC',
      statusDescription: 'NORM',
      billPlan: 'TXMI',
      billPlanDescription: 'Eleven(11) Payment Plan',
      hasAutoPay: true,
      isPaymentDueAutoPay: true,
      totalBalance: '10.33',
      minimumDue: '10.33',
      dueDate: '2019-11-23',
      lastPayment: '     -314.28',
      lastPaymentDate: '2019-09-10',
      isInRenewal: false,
      isInArrears: false,
      isPastDue: false,
      cancelForNonPaymentDate: '2020-03-16',
      lastMoneyDate: '2020-04-20',
      reinstatementMinimumDue: '100.00',
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'D',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '07/01/2023',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'D',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
    },
    {
      productId: 'TPA123456789',
      productType: 'Auto',
      productTypeDetail: 'PPA',
      status: 'CANC',
      statusDescription: 'CANC',
      billPlan: 'TXMI',
      billPlanDescription: 'Eleven(11) Payment Plan',
      hasAutoPay: true,
      isPaymentDueAutoPay: true,
      totalBalance: '10.33',
      minimumDue: '10.33',
      dueDate: '2019-11-23',
      lastPayment: '-314.28',
      lastPaymentDate: '2019-09-10',
      isInRenewal: false,
      isInArrears: false,
      isPastDue: false,
      cancelForNonPaymentDate: '2020-03-16',
      lastMoneyDate: '9999-01-01',
      reinstatementMinimumDue: '100.00',
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'D',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '07/01/2023',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'D',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
    },
  ],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '2019-11-23',
    monthlyPaymentAmount: '7.33',
    totalPastDue: '',
    payments: [
      {
        paymentDueDate: '11/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
      {
        paymentDueDate: '12/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
    ],
  },
};

export const billingSummaryCNPResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  productBillingSummaries: [
    {
      productId: '252 12345678 00',
      productType: 'Membership',
      productTypeDetail: 'CLSC',
      status: 'VALD',
      statusDescription: 'Valid',
      billPlan: 'MP',
      billPlanDescription: 'Monthly PayPlan',
      hasAutoPay: true,
      isPaymentDueAutoPay: true,
      totalBalance: '7.33',
      minimumDue: '7.33',
      dueDate: '2019-11-23',
      lastPayment: '82.00',
      lastPaymentDate: '',
      isInRenewal: true,
      isInArrears: false,
      isPastDue: false,
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '00',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'C',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '01/01/2022',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'C',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
    },
    {
      productId: 'TPA123456789',
      productType: 'Auto',
      productTypeDetail: 'PPA',
      status: 'NORM',
      statusDescription: 'NORM',
      billPlan: 'TXMI',
      billPlanDescription: 'Eleven(11) Payment Plan',
      hasAutoPay: true,
      isPaymentDueAutoPay: false,
      totalBalance: '10.33',
      minimumDue: '10.33',
      dueDate: '2019-11-23',
      lastPayment: '     -314.28',
      lastPaymentDate: '2019-09-10',
      isInRenewal: false,
      isInArrears: false,
      isPastDue: false,
      cancelForNonPaymentDate: '2020-07-03',
      terminateRenewalForNonPaymentDate: '',
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'D',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '07/01/2023',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'D',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
      autopayAccountInfo: {
        //won"t be returned for non auto pay
        accountType: 'CCRD',
        accountLastFourDigits: '9638',
        accountExpirationDate: '2022-07-31',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'D',
      },
    },
  ],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '2019-11-23',
    monthlyPaymentAmount: '7.33',
    totalPastDue: '',
    payments: [
      {
        paymentDueDate: '11/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
      {
        paymentDueDate: '12/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
    ],
  },
};

export const billingSummaryTRNPResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  productBillingSummaries: [
    {
      productId: '252 12345678 00',
      productType: 'Membership',
      productTypeDetail: 'CLSC',
      status: 'VALD',
      statusDescription: 'Valid',
      billPlan: 'MP',
      billPlanDescription: 'Monthly PayPlan',
      hasAutoPay: true,
      isPaymentDueAutoPay: true,
      totalBalance: '7.33',
      minimumDue: '7.33',
      dueDate: '2019-11-23',
      lastPayment: '82.00',
      lastPaymentDate: '',
      isInRenewal: true,
      isInArrears: false,
      isPastDue: false,
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '00',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'C',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '01/01/2022',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'C',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
    },
    {
      productId: 'TPA123456789',
      productType: 'Auto',
      productTypeDetail: 'PPA',
      status: 'NORM',
      statusDescription: 'NORM',
      billPlan: 'TXMI',
      billPlanDescription: 'Eleven(11) Payment Plan',
      hasAutoPay: true,
      isPaymentDueAutoPay: false,
      totalBalance: '10.33',
      minimumDue: '10.33',
      dueDate: '2019-11-23',
      lastPayment: '     -314.28',
      lastPaymentDate: '2019-09-10',
      isInRenewal: false,
      isInArrears: false,
      isPastDue: false,
      cancelForNonPaymentDate: '',
      terminateRenewalForNonPaymentDate: '2020-07-02',
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: 'D',
        accountLastFourDigits: '1234',
        accountHolderName: 'KENT Z CLARK',
        accountExpirationDate: '07/01/2023',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'D',
        billableFlag: 'Y',
        eligibleFlag: 'E',
        authorizationPending: 'N',
        panStatusIndicator: 'A',
        IsCreditCardExpiring: false,
        isNoCardOnFile: false,
        isCreditCardInvalid: false,
      },
      autopayAccountInfo: {
        //won"t be returned for non auto pay
        accountType: 'CCRD',
        accountLastFourDigits: '9638',
        accountExpirationDate: '2022-07-31',
        cardType: 'VSN',
        cardDebitOrCreditIndicator: 'D',
      },
    },
  ],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '2019-11-23',
    monthlyPaymentAmount: '7.33',
    totalPastDue: '',
    payments: [
      {
        paymentDueDate: '11/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
      {
        paymentDueDate: '12/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
    ],
  },
};

export const billingSummaryPolicyStarCNPResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  productBillingSummaries: [
    {
      productId: '065 18520360 00',
      productType: 'Membership',
      productTypeDetail: 'CLASSIC',
      status: 'VALD',
      statusDescription: 'Valid',
      billPlan: 'AM',
      billPlanDescription: 'Annual One time Payment',
      hasAutoPay: false,
      totalBalance: '0.00',
      minimumDue: '0.00',
      dueDate: '2020-10-01',
      lastPayment: '56.00',
      lastPaymentDate: '',
      isInRenewal: false,
      isInArrears: false,
      isPastDue: false,
      scheduledPayment: {
        scheduledDate: '',
        submitDate: '',
        sequenceNumber: '00',
        processingDate: '',
        cancelDate: '',
        type: '',
        amount: '0.00',
        processingCode: '',
        accountLastFourDigits: '0000',
      },
      PIT: {
        payments: [],
        returnCode: '210',
        returnMessage: 'PAYMENT NOT FOUND',
      },
      lastPaymentAccountInfo: {
        accountType: '',
        accountLastFourDigits: '',
        accountHolderName: '',
        accountExpirationDate: '',
        cardType: '',
        cardDebitOrCreditIndicator: '',
        billableFlag: '',
        eligibleFlag: '',
        authorizationPending: '',
        panStatusIndicator: '',
        IsCreditCardExpiring: '',
        isNoCardOnFile: '',
        isCreditCardInvalid: false,
      },
    },
    {
      productId: 'A12345678',
      productType: 'Auto',
      productTypeDetail: 'Auto',
      status: 'NORM',
      statusDescription: '',
      billPlan: '',
      billPlanDescription: '',
      hasAutoPay: false,
      totalBalance: '440.00',
      minimumDue: '94.00',
      dueDate: '2020-07-18',
      lastPayment: '', // not in Enterprise API
      lastPaymentDate: '',
      policyExpirationDate: '2020-12-30',
      cancelForNonPaymentDate: '2020-07-18',
      terminateRenewalForNonPaymentDate: '',
      lastMoneyDate: '',
      reinstatementMinimumDue: '',
      isInRenewal: false, // not in Enterprise API
      isInArrears: false, // not in Enterprise API
      isPastDue: false, // not in Enterprise API
      // scheduledPayment: {},
      // PIT: {},
      lastPaymentAccountInfo: {
        accountType: '',
        accountLastFourDigits: '',
        accountHolderName: '',
        accountExpirationDate: '',
        cardType: '',
        cardDebitOrCreditIndicator: '',
      },
    },
    {
      productId: 'P12345678',
      productType: 'Home',
      productTypeDetail: 'Home',
      status: 'NORM',
      statusDescription: '',
      billPlan: '',
      billPlanDescription: '',
      hasAutoPay: false,
      totalBalance: '895.00',
      minimumDue: '228.75',
      dueDate: '2020-08-02',
      lastPayment: '939.00',
      lastPaymentDate: '2019-07-08',
      policyExpirationDate: '2020-08-02',
      cancelForNonPaymentDate: '',
      terminateRenewalForNonPaymentDate: '',
      lastMoneyDate: '',
      reinstatementMinimumDue: '',
      isInRenewal: false, // not in Enterprise API
      isInArrears: false, // not in Enterprise API
      isPastDue: false, // not in Enterprise API
      // scheduledPayment: {},
      // PIT: {},
      lastPaymentAccountInfo: {
        accountType: '',
        accountLastFourDigits: '',
        accountHolderName: '',
        accountExpirationDate: '',
        cardType: '',
        cardDebitOrCreditIndicator: '',
      },
    },
  ],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '',
    monthlyPaymentAmount: '',
    totalPastDue: '',
    payments: [],
  },
};

export const billingSummaryAllProductsWithDuplicatesResponseEnterpriseFixture: BillingSummaryResponseEnterprise = {
  productBillingSummaries: [MEMBERSHIP_6202521234567800, HOME_TEN_THO3456789, HOME_TEN_THO3456789],
  membershipMonthlyPlanBillingSummary: {
    nextPaymentDate: '2019-11-23',
    monthlyPaymentAmount: '7.33',
    totalPastDue: '',
    payments: [
      {
        paymentDueDate: '11/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
      {
        paymentDueDate: '12/23/2019',
        paymentAmount: '7.33',
        PITFlag: false,
        pastDueFlag: false,
        creditBackAmount: '0.00',
      },
    ],
  },
};
