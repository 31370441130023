import { Theme } from '@material-ui/core/styles/createMuiTheme';

interface StyleProps {
  root: any;
  cardHeader: any;
  cardSubHeader: any;
  cardContent: any;
  cardActions: any;
  popper: any;
  menuList: any;
  menuItem: any;
}

const awarenessCardStyles = (theme: Theme): StyleProps => ({
  root: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    '& .MuiCardHeader-title': {
      fontSize: 18,
      [theme.breakpoints.up('lg')]: {
        fontSize: 22,
      },
    },
  },
  cardSubHeader: {
    color: '#717174',
  },
  cardContent: {
    padding: 4,
    '& .Body': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  cardActions: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    padding: 16,
    paddingTop: 0,
    '& button': {
      flexGrow: 1,
    },
    '& button:first-child': {
      marginRight: 8,
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        marginTop: 8,
      },
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: '0px',
      '& button': {
        margin: 0,
      },
      '& button:not(:first-child)': {
        margin: 0,
        marginTop: 8,
      },
    },
  },
  popper: {
    zIndex: 1,
  },
  menuList: {
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.4)',
    borderRadius: 4,
    padding: 0,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: 'rgba(68, 112, 191, 0.1)',
    },
    minHeight: 44,
    lineHeight: 1.5,
    paddingTop: 10,
    paddingBottom: 10,
  },
});

export default awarenessCardStyles;
