import {
  NotificationsRequestApp,
  NotificationsResponseApp,
  NotificationContractApp,
  notificationsModelFixture,
  aceMetaFixture,
  Notification,
  AceMeta,
} from 'shared';
import { RemotePost } from '../fetchers';
import { GetSourceParam, SourceParam } from 'remotes';
import { WebUtils } from 'utils';
import { NotificationsMapper } from 'shared/mappers/customerMapper';

export const NotificationRemoteApp = (endpoint: string): NotificationContractApp => {
  return async (request) => {
    const apiVersion = GetSourceParam(SourceParam.apiVersion);
    const { aceMeta } = WebUtils.getMetadata();

    if (apiVersion === 'v3') {
      const resp: NotificationsResponseApp = notisV3(aceMeta);

      // await RemotePost<NotificationsRequestApp, NotificationsResponseApp>(endpoint)(request);
      return Promise.resolve(resp);
    }

    return RemotePost<NotificationsRequestApp, NotificationsResponseApp>(endpoint)(request);
  };
};

export const NotificationStubApp = (_endpoint: string): NotificationContractApp => {

  return async () => {
    const apiVersion = GetSourceParam(SourceParam.apiVersion);
    if (apiVersion === 'v3') {
      const resp: NotificationsResponseApp = notisV3({
        sessionId: '',
        timestamp: ''
      });
      return resp;
    }
    else {
      return {
        aceMeta: aceMetaFixture,
        data: {
          notifications: notificationsModelFixture,
        },
      };
    };
  }
};

function notisV3(aceMeta: AceMeta) {
  const notifications: Notification[] = NotificationsMapper();
  const resp: NotificationsResponseApp = {
    aceMeta: { 
      sessionId: aceMeta.sessionId,
      timestamp: aceMeta.timestamp,
    },
    data: {
      notifications,
    },
  };
  return resp;
}

