import {
  CustomerDetailsContractApp,
  customerDetailsWithClaimsFixture,
  CustomerDetailsRequestApp,
  CustomerDetailsResponseApp,
  aceMetaFixture,
  taggingFixture,
  CustomerDetails,
  AceMeta,
  ProductType,
} from 'shared';
import { RemotePost } from '../fetchers';
import { GetSourceParam, SourceParam } from 'remotes/appSourcesParams';
import { CustomerDetailsMapper } from 'shared/mappers/customerMapper';
import { WebUtils } from 'utils';

export const CustomerDetailsRemoteApp = (endpoint: string): CustomerDetailsContractApp => {
  return async (request) => {
    const apiVersion = GetSourceParam(SourceParam.apiVersion);
    const { aceMeta } = WebUtils.getMetadata();

    const resp: CustomerDetailsResponseApp = custDetailsV3(aceMeta);
  
    if (apiVersion === 'v3') {
      // await RemotePost<CustomerDetailsRequestApp, CustomerDetailsResponseApp>(endpoint)(request);
      return Promise.resolve(resp);
    }

    const repsCD = await RemotePost<CustomerDetailsRequestApp, CustomerDetailsResponseApp>(endpoint)(request);
    const pmid = resp.data.customerDetails.products.find( p => p.type === ProductType.PMID);
    if (pmid) {
      repsCD.data.customerDetails.products.push(pmid);
    }
    return Promise.resolve(repsCD);
  };
};

export const CustomerDetailsStubApp = (_endpoint: string): CustomerDetailsContractApp => {

  return async () => {
    const apiVersion = GetSourceParam(SourceParam.apiVersion);
    if (apiVersion === 'v3') {
      const resp: CustomerDetailsResponseApp = custDetailsV3({
        sessionId: '',
        timestamp: ''
      });
      return resp;
    }
    else  {
      return {aceMeta: aceMetaFixture,
    data: {
      customerDetails: customerDetailsWithClaimsFixture,
    },
    analytics: taggingFixture}
    }
}
}



function custDetailsV3(aceMeta: AceMeta) {
  const customerDetails: CustomerDetails = CustomerDetailsMapper();
  const resp: CustomerDetailsResponseApp = {
    aceMeta: {
      sessionId: aceMeta.sessionId,
      timestamp: aceMeta.timestamp,
    },
    analytics: {} as any,
    data: {
      customerDetails,
    },
  };
  return resp;
}
