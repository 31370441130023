import {
  CustomerDetails,
  CustomerDetailsProduct,
  CustomerDetailsRequestApp,
  CustomerDetailsResponseApp,
  CustomerDetailsResponseService,
  CustomerDetailsResponseEnterprise,
} from '../models/customerDetailsModel';
import {
  billingSummaryDetailAutoFixture,
  billingSummaryDetailAutoPolicyStarCNPFixture,
  billingSummaryDetailAutoPolicyStarFixture,
  billingSummaryDetailCEAFixture,
  billingSummaryDetailCondoFixture,
  billingSummaryDetailHomeFixture,
  billingSummaryDetailHomePolicyStarCNPFixture,
  billingSummaryDetailHomePolicyStarFixture,
  billingSummaryDetailLifeFixture,
  billingSummaryDetailMembershipFixture,
  billingSummaryDetailPolicyStarMembershipFixture,
  billingSummaryDetailPUPFixture,
  billingSummaryDetailRentalDwellingFixture,
  billingSummaryDetailTenantFixture,
  billingSummaryDetailWatercraftFixture,
} from './billingSummaryModelFixture';
import { taggingFixture } from './taggingFixture';
import { aceMetaFixture } from './aceMetaFixture';
import { ProductType } from '../contracts/ProductType';
import { SourceSystem } from '../common/enums';

//membership
const customerDetailsMembershipProduct: CustomerDetailsProduct = {
  type: ProductType.MEMBERSHIP,
  productName: 'Plus membership',
  productIdDesc: 'Member #',
  productId: '6202521234567800',
  productExpiration: 'November 11, 2021',
  productEffectiveDate: '2020-11-11',
  productStatus: 'VALD',
  productRole: 'PR',
  rank: 1,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.MRD,
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: false,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
  pitReturnMessage: 'payment not found',
  billingSummary: billingSummaryDetailMembershipFixture,
};

const customerDetailsPolicyStarMembershipProduct: CustomerDetailsProduct = {
  type: ProductType.MEMBERSHIP,
  productName: 'Plus membership',
  productIdDesc: 'Member #',
  productId: '6202521234567800',
  productExpiration: 'January 11, 2020',
  productEffectiveDate: '2003-01-11',
  productStatus: 'VALD',
  productRole: 'PR',
  rank: 1,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.MRD,
  billPlan: 'MP',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: true,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
  pitReturnMessage: 'payment not found',
  billingSummary: billingSummaryDetailPolicyStarMembershipFixture,
};

const customerDetailsMembershipProductAssociate: CustomerDetailsProduct = {
  type: ProductType.MEMBERSHIP,
  productName: 'Plus membership',
  productIdDesc: 'Member #',
  productId: '62544412345671234',
  productExpiration: 'January 11, 2020',
  productEffectiveDate: '2003-01-11',
  productStatus: 'VALD',
  productRole: 'AD',
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.MRD,
  billPlan: 'AC',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: false,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
};
const customerDetailsMembershipProductDependent: CustomerDetailsProduct = {
  type: ProductType.MEMBERSHIP,
  productName: 'Plus membership',
  productIdDesc: 'Member #',
  productId: '62544412345671234',
  productExpiration: 'January 11, 2020',
  productEffectiveDate: '2003-01-11',
  productStatus: 'VALD',
  productRole: 'DE',
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.MRD,
  billPlan: 'AC',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: false,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
};
//insurance
const customerDetailsAutoInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.AUTO,
  productName: 'Auto insurance policy',
  productIdDesc: 'Policy #',
  productId: 'TPA123456789',
  productExpiration: 'February 2, 2022',
  productEffectiveDate: '2021-02-02',
  productStatus: 'NORM',
  productRole: '',
  rank: 3,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'AC',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: false,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: false,
  hasPIT: false,
  billingSummary: billingSummaryDetailAutoFixture,
};
const customerDetailsClassicCollectorInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.AUTO,
  productName: 'Auto insurance policy',
  productIdDesc: 'Policy #',
  productId: 'CAC123456789',
  productExpiration: 'March 3, 2020',
  productEffectiveDate: '2010-03-03',
  productStatus: 'NORM',
  productRole: '',
  rank: 3,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: false,
  hasPIT: false,
  billingSummary: billingSummaryDetailAutoFixture,
};
export const customerDetailsHomeInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.HOME,
  productName: 'Home insurance policy',
  productIdDesc: 'Policy #',
  productId: 'THO123456789',
  productExpiration: 'May 5, 2020',
  productEffectiveDate: '2014-05-05',
  productStatus: 'NORM',
  productRole: '',
  rank: 4,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
  billingSummary: billingSummaryDetailHomeFixture,
};

const customerDetailsWatercraftInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.WATERCRAFT,
  productName: 'Watercraft insurance policy',
  productIdDesc: 'Policy #',
  productId: 'TXW123456789',
  productExpiration: 'September 19, 2019',
  productEffectiveDate: '2014-09-19',
  productStatus: 'NORM',
  productRole: '',
  rank: 9,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: false,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
  billingSummary: billingSummaryDetailWatercraftFixture,
};

const customerDetailsLifeInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.LIFE,
  productName: 'Life insurance policy',
  productIdDesc: 'Policy #',
  productId: '1122334455',
  productExpiration: 'July 16, 2054',
  productEffectiveDate: '01/01/0001',
  productStatus: 'NORM',
  productRole: '',
  rank: 11,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.LIF,
  billPlan: '',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: false,
  hasPIT: false,
  billingSummary: billingSummaryDetailLifeFixture,
};

export const customerDetailsEarthQuakeInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.CALIFORNIA_EARTHQUAKE,
  productName: 'California earthquake authority (CEA) policy',
  productIdDesc: 'Policy #',
  productId: 'CEA123456789',
  productExpiration: 'April 21, 2020',
  productEffectiveDate: '',
  productStatus: 'NORM',
  productRole: 'PH',
  rank: 8,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'AC',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: false,
  hasPIT: false,
  billingSummary: billingSummaryDetailCEAFixture,
};

const customerDetailsPUPInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.PUP,
  productName: 'Umbrella insurance policy',
  productIdDesc: 'Policy #',
  productId: 'CAU123456789',
  productExpiration: 'May 14, 2020',
  productEffectiveDate: '',
  productStatus: 'NORM',
  productRole: 'BC',
  rank: 10,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'MP',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: false,
  hasPIT: false,
  billingSummary: billingSummaryDetailPUPFixture,
};

export const customerDetailsCondoInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.CONDO,
  productName: 'Condo insurance policy',
  productIdDesc: 'Policy #',
  productId: 'THO987654321',
  productExpiration: 'May 5, 2020',
  productEffectiveDate: '2014-05-05',
  productStatus: 'NORM',
  productRole: 'PH',
  rank: 5,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  billPlan: 'MP',
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: false,
  hasPIT: false,
  billingSummary: billingSummaryDetailCondoFixture,
};

export const customerDetailsTenantInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.TENANT,
  productName: 'Tenant insurance policy',
  productIdDesc: 'Policy #',
  productId: 'THO3456789',
  productExpiration: 'May 5, 2020',
  productEffectiveDate: '2014-05-05',
  productStatus: 'NORM',
  productRole: 'PH',
  rank: 6,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: false,
  hasPIT: false,
  billingSummary: billingSummaryDetailTenantFixture,
};

export const customerDetailsRentalDwellingInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.RENTAL_DWELLING,
  productName: 'Rental dwelling insurance policy',
  productIdDesc: 'Policy #',
  productId: 'THO23456789',
  productExpiration: 'May 5, 2020',
  productEffectiveDate: '2014-05-05',
  productStatus: 'NORM',
  productRole: 'PH',
  rank: 7,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: false,
  hasPIT: false,
  billingSummary: billingSummaryDetailRentalDwellingFixture,
};

const customerDetailsAutoPolicyStarInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.AUTO,
  productName: 'Auto insurance policy',
  productIdDesc: 'Policy #',
  productId: 'A22208351',
  productStatus: 'ACTIVE',
  productExpiration: 'March 3, 2020',
  productEffectiveDate: '2010-03-03',
  productRole: '',
  rank: 3,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: 'Policy Star',
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
  billingSummary: billingSummaryDetailAutoPolicyStarFixture,
};

const customerDetailsAutoGuidewireInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.AUTO,
  productName: 'Auto insurance policy',
  productIdDesc: 'Policy #',
  productId: 'A22208351',
  productStatus: 'ACTIVE',
  productExpiration: 'March 3, 2020',
  productEffectiveDate: '2010-03-03',
  productRole: '',
  rank: 3,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: 'Guidewire',
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
  billingSummary: billingSummaryDetailAutoPolicyStarFixture,
};

const customerDetailsPMIDProduct: CustomerDetailsProduct = {
  type: ProductType.PMID,
  productName: 'Essential',
  rank: 2,
  productIdDesc: '',
  productId: '',
  productExpiration: '',
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  billPlan: '',
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
};

const customerDetailsHomePolicyStarInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.HOME,
  productName: 'Home insurance policy',
  productIdDesc: 'Policy #',
  productId: 'P12345678',
  productStatus: 'ACTIVE',
  productExpiration: 'August 2, 2019',
  productEffectiveDate: '2019-08-02',
  productRole: '',
  rank: 3,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: 'Policy Star',
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
  billingSummary: billingSummaryDetailHomePolicyStarFixture,
};

const customerDetailsAutoPolicyStarCNPTRNPInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.AUTO,
  productName: 'Auto insurance policy',
  productIdDesc: 'Policy #',
  productId: 'A12345678',
  productStatus: 'ACTIVE',
  productExpiration: 'December 30, 2020',
  productEffectiveDate: '2020-06-30',
  productRole: '',
  rank: 3,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: 'Policy Star',
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
  billingSummary: billingSummaryDetailAutoPolicyStarCNPFixture,
};

const customerDetailsHomePolicyStarCNPTRNPInsuranceProduct: CustomerDetailsProduct = {
  type: ProductType.HOME,
  productName: 'Home insurance policy',
  productIdDesc: 'Policy #',
  productId: 'P12345678',
  productStatus: 'ACTIVE',
  productExpiration: 'August 2, 2019',
  productEffectiveDate: '2019-08-02',
  productRole: '',
  rank: 3,
  allowMakePolicyChanges: true,
  allowSubmitPayment: true,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: 'Policy Star',
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: true,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
  billingSummary: billingSummaryDetailHomePolicyStarCNPFixture,
};

// below 3 used for cant submit payment scenario
const customerDetailsMembershipProductCantMakePayment: CustomerDetailsProduct = {
  type: ProductType.MEMBERSHIP,
  productName: 'Plus membership',
  productIdDesc: 'Member #',
  productId: '6202521234567800',
  productExpiration: 'January 11, 2020',
  productStatus: 'VALD',
  productEffectiveDate: '2003-01-11',
  productRole: 'PR',
  rank: 1,
  allowMakePolicyChanges: true,
  allowSubmitPayment: false,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.MRD,
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: false,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
};

const customerDetailsAutoInsuranceProductCantMakePayment: CustomerDetailsProduct = {
  type: ProductType.AUTO,
  productName: 'Auto insurance policy',
  productIdDesc: 'Policy #',
  productId: 'TPA123456789',
  productExpiration: 'February 2, 2020',
  productStatus: 'NORM',
  productEffectiveDate: '2010-02-02',
  productRole: '',
  rank: 3,
  allowMakePolicyChanges: true,
  allowSubmitPayment: false,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: false,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
};

const customerDetailsHomeInsuranceProductCantMakePayment: CustomerDetailsProduct = {
  type: ProductType.HOME,
  productName: 'Home insurance policy',
  productIdDesc: 'Policy #',
  productId: 'THO123456789',
  productExpiration: 'May 5, 2020',
  productStatus: 'NORM',
  productEffectiveDate: '2014-05-05',
  productRole: '',
  rank: 4,
  allowMakePolicyChanges: true,
  allowSubmitPayment: false,
  allowSubmitIQ: true,
  allowViewPolicy: true,
  allowViewPOI: true,
  sourceSystem: SourceSystem.HUON,
  billPlan: 'AM',
  isPolicyCancelled: false,
  isPolicyReinstatable: false,
  hasAutoPay: false,
  isInRenewal: false,
  isInArrears: false,
  hasMinimumAmountDue: true,
  hasPIT: false,
};

export const customerDetailsFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 11,
  products: [
    customerDetailsMembershipProduct,
    customerDetailsAutoInsuranceProduct,
    customerDetailsHomeInsuranceProduct,
    customerDetailsLifeInsuranceProduct,
    // customerDetailsWatercraftInsuranceProduct,
    // customerDetailsCondoInsuranceProduct,
    // customerDetailsTenantInsuranceProduct,
    // customerDetailsRentalDwellingInsuranceProduct,
    // customerDetailsEarthQuakeInsuranceProduct,
    // customerDetailsPUPInsuranceProduct,
    // customerDetailsClassicCollectorInsuranceProduct,
  ],
};

export const customerDetailsPremierFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Premier',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 11,
  products: [
    customerDetailsMembershipProduct,
    customerDetailsAutoInsuranceProduct,
    customerDetailsHomeInsuranceProduct,
    customerDetailsLifeInsuranceProduct,
    // customerDetailsWatercraftInsuranceProduct,
    // customerDetailsCondoInsuranceProduct,
    // customerDetailsTenantInsuranceProduct,
    // customerDetailsRentalDwellingInsuranceProduct,
    // customerDetailsEarthQuakeInsuranceProduct,
    // customerDetailsPUPInsuranceProduct,
    // customerDetailsClassicCollectorInsuranceProduct,
  ],
};

export const customerDetailsCanceledPolicyFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 11,
  products: [
    customerDetailsMembershipProduct,
    {
      ...customerDetailsAutoInsuranceProduct,
      isPolicyCancelled: true,
    },
  ],
};

export const customerDetailsECPAFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '215',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'PA',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 11,
  products: [
    customerDetailsMembershipProduct,
    customerDetailsHomeInsuranceProduct,
    customerDetailsAutoInsuranceProduct,
  ],
};

export const customerDetailsECNonPAFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '215',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'KY',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 11,
  products: [
    customerDetailsMembershipProduct,
    customerDetailsHomeInsuranceProduct,
    customerDetailsAutoInsuranceProduct,
  ],
};

export const customerDetailsOrderedFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  emptyAuthorizations: false,
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  person: {
    gender: 'M',
    dateOfBirth: '1995-12-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2017-12-01',
  },
  numberOfProducts: 11,
  products: [
    customerDetailsMembershipProduct,
    customerDetailsAutoInsuranceProduct,
    customerDetailsClassicCollectorInsuranceProduct,
    customerDetailsHomeInsuranceProduct,
    customerDetailsCondoInsuranceProduct,
    customerDetailsTenantInsuranceProduct,
    customerDetailsRentalDwellingInsuranceProduct,
    customerDetailsEarthQuakeInsuranceProduct,
    customerDetailsWatercraftInsuranceProduct,
    customerDetailsPUPInsuranceProduct,
    customerDetailsLifeInsuranceProduct,
  ],
};

export const customerDetailsActiveFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 4,
  products: [
    customerDetailsMembershipProduct,
    customerDetailsAutoInsuranceProduct,
    customerDetailsHomeInsuranceProduct,
    customerDetailsWatercraftInsuranceProduct,
  ],
};

export const customerDetailsLifeInsuranceFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 9,
  products: [
    customerDetailsMembershipProduct,
    customerDetailsWatercraftInsuranceProduct,
    customerDetailsHomeInsuranceProduct,
    customerDetailsAutoInsuranceProduct,
    customerDetailsLifeInsuranceProduct,
    customerDetailsEarthQuakeInsuranceProduct,
    customerDetailsPUPInsuranceProduct,
    customerDetailsLifeInsuranceProduct,
    customerDetailsLifeInsuranceProduct,
  ],
};

export const customerDetailsFixtureMembershipPrimary: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 1,
  products: [customerDetailsMembershipProduct],
};

export const customerDetailsFixtureMembershipAssociate: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '01',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 1,
  products: [customerDetailsMembershipProductAssociate],
};

export const customerDetailsFixtureMembershipDependent: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '02',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 1,
  products: [customerDetailsMembershipProductDependent],
};

//used for cant submit payment scenario
export const customerDetailsFixtureCantMakePayment: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 3,
  products: [
    customerDetailsMembershipProductCantMakePayment,
    customerDetailsHomeInsuranceProductCantMakePayment,
    customerDetailsAutoInsuranceProductCantMakePayment,
  ],
};

// Policy Star
export const customerDetailsPolicyStarFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 2,
  products: [
    customerDetailsMembershipProduct, 
    customerDetailsAutoPolicyStarInsuranceProduct,
    customerDetailsHomePolicyStarInsuranceProduct
  ],
};

// Guidewire
export const customerDetailsGuidewireFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 2,
  products: [ 
    customerDetailsAutoGuidewireInsuranceProduct,
    customerDetailsPMIDProduct,
  ],
};

//CNP TRNP HUON
export const customerDetailsCnpAndTrnpFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 11,
  products: [customerDetailsMembershipProduct, customerDetailsAutoInsuranceProduct],
};

//CNP TRNP  PSTAR
export const customerDetailsPolicyStarCnpAndTrnpFixture: CustomerDetails = {
  welcomeMessage: 'Bruce, thank you for your 17 years of loyalty',
  customerId: '116310020099992',
  user: {
    clubCode: '252',
    customerId: '116310020099992',
    customerName: 'Bruce',
    permissions: [],
    sessionId: '98765432123456789',
  },
  emptyAuthorizations: false,
  person: {
    gender: 'M',
    dateOfBirth: '1995-01-31',
    residenceAddress: {
      address: {
        streetAddress: '123 LAKE ST',
        city: 'SUNRISE BEACH',
        state: 'TX',
        zipCode: '78643',
        zipPlus4: '9395',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Plus',
    roleCode: '00',
    clubJoinYear: '2003',
    pendingBenefitLevel: { membershipType: '', effectiveDate: '' },
  },
  preferredEmailAddress: {
    emailAddress: 'bruce@email.com',
    lastUpdateDate: '2019-12-01',
  },
  numberOfProducts: 11,
  products: [
    customerDetailsPolicyStarMembershipProduct,
    customerDetailsAutoPolicyStarCNPTRNPInsuranceProduct,
    customerDetailsHomePolicyStarCNPTRNPInsuranceProduct,
  ],
};

// App
export const customerDetailsRequestAppFixture: CustomerDetailsRequestApp = {
  aceMeta: aceMetaFixture,
};
export const customerDetailsResponseAppFixture: CustomerDetailsResponseApp = {
  aceMeta: aceMetaFixture,
  data: {
    customerDetails: customerDetailsFixture,
  },
  analytics: taggingFixture,
};

export const customerDetailsOrderedResponseServiceFixture: CustomerDetailsResponseService = {
  customerDetails: customerDetailsOrderedFixture,
};

const MEMBERSHIP_6202521234567800 = {
  productId: '6202521234567800',
  lineOfBusiness: {
    code: '01',
    description: 'Membership',
  },
  sourceSystem: 'MRD',
  active: true,
  status: 'VALD',
  effectiveDate: '2003-01-11',
  expirationDate: '2020-01-11',
  productSpecificInformation: '',
  productRole: {
    roleType: 'PR',
    roleTypeDescription: 'PRIMARY MEMBER',
  },
};
const WATERCRAFT_TXW123456789 = {
  productId: 'TXW123456789',
  lineOfBusiness: {
    code: '20',
    description: 'Watercraft Insurance',
  },
  sourceSystem: 'HUON',
  active: true,
  status: 'NORM',
  effectiveDate: '2014-09-19',
  expirationDate: '2019-09-19',
  productSpecificInformation: 'WTR',
  productRole: {},
};
const WATERCRAFT_TXW123456789_AUTH = {
  productId: 'TXW123456789',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const HOME_HOB_THO123456789 = {
  productId: 'THO123456789',
  lineOfBusiness: {
    code: '20',
    description: 'Home Insurance',
  },
  sourceSystem: 'HUON',
  active: true,
  status: 'NORM',
  effectiveDate: '2014-05-05',
  expirationDate: '2020-05-05',
  productSpecificInformation: 'HOB',
  productRole: {},
};
const HOME_HOB_THO123456789_AUTH = {
  productId: 'THO123456789',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const HOME_CON_THO987654321 = {
  productId: 'THO987654321',
  lineOfBusiness: {
    code: '20',
    description: 'Home Insurance',
  },
  sourceSystem: 'HUON',
  active: true,
  status: 'NORM',
  effectiveDate: '2014-05-05',
  expirationDate: '2020-05-05',
  productSpecificInformation: 'CON',
  productRole: {
    roleType: 'PH',
    roleTypeDescription: 'Policy Holder Insured',
  },
};
const HOME_CON_THO987654321_AUTH = {
  productId: 'THO987654321',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const HOME_TEN_THO3456789 = {
  productId: 'THO3456789',
  lineOfBusiness: {
    code: '20',
    description: 'Home Insurance',
  },
  sourceSystem: 'HUON',
  active: true,
  status: 'NORM',
  effectiveDate: '2014-05-05',
  expirationDate: '2020-05-05',
  productSpecificInformation: 'TEN',
  productRole: {
    roleType: 'PH',
    roleTypeDescription: 'Policy Holder Insured',
  },
};
const HOME_TEN_THO3456789_AUTH = {
  productId: 'THO3456789',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const HOME_RDP_THO23456789 = {
  productId: 'THO23456789',
  lineOfBusiness: {
    code: '20',
    description: 'Home Insurance',
  },
  sourceSystem: 'HUON',
  active: true,
  status: 'NORM',
  effectiveDate: '2014-05-05',
  expirationDate: '2020-05-05',
  productSpecificInformation: 'RDP',
  productRole: {
    roleType: 'PH',
    roleTypeDescription: 'Policy Holder Insured',
  },
};
const HOME_RDP_THO23456789_AUTH = {
  productId: 'THO23456789',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const AUTO_PPA_TPA123456789 = {
  productId: 'TPA123456789',
  lineOfBusiness: {
    code: '20',
    description: 'Auto Insurance',
  },
  sourceSystem: 'HUON',
  active: true,
  status: 'NORM',
  effectiveDate: '2010-02-02',
  expirationDate: '2020-02-02',
  productSpecificInformation: 'PPA',
  productRole: {},
};
const AUTO_PPA_TPA123456789_AUTH = {
  productId: 'TPA123456789',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const AUTO_CAC_CAC123456789 = {
  productId: 'CAC123456789',
  lineOfBusiness: {
    code: '20',
    description: 'Auto Insurance',
  },
  sourceSystem: 'HUON',
  active: true,
  status: 'NORM',
  effectiveDate: '2010-03-03',
  expirationDate: '2020-03-03',
  productSpecificInformation: 'PPA',
  productRole: {},
};
const AUTO_CAC_CAC123456789_AUTH = {
  productId: 'CAC123456789',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const AUTO_POLICYSTAR_A22208351 = {
  productId: 'A22208351',
  lineOfBusiness: {
    code: '16',
    description: 'Auto Insurance',
  },
  sourceSystem: 'Policy Star',
  active: true,
  status: 'ACTIVE',
  effectiveDate: '2020-01-01',
  expirationDate: '2020-07-01',
  productSpecificInformation: 'Number of Passenger Vehicles: 3',
};
const AUTO_POLICYSTAR_A22208351_AUTH = {
  productId: 'A22208351',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const LIFE_1122334455 = {
  productId: '1122334455',
  lineOfBusiness: {
    code: '15',
    description: 'Life Insurance',
  },
  sourceSystem: 'LIF',
  active: true,
  productSpecificInformation: 'Face Amount = 500000',
  effectiveDate: '01/01/0001',
  expirationDate: '07/16/2054',
};
const LIFE_1122334455_AUTH = {
  productId: '1122334455',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const EARTHQUAKE_CEA123456789 = {
  productId: 'CEA123456789',
  lineOfBusiness: {
    code: '20',
    description: 'Earthquake',
  },
  sourceSystem: 'HUON',
  active: true,
  status: 'NORM',
  effectiveDate: '',
  expirationDate: '2020-04-21',
  productSpecificInformation: 'CON',
  productRole: {
    roleType: 'PH',
    roleTypeDescription: 'Policy Holder Insured',
  },
};
const EARTHQUAKE_CEA123456789_AUTH = {
  productId: 'CEA123456789',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
const PUP_CAU123456789 = {
  productId: 'CAU123456789',
  lineOfBusiness: {
    code: '20',
    description: 'PUP',
  },
  sourceSystem: 'HUON',
  active: true,
  status: 'NORM',
  effectiveDate: '',
  expirationDate: '2020-05-14',
  productSpecificInformation: 'PUP',
  productRole: {
    roleType: 'BC',
    roleTypeDescription: 'Billing Client',
  },
};
const PUP_CAU123456789_AUTH = {
  productId: 'CAU123456789',
  canViewPolicy: 'Y',
  canMakePolicyChanges: 'Y',
  canSubmitPayment: 'Y',
  canSubmitInsuranceQuestionaire: 'Y',
  canViewProofOfInsurance: 'Y',
};
export const customerDetailsResponseEnterpriseFixture: CustomerDetailsResponseEnterprise = {
  customer: {
    customerId: '116310020099992',
    person: {
      gender: 'M',
      dateOfBirth: '1995-12-31',
      residenceAddress: {
        address: {
          streetAddress: '123 LAKE ST',
          city: 'SUNRISE BEACH',
          state: 'TX',
          zipCode: '78643',
          zipPlus4: '9395',
          countryCode: '',
        },
      },
    },
    preferredEmailAddress: {
      emailAddress: 'bruce@email.com',
      lastUpdateDate: '2017-12-01',
    },
    numberOfProducts: 11,
    productSummaries: [
      MEMBERSHIP_6202521234567800,
      WATERCRAFT_TXW123456789,
      HOME_HOB_THO123456789,
      HOME_CON_THO987654321,
      HOME_TEN_THO3456789,
      HOME_RDP_THO23456789,
      AUTO_PPA_TPA123456789,
      AUTO_CAC_CAC123456789,
      LIFE_1122334455,
      EARTHQUAKE_CEA123456789,
      PUP_CAU123456789,
    ],
  },
  authorizations: {
    callRespMessage: 'Success',
    productAuthorization: [
      WATERCRAFT_TXW123456789_AUTH,
      HOME_HOB_THO123456789_AUTH,
      HOME_CON_THO987654321_AUTH,
      HOME_TEN_THO3456789_AUTH,
      HOME_RDP_THO23456789_AUTH,
      AUTO_PPA_TPA123456789_AUTH,
      AUTO_CAC_CAC123456789_AUTH,
      LIFE_1122334455_AUTH,
      EARTHQUAKE_CEA123456789_AUTH,
      PUP_CAU123456789_AUTH,
    ],
  },
};

export const customerDetailsResponseWithPolicyStarSourceEnterpriseFixture: CustomerDetailsResponseEnterprise = {
  customer: {
    customerId: '116310020099992',
    person: {
      gender: 'M',
      dateOfBirth: '1995-12-31',
      residenceAddress: {
        address: {
          streetAddress: '123 LAKE ST',
          city: 'SUNRISE BEACH',
          state: 'TX',
          zipCode: '78643',
          zipPlus4: '9395',
          countryCode: '',
        },
      },
    },
    preferredEmailAddress: {
      emailAddress: 'bruce@email.com',
      lastUpdateDate: '2017-12-01',
    },
    numberOfProducts: 2,
    productSummaries: [MEMBERSHIP_6202521234567800, AUTO_POLICYSTAR_A22208351],
  },
  authorizations: {
    callRespMessage: 'Success',
    productAuthorization: [AUTO_POLICYSTAR_A22208351_AUTH],
  },
};

export const customerDetailsResponseNoPermissionsEnterpriseFixture: CustomerDetailsResponseEnterprise = {
  customer: {
    customerId: '116310020099992',
    person: {
      gender: 'M',
      dateOfBirth: '1995-12-31',
      residenceAddress: {
        address: {
          streetAddress: '123 LAKE ST',
          city: 'SUNRISE BEACH',
          state: 'TX',
          zipCode: '78643',
          zipPlus4: '9395',
          countryCode: '',
        },
      },
    },
    preferredEmailAddress: {
      emailAddress: 'bruce@email.com',
      lastUpdateDate: '2017-12-01',
    },
    numberOfProducts: 11,
    productSummaries: [
      MEMBERSHIP_6202521234567800,
      WATERCRAFT_TXW123456789,
      HOME_HOB_THO123456789,
      HOME_CON_THO987654321,
      HOME_TEN_THO3456789,
      HOME_RDP_THO23456789,
      AUTO_PPA_TPA123456789,
      AUTO_CAC_CAC123456789,
      LIFE_1122334455,
      EARTHQUAKE_CEA123456789,
      PUP_CAU123456789,
    ],
  },
  authorizations: {
    callRespMessage: 'Success',
    productAuthorization: [
      WATERCRAFT_TXW123456789_AUTH,
      HOME_HOB_THO123456789_AUTH,
      HOME_CON_THO987654321_AUTH,
      HOME_TEN_THO3456789_AUTH,
      HOME_RDP_THO23456789_AUTH,
      {
        productId: 'TPA123456789',
        canViewPolicy: 'N',
        canMakePolicyChanges: 'N',
        canSubmitPayment: 'N',
        canSubmitInsuranceQuestionaire: 'N',
        canViewProofOfInsurance: 'N',
      },
      AUTO_CAC_CAC123456789_AUTH,
      LIFE_1122334455_AUTH,
      EARTHQUAKE_CEA123456789_AUTH,
      PUP_CAU123456789_AUTH,
    ],
  },
};

export const customerDetailsNonSupportedProductResponseEnterpriseFixture: CustomerDetailsResponseEnterprise = {
  customer: {
    customerId: '116310020099992',
    person: {
      gender: 'M',
      dateOfBirth: '1995-12-31',
      residenceAddress: {
        address: {
          streetAddress: '123 LAKE ST',
          city: 'SUNRISE BEACH',
          state: 'TX',
          zipCode: '78643',
          zipPlus4: '9395',
          countryCode: '',
        },
      },
    },
    preferredEmailAddress: {
      emailAddress: 'bruce@email.com',
      lastUpdateDate: '2017-12-01',
    },
    numberOfProducts: 11,
    productSummaries: [
      MEMBERSHIP_6202521234567800,
      WATERCRAFT_TXW123456789,
      HOME_HOB_THO123456789,
      HOME_CON_THO987654321,
      HOME_TEN_THO3456789,
      HOME_RDP_THO23456789,
      AUTO_PPA_TPA123456789,
      AUTO_CAC_CAC123456789,
      LIFE_1122334455,
      EARTHQUAKE_CEA123456789,
      PUP_CAU123456789,
      {
        productId: '123456789',
        lineOfBusiness: {
          code: '05',
          description: 'Personal Exc Lib Insurance',
        },
        sourceSystem: 'UNKNOWN',
        active: false,
        status: 'CONV',
        effectiveDate: '',
        expirationDate: '2005-09-29',
        productSpecificInformation: '',
        productRole: {
          roleType: '',
          roleTypeDescription: '',
        },
      },
    ],
  },
  authorizations: {
    callRespMessage: 'Success',
    productAuthorization: [
      WATERCRAFT_TXW123456789_AUTH,
      HOME_HOB_THO123456789_AUTH,
      HOME_CON_THO987654321_AUTH,
      HOME_TEN_THO3456789_AUTH,
      HOME_RDP_THO23456789_AUTH,
      AUTO_PPA_TPA123456789_AUTH,
      AUTO_CAC_CAC123456789_AUTH,
      LIFE_1122334455_AUTH,
      EARTHQUAKE_CEA123456789_AUTH,
      PUP_CAU123456789_AUTH,
    ],
  },
};

export const customerDetailsPolicyStarResponseEnterpriseFixture: CustomerDetailsResponseEnterprise = {
  customer: {
    customerId: '116310020099992',
    person: {
      gender: 'M',
      dateOfBirth: '1923-07-30',
      residenceAddress: {
        address: {
          streetAddress: '1522 N 81ST ST',
          city: 'KANSAS CITY',
          state: 'KS',
          zipCode: '66112',
          zipPlus4: '2180',
          countryCode: '',
        },
      },
    },
    preferredEmailAddress: {
      emailAddress: 'bruse@gmail.com',
      lastUpdateDate: '2019-07-07',
    },
    numberOfProducts: 3,
    productSummaries: [
      MEMBERSHIP_6202521234567800,
      {
        productId: 'A12345678',
        lineOfBusiness: {
          code: '16',
          description: 'Auto Insurance',
        },
        sourceSystem: 'Policy Star',
        active: true,
        status: 'ACTIVE',
        effectiveDate: '2020-06-30',
        expirationDate: '2020-12-30',
        productSpecificInformation: 'Number of Passenger Vehicles: 1',
      },
      {
        productId: 'P12345678',
        lineOfBusiness: {
          code: '16',
          description: 'Home Insurance',
        },
        sourceSystem: 'Policy Star',
        active: true,
        status: 'ACTIVE',
        effectiveDate: '2019-08-02',
        expirationDate: '2020-08-02',
        productSpecificInformation: 'Number of Passenger Vehicles: 0',
      },
    ],
  },
  authorizations: {
    callRespMessage: 'Success',
    productAuthorization: [
      {
        productId: 'P12345678',
        canViewPolicy: 'Y',
        canMakePolicyChanges: 'Y',
        canSubmitPayment: 'Y',
        canSubmitInsuranceQuestionaire: 'Y',
        canViewProofOfInsurance: 'Y',
      },
      {
        productId: 'A12345678',
        canViewPolicy: 'Y',
        canMakePolicyChanges: 'Y',
        canSubmitPayment: 'Y',
        canSubmitInsuranceQuestionaire: 'Y',
        canViewProofOfInsurance: 'Y',
      },
    ],
  },
};

export const customerDetailsWithDuplicatesResponseEnterpriseFixture: CustomerDetailsResponseEnterprise = {
  customer: {
    customerId: '116310020099992',
    person: {
      gender: 'M',
      dateOfBirth: '1995-12-31',
      residenceAddress: {
        address: {
          streetAddress: '123 LAKE ST',
          city: 'SUNRISE BEACH',
          state: 'TX',
          zipCode: '78643',
          zipPlus4: '9395',
          countryCode: '',
        },
      },
    },
    preferredEmailAddress: {
      emailAddress: 'bruce@email.com',
      lastUpdateDate: '2017-12-01',
    },
    numberOfProducts: 3,
    productSummaries: [MEMBERSHIP_6202521234567800, HOME_TEN_THO3456789, HOME_TEN_THO3456789],
  },
  authorizations: {
    callRespMessage: 'Success',
    productAuthorization: [HOME_TEN_THO3456789_AUTH],
  },
};

export const customerDetailsResponseEnterpriseFixturePIT: CustomerDetailsResponseEnterprise = {
  customer: {
    customerId: '116310020099992',
    person: {
      gender: 'M',
      dateOfBirth: '1995-12-31',
      residenceAddress: {
        address: {
          streetAddress: '123 LAKE ST',
          city: 'SUNRISE BEACH',
          state: 'TX',
          zipCode: '78643',
          zipPlus4: '9395',
          countryCode: '',
        },
      },
    },
    preferredEmailAddress: {
      emailAddress: 'bruce@email.com',
      lastUpdateDate: '2017-12-01',
    },
    numberOfProducts: 3,
    productSummaries: [MEMBERSHIP_6202521234567800, HOME_HOB_THO123456789, AUTO_PPA_TPA123456789],
  },
  authorizations: {
    callRespMessage: 'Success',
    productAuthorization: [HOME_HOB_THO123456789_AUTH, AUTO_PPA_TPA123456789_AUTH],
  },
};
