import { aceMetaFixture } from './aceMetaFixture';
import { configFixtureApp } from './configFixture';
import { NotificationType, IconFlags, ClubCodes, CardCodeEnum } from '../common/enums';
import {
  NotificationsRequestApp,
  NotificationsRequestService,
  NotificationsResponseService,
  NotificationsResponseApp,
  Notification,
} from '../models/notificationsModel';
import { ProductType } from '../contracts/ProductType';

export const notificationsModelFixture: Notification[] = [
  {
    code: CardCodeEnum.BILLING,
    type: NotificationType.BILLING_DUE,
    title: `Plus Membership`,
    productIdLabel: 'Membership',
    productStatus: 'VALD',
    productId: '12345671',
    productType: ProductType.MEMBERSHIP,
    payPlan: 'AM',
    isAutoPay: false,
    paymentType: 'Credit Card',
    lastFourDigits: '5512',
    date: 'November 14, 2021',
    amount: '10.00',
    paymentEnabled: true,
    cancellationEnabled: false,
    showPaymentDetails: true,
    showPayNowLabel: false,
    payBillUrl: '',
    iconFlag: IconFlags.IS_PASTDUE,
    showRecurringIconFlag: false,
    message: configFixtureApp.Messages.NotificationMessages.PaymentPastDue,
    isPastDue: true,
    showUpdatePaymentAccountsLink: false,
    updatePaymentAccountsUrl: '',
    highlightCreditCardNum: false,
  },
];

export const notificationsIQModelFixture: Notification[] = [
  {
    type: NotificationType.BILLING_DUE,
    title: 'Premier membership',
    productIdLabel: 'Membership',
    productId: '62544412345671234',
    productStatus: 'VALD',
    paymentEnabled: true,
    iconFlag: IconFlags.IS_PASTDUE,
    paymentType: 'Credit Card',
    date: 'November 01, 2019',
    amount: '1000',
    payBillUrl: '',
    updatePaymentAccountsUrl: '',
    lastFourDigits: '5512',
    isAutoPay: true,
    isPastDue: true,
    message: configFixtureApp.Messages.NotificationMessages.PaymentPastDue,
    productType: ProductType.MEMBERSHIP,
  },
  {
    type: NotificationType.INSURANCE_QUESTIONNAIRE,
    title: 'IQ',
    productIdLabel: '',
    productId: 'TPA123456789',
    message: 'testMessage',
    productType: ProductType.INSURANCE_QUESTIONNAIRE,
    productStatus: 'NORM',
    showRecurringIconFlag: false,
    paymentEnabled: false,
    paymentType: '',
    date: '',
    amount: '0.00',
    payBillUrl: '',
    updatePaymentAccountsUrl: '',
    isAutoPay: false,
    payPlan: '',
    lastFourDigits: '',
    cancellationEnabled: false,
    isPastDue: false,
    showUpdatePaymentAccountsLink: false,
    highlightCreditCardNum: false,
    rank: 1,
    canSubmitPayment: true,
    primaryUser: '',
    externalFields: undefined,
  },
];

// App
export const notificationsRequestAppFixture: NotificationsRequestApp = {
  aceMeta: aceMetaFixture,  
};

export const notificationsResponseAppFixture: NotificationsResponseApp = {
  aceMeta: aceMetaFixture,
  data: {
    notifications: notificationsModelFixture,
  },
};

// Service
export const notificationsRequestServiceFixture: NotificationsRequestService = {
  customerId: '116310020099992',
  clubCode: ClubCodes.TX,
};

export const notificationsResponseServiceFixture: NotificationsResponseService = {
  notifications: notificationsModelFixture,
};

export const notificationsModelFixtureCanSubmitPayment: Notification[] = [
  {
    type: NotificationType.BILLING_DUE,
    title: 'Premier membership',
    productIdLabel: '*****112',
    productId: '*****112',
    productStatus: 'VALD',
    paymentEnabled: true,
    iconFlag: IconFlags.IS_PASTDUE,
    paymentType: 'Credit Card',
    date: 'November 01, 2019',
    amount: '1000',
    payBillUrl: '',
    updatePaymentAccountsUrl: '',
    lastFourDigits: '5512',
    isAutoPay: true,
    isPastDue: true,
    message: configFixtureApp.Messages.NotificationMessages.PaymentPastDue,
    productType: ProductType.MEMBERSHIP,
    canSubmitPayment: true,
  },
  {
    type: NotificationType.BILLING_DUE,
    title: 'string',
    productIdLabel: 'string',
    productId: 'string',
    productStatus: 'NORM',
    message: '',
    paymentEnabled: false,
    iconFlag: IconFlags.IS_PASTDUE,
    paymentType: '',
    date: 'January 01, 1999',
    amount: '1000',
    payBillUrl: '',
    updatePaymentAccountsUrl: '',
    canSubmitPayment: true,
    productType: ProductType.AUTO,
  },
  {
    type: NotificationType.BILLING_DUE,
    title: 'string',
    productIdLabel: 'string',
    productStatus: 'NORM',
    productId: 'string',
    message: '',
    paymentEnabled: false,
    iconFlag: IconFlags.IS_PASTDUE,
    paymentType: '',
    date: 'January 01, 1999',
    amount: '1000',
    payBillUrl: '',
    updatePaymentAccountsUrl: '',
    canSubmitPayment: true,
    productType: ProductType.HOME,
  },
  {
    type: NotificationType.BILLING_DUE,
    title: 'string',
    productIdLabel: 'string',
    productId: 'string',
    productStatus: 'NORM',
    message: '',
    paymentEnabled: false,
    iconFlag: IconFlags.IS_PASTDUE,
    paymentType: '',
    date: 'January 01, 1999',
    amount: '1000',
    payBillUrl: '',
    updatePaymentAccountsUrl: '',
    canSubmitPayment: true,
  },
];
