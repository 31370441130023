import { AceMeta } from "shared/contracts/AceMeta";
import { Login } from '../contracts/Login';
import { HttpResponseHandler } from '../contracts/HttpResponseHandler';
import { TaggingModel } from './taggingModel';

export interface AnalyticsModel {
  productCount: number;
  membershipType: string;
  hasAutoPay: boolean;
  isInRenewal: boolean;
  roleCode: string;
  gender: any;
  ageRange1: number;
  ageRange2: number;
  hasEmailAddress: boolean;
  emailLastUpdate: number;
  yearsAsAAAMember: number;
  hasAutoInsurance: boolean;
  hasAutoInsuranceAutoPay: boolean;
  numberOfAutoPolicies: number;
  hasHomeInsurance: boolean;
  hasHomeInsuranceAutoPay: boolean;
  numberOfHomePolicies: number;
}

export const AnalyticsModelKeys = {
  productCount: 'e01',
  membershipType: 'm01',
  hasAutoPay: 'm02',
  isInRenewal: 'm03',
  roleCode: 'm04',
  gender: 'm05',
  ageRange1: 'm06',
  ageRange2: 'm07',
  hasEmailAddress: 'm08',
  emailLastUpdate: 'm09',
  yearsAsAAAMember: 'm10',
  hasAutoInsurance: 'i01',
  hasAutoInsuranceAutoPay: 'i02',
  numberOfAutoPolicies: 'i03',
  hasHomeInsurance: 'i10',
  hasHomeInsuranceAutoPay: 'i11',
  numberOfHomePolicies: 'i12',
};

export interface AnalyticsRequestApp {
  aceMeta: AceMeta;
  login?: Login;
}
export interface AnalyticsResponseApp {
  aceMeta: AceMeta;
  httpResponseHandler: HttpResponseHandler;
  analytics: TaggingModel;
}
