import * as React from 'react';
import { Modal } from '@clublabs/ace-component-library';
import { withStyles } from '@material-ui/core';
import { cancelModalStyles, StyleProps } from './CancelPaymentModalStyles';
export interface CancelPaymentModalProps {
  onClick: () => void;
  openModal: boolean;
  title: string;
  subTitle: string;
  actionButtons: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  disableCloseIcon: boolean;
  id: string;
}

const CancelPaymentModal: React.FunctionComponent<CancelPaymentModalProps & StyleProps> = ({
  onClick,
  openModal,
  title,
  actionButtons,
  subTitle,
  classes,
  disableCloseIcon,
  id
}) => {
  return (
    <Modal
      disableCloseIcon={disableCloseIcon}
      actionButtons={actionButtons}
      title={title}
      subTitle={subTitle}
      onClose={onClick}
      openModal={openModal}
      id={id}
      disableBackdropClick={true}
      className={classes.root}
    />
  );
};

export default withStyles(cancelModalStyles, {withTheme: true})(CancelPaymentModal);
