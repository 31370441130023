import {
  MembershipOptionsResponseEnterprise,
  MembershipOptionsRequestService,
  MembershipOptionsResponseService,
  MembershipOption,
  MembershipOptionsDisplays,
} from '../models/membershipOptionsModel';
import { IconFlags, ClubCodes, MembershipOptionEnum } from '../common/enums';

export const membershipOptionsFixture: MembershipOption[] = [
  {
    display: MembershipOptionsDisplays.PAY_MY_BILL,
    flags: [IconFlags.IS_PASTDUE],
    id: MembershipOptionEnum.PAY_MY_BILL,
    newWindow: false,
    value: 'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1',
  },
  {
    display: MembershipOptionsDisplays.MANAGE_MEMBERSHIP,
    flags: [],
    id: MembershipOptionEnum.MANAGE_MEMBERSHIP,
    newWindow: false,
    value: 'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1',
  },
  {
    display: MembershipOptionsDisplays.GET_MEMBERSHIP_CARD,
    flags: [],
    id: MembershipOptionEnum.GET_MEMBERSHIP_CARD,
    newWindow: false,
    value: 'https://apps.<domain>.aaa.com/aceapps/replacementcard/request',
  },
  {
    display: MembershipOptionsDisplays.MANAGE_AUTOMATIC_PAYMENTS,
    flags: [],
    id: MembershipOptionEnum.MANAGE_AUTOMATIC_PAYMENTS,
    newWindow: false,
    value:
      'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1&activity=AutoRenew',
  },
  {
    display: MembershipOptionsDisplays.ENROLL_AUTOMATIC_PAYMENTS,
    flags: [],
    id: MembershipOptionEnum.ENROLL_AUTOMATIC_PAYMENTS,
    newWindow: false,
    value: 'https://apps.<domain>.aaa.com/aceapps/membership/ChangeMembership/ReviewMidtermMembership?startup=1',
  },
  {
    display: MembershipOptionsDisplays.VIEW_MEMBER_GUIDE,
    flags: [],
    id: MembershipOptionEnum.VIEW_MEMBER_GUIDE,
    newWindow: false,
    value: 'https://www.calif.aaa.com/content/dam/ace/pdf/TX-MemberGuide.pdf',
  },
  {
    display: MembershipOptionsDisplays.DONT_SEE,
    flags: [],
    id: MembershipOptionEnum.DONT_SEE,
    newWindow: false,
    value: 'https://www.<domain>.aaa.com/membership/why-join-aaa.html#faqs',
  },
];

export const membershipOptionsRequestServiceFixture: MembershipOptionsRequestService = {
  customerId: '116310020099992',
  clubCode: ClubCodes.TX,
};

export const membershipOptionsResponseServiceFixture: MembershipOptionsResponseService = {
  membershipOptions: membershipOptionsFixture,
};

// misleading. membership options are not hydrated from an enterprise call. so why is this called ResponseEnterprise?
// for now adding missing fields. This is used mostly in membershipOptionsStubAPI
//
export const membershipOptionsEnterpriseFixture: MembershipOptionsResponseEnterprise = {
  membershipOptions: [
    { id: 'PAY_MY_BILL', value: '', display: MembershipOptionsDisplays.PAY_MY_BILL, newWindow: false },
    { id: 'MANAGE_MEMBERSHIP', value: '', display: MembershipOptionsDisplays.MANAGE_MEMBERSHIP, newWindow: false },
    { id: 'GET_MEMBERSHIP_CARD', value: '', display: MembershipOptionsDisplays.GET_MEMBERSHIP_CARD, newWindow: false },
    {
      id: 'MANAGE_AUTOMATIC_PAYMENTS',
      value: '',
      display: MembershipOptionsDisplays.MANAGE_AUTOMATIC_PAYMENTS,
      newWindow: false,
    },
    { id: 'VIEW_MEMBER_GUIDE', value: '', display: MembershipOptionsDisplays.VIEW_MEMBER_GUIDE, newWindow: false },
    { id: 'DONT_SEE', value: '', display: MembershipOptionsDisplays.DONT_SEE, newWindow: false },
  ],
};
