import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { WithStyles, createStyles } from '@material-ui/core';

const notificationCardStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 0,
    },
    rootEmptyResults: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    notificationMessage: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 14,
      paddingRight: 14,
    },
    notificationMessageIcon: {},
    headLine: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fontSize: 32,
        [theme.breakpoints.down('sm')]: {
          fontSize: 28,
        },
      },
    },
    headLineText: {
      paddingLeft: 5,
    },
    notificationCard: {
      flexDirection: 'column',
      marginTop: 16,
    },
    customFlex: {
      display: 'flex',
      alignItems: 'center',
    },
    amountText: {
      flexDirection: 'row',
      margin: 0,
    },
    payWaitNotification: {
      color: 'gray',
      fontSize: 'medium',
    },
    cardHeader: {
      borderBottom: 'solid 1px #717174',
      '& .MuiCardHeader-title': {
        fontSize: 18,
        [theme.breakpoints.up('lg')]: {
          fontSize: 22,
        },
      },
    },
    cardContent: {
      padding: 4,
      '& .Body': {
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 12,
        paddingRight: 12,
      },
      '& #pay-status': {
        height: 40,
        paddingLeft: 5,
        [theme.breakpoints.down('sm')]: {
          height: 40,
        },
      },
    },
    payStatus: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'RGB(241, 242, 244)',
    },
    payStatusIcon: {
      display: 'flex',
      paddingRight: '0px !important',
    },
    pastDue: {
      color: '#AE540A',
    },
    highlightedText: {
      color: '#AE540A',
      // marginLeft: -10
    },
    cardActions: {
      display: 'flex',
      flexDirection: 'row-reverse',
      padding: 8,
      '& button': {
        margin: '4px',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        paddingTop: '0px',
      },
    },
    cardAction: {
      marginLeft: 'auto',
    },
    notificationAlert: {
      paddingLeft: 8,
      display: 'flex',
      flexDirection: 'row',
    },
    notificationAlertIcon: {
      display: 'flex',
      flexDirection: 'column',
    },
    notificationAlertMessage: {
      display: 'flex',
      flexDirection: 'column',
    },
    verticalActions: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      paddingTop: 8,
      padding: 16,
      '& a': {
        margin: 0,
        width: '100%',
        textAlign: 'center',
      },
    },
    doNothing: {},
    showBackground: {
      padding: 14,
      backgroundColor: '#e0e2e9',
    },

    rightColumn: {
      textAlign: 'right',
      paddingRight: 8,
    },
    bold: {
      fontWeight: 'bold',
      '&.Body': {
        fontWeight: 'bold',
      },
    },
    pitGrid: {
      margin: '0.8em 0',
    },
  });

export type StyleProps = WithStyles<typeof notificationCardStyles>;

export default notificationCardStyles;
