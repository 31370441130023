import {
  TaggingSource,
  ConfigResponseApp,
  ProductStatus,
  MvcRedirectKey,
  WebLogLevel,
  FormPostKeyValue,
  ProductType,
  MvcRedirectMode,
} from 'shared';
import { WebUtils, utility } from 'utils';
import { ModalState } from '../../redux/store/Reducers/modal.reducer';
import ReactHTMLParser from 'react-html-parser';
import { tagCancelledPolicyPayBillAction } from './TaggingHelper';
import { SET_MODAL } from '../../redux/store/Actions/modal.actions';

const handleReinstatablePolicyModal = (
  productId: string,
  taggingSource: TaggingSource,
  config: ConfigResponseApp,
  args
) => {
  const { aceMeta } = WebUtils.getMetadata();
  const { userState, analytics, modalState, dispatch, setModalState, customerDetails, extraFields, payBillUrl } = args;
  const product = userState?.products?.find((product) => product.productId === productId);
  const page: MvcRedirectKey = MvcRedirectKey.ONLINE_BILL_PAY;
  const { billingSummary } = product;

  try {
    if (product && config && userState) {
      // tag the reinstate modal
      if (analytics) {
        analytics.model.policystate =
          product.productStatus && ProductStatus.findByCode(product.productStatus).taggingValue;
        tagCancelledPolicyPayBillAction(analytics.model, taggingSource);
      }

      const policyExpiryDate = product.reinstateDate || product.productExpiration;
      const reinstateModalState = {
        cancelledPolicyModal: true,
        cancelledPolicyDescription: ReactHTMLParser(
          config && config.Messages.ModalDialogMessages.CancelledPolicyReinstatement.description
        ),
        cancelledPolicyMessage: utility.replaceURLTemplateStrings(
          config && config.Messages.ModalDialogMessages.CancelledPolicyReinstatement.actionMessage,
          ['<cancel_date>'],
          [policyExpiryDate]
        ),
        taggingSource: '',
        extraFields: {},
        payBillUrl: payBillUrl || config['InsuranceURLs']['billPayAWSRedirectURL'],
      };

      // externalFields is passed in, we are coming from notification
      if (extraFields) {
        reinstateModalState.taggingSource = taggingSource;
        reinstateModalState.extraFields = extraFields;
        if (dispatch)
          dispatch({
            type: SET_MODAL,
            payload: { ...modalState, ...reinstateModalState },
          });
        else
          setModalState({
            ...modalState,
            ...reinstateModalState,
          } as unknown as ModalState);
      } else {
        const { products } = customerDetails;
        const membershipProduct = products.find((p) => p.type === ProductType.MEMBERSHIP);
        const formPostKeyValues: FormPostKeyValue[] = [
          { key: 'key', value: page },
          { key: 'policynumber', value: product.productId },
          { key: 'fullPolicyNumber', value: product.productId },
          { key: 'mode', value: MvcRedirectMode.PAYBUTTON },
          { key: 'clubcode', value: customerDetails.user.clubCode },
          { key: 'primaryEmail', value: customerDetails.preferredEmailAddress.emailAddress },
          { key: 'customerID', value: customerDetails.customerId },
          { key: 'memberNumber', value: membershipProduct?.productId },
          { key: 'allowAutoPay', value: 'false' },
          {
            key: 'allowPayment',
            value: billingSummary?.hasMinimumAmountDue || billingSummary?.hasBalanceDue ? 'true' : 'false',
          },
          { key: 'hasExistingSchedPmt', value: billingSummary?.hasPaymentScheduled },
          {
            key: 'existingSchedPmtAmtString',
            value: billingSummary?.hasPaymentScheduled ? billingSummary?.amount : '0.00',
          },
          {
            key: 'existingSchedPmtDateString',
            value: billingSummary?.hasPaymentScheduled ? billingSummary?.billingDate : '',
          },
          { key: 'existingSchedPmtConfNbr', value: '' },
          { key: 'insMinDue', value: billingSummary?.hasMinimumAmountDue ? 'true' : 'false' },
          { key: 'policyRenewal', value: billingSummary?.isInRenewal ? 'true' : 'false' },
          {
            key: 'insCurrentTerm',
            value:
              billingSummary && billingSummary.billPlan?.length >= 4
                ? billingSummary?.billPlan.substring(3) === '9'
                  ? '9'
                  : '10'
                : '',
          },
          { key: 'useSystemAgnosticCall', value: true },
          { key: 'cancelSchedPmtExecuted', value: false },
          { key: 'hasLapseAccidents', value: '' },
          { key: 'clientId', value: product.clientId },
        ];

        reinstateModalState.taggingSource = TaggingSource.Blade;
        reinstateModalState.extraFields = formPostKeyValues;
        setModalState({
          ...modalState,
          ...reinstateModalState,
        } as unknown as ModalState);
      }
    }
  } catch (error: any) {
    WebUtils.logWeb({
      message: 'Failed to launch reinstate modal box',
      level: WebLogLevel.error,
      data: WebUtils.constructLogData({ error }, userState?.customerId, userState?.membershipNumber),
      aceMeta,
    });
  }
};

export const CommonHandlers = {
  handleReinstatablePolicyModal,
};
