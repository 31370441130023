import moment from "moment-timezone";
import { MaintenanceStatusModel } from '../shared';
export const TIME_ZONE = 'America/Los_Angeles';

export const readMaintenanceStatus = (config, date): MaintenanceStatusModel  => {  
  const dayOfTheWeek = date.day().toString();
  const schedule = config["Maintenance"]["ScheduleByDays"][dayOfTheWeek];

  let maintenanceStatus: MaintenanceStatusModel = {
    showMaintenancePage: false,
    start: null,
    end: null,
    date: null,
    roadsideUrl: config["Maintenance"]["RoadsideUrl"],
    helpPhoneNumber: config["Maintenance"]["HelpPhoneNumber"],
    headerLogoUrl: config['headerLogoURL']
  }

  if (schedule) {
    const [ startHour, startMinutes ] = schedule["StartTime"].split(":");
    const [ endHour, endMinutes ] = schedule["EndTime"].split(":");
    const start = moment(date)
      .tz(TIME_ZONE)
      .set({ hour: startHour, minute: startMinutes, second: 0, millisecond:0 });
    const end = moment(date)
      .tz(TIME_ZONE)
      .set({ hour: endHour, minute: endMinutes, second: 0,millisecond: 0 });
    
    maintenanceStatus.showMaintenancePage = date.isBetween(start, end);
    maintenanceStatus.start = start.toDate();
    maintenanceStatus.end = end.toDate();
    maintenanceStatus.date = date.toDate();
  }
  
  return maintenanceStatus;
};

