import {
  httpResponseHandlerFixture,
  MembershipOptionsContractApp,
  membershipOptionsFixture,
  MembershipOptionsRequestApp,
  MembershipOptionsResponseApp,
} from 'shared';
import { RemotePost } from '../fetchers';

export const MembershipOptionsRemoteApp = (endpoint: string): MembershipOptionsContractApp =>
  RemotePost<MembershipOptionsRequestApp, MembershipOptionsResponseApp>(endpoint);

export const MembershipOptionsStubApp =
  (_endpoint: string): MembershipOptionsContractApp =>
  async () => ({
    aceMeta: {
      sessionId: 'some-correlation-id',
      timestamp: '2022-02-16T23:45:40.212Z',
      useMock: false
    },
    httpResponseHandler: httpResponseHandlerFixture, 
    membershipOptions: membershipOptionsFixture 
  });
