import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import AppContainer from './AppContainer';
import Root from './Root';

ReactDOM.render(
  <Root>
    <AppContainer />
  </Root>,
  document.getElementById('root')
);
