export type IServiceError = {
  Severity: string;
  ComponentErrorCode: string;
  ErrorMessage: string;
  ComponentName: string;
  SessionId: string;
  CustomerId: string;
};

export class ServiceError implements IServiceError {
  constructor(
    public Severity: string,
    public ComponentErrorCode: string,
    public ErrorMessage: any,
    public ComponentName: string,
    public ComponentStack: Array<string>,
    public SessionId: string,
    public CustomerId: string
  ) {}
}
export type ServiceErrorResponse = {
  Error: Error;
};
