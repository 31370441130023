import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import { FormPostKeyValue } from 'shared/contracts/FormPostKeyValue';
import { MvcRedirectMode, ProductType } from 'shared';

class Utility {
  public toBoolean(input: string): boolean {
    const str = input && input.trim().toLowerCase();
    return str === 'true' || str === 'y' || str === 'yes';
  }

  /**
   * Creates a moment instance of a date string
   * @param d
   */
  public toMoment(d: string) {
    return moment(d);
  }

  public toDisplayDate(d: string) {
    const displayDate = moment(d).format('LL');

    // invalid date is a default return string from moment.js
    return displayDate.indexOf('Invalid date') > -1 ? undefined : displayDate;
  }

  public formatDoBForIIB(date: string) {
    return date.replace(/-/g, '/');
  }

  public currentYear(): number {
    return moment().year();
  }

  // for testing
  public addDays(d: number) {
    return moment().add(d, 'days').format('YYYY-MM-DD');
  }

  /**
   * Date difference
   * @param fromDate
   * @param toDate
   */
  public dateDiff(fromDate: string, toDate: string) {
    const a = moment(fromDate, 'YYYY-MM-DD');
    const b = moment(toDate, 'YYYY-MM-DD');
    return b.diff(a, 'days'); // 1
  }

  public toCamelText(input: string) {
    //TODO: this should be handled through css
    if (input) {
      return input.charAt(0).toUpperCase() + input.toLowerCase().substring(1);
    } else {
      return '';
    }
  }

  public replaceURLTemplateStrings(template: string, find: string[], replace: string[]) {
    if (template) {
      for (let i = 0; i < find.length; i++) {
        template = template.replace(new RegExp(find[i], 'gi'), replace[i]);
      }
    }
    return template;
  }

  // Be careful about using this method as your source object must be JSON safe.
  public cloneFixture<T>(fixture: T): T {
    return cloneDeep(fixture);
    //return JSON.parse(JSON.stringify(fixture));
  }

  // matches the 8 digits in the middle
  public isMatchingMembershipId(fromCustomerDetail: string, fromBillingSummary: string): boolean {
    return (
      fromCustomerDetail != null &&
      fromBillingSummary != null &&
      fromCustomerDetail.slice(-10, -2) === fromBillingSummary.slice(-11, -3)
    );
  }

  public insertInBetween(original: string, insertString: string, lastString: string): string {
    const lastIndex = original.lastIndexOf(lastString);
    return original.substring(0, lastIndex) + insertString + original.substring(lastIndex);
  }

  public formatMembership(membershipNumber: string | undefined) {
    let result = '';
    if (membershipNumber && membershipNumber.trim().length === 16) {
      const firstSplit = this.insertInBetween(
        membershipNumber,
        ' ',
        membershipNumber.slice(3, membershipNumber.length)
      );
      result = this.insertInBetween(firstSplit, ' ', firstSplit.slice(7, firstSplit.length));
    }
    return result;
  }

  public maskCustomerId = (customerId: string, numberOfDigitsToShow: number = 4): string => {
    let maskedCustId = '';
    for (let i = 0; i < customerId?.length; i++) {
      maskedCustId += i < customerId.length - numberOfDigitsToShow ? '*' : customerId[i];
    }
    return maskedCustId;
  };

  public maskMembershipNumber = (membershipNumber: string, numberOfDigitsToShow: number = 8): string => {
    let maskedMemberNumber = '';
    for (let i = 0; i < membershipNumber?.length; i++) {
      maskedMemberNumber += i < membershipNumber.length - numberOfDigitsToShow ? '*' : membershipNumber[i];
    }
    return maskedMemberNumber;
  };

  public loadExternalJavascriptLibraries = (url) => {
    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    document.body.appendChild(scriptTag);
  };

  public stripUrl = (url) => {
    const urlPrefixes = ['/EnterpriseServices', '/api', '/cache', '/config'];
    for (const prefix of urlPrefixes) {
      const idx = url.indexOf(prefix);
      if (idx !== -1) {
        return url.substring(idx);
      }
    }

    // in case we missed anything, don't log anything at all
    return url;
  };

  /** converts a simple flattened object to a javascript map */
  public fromObjectToMap = (flatJsonObject) => {
    const map = new Map();
    Object.keys(flatJsonObject).forEach((objectKey) => map.set(objectKey, flatJsonObject[objectKey]));
    return map;
  };

  /** adds new query string params */
  public addQueryStringParams = (url: string, key: string, val: string) => {
    const newParam = `${key}=` + encodeURIComponent(val);
    const separator = url.lastIndexOf('?') > 0 ? '&' : '?';

    return url + separator + newParam;
  };

  // Deprecated. Not used anywhere
  public mapExternalFieldsObjtoArray = (externalFields) => {
    const extraFields: FormPostKeyValue[] = [];

    if (externalFields) {
      if (externalFields.key) {
        extraFields.push({ key: 'key', value: externalFields.key });
      }
      if (externalFields.policyNumber) {
        extraFields.push({ key: 'fullPolicyNumber', value: externalFields.policyNumber });
      }
      if (externalFields.mode) {
        extraFields.push({ key: 'mode', value: externalFields.mode });
      }
      if (externalFields.payBillFields) {
        extraFields.push({ key: 'primaryEmail', value: externalFields.payBillFields.primaryEmail });
        extraFields.push({ key: 'customerID', value: externalFields.payBillFields.customerID });
        extraFields.push({ key: 'memberNumber', value: externalFields.payBillFields.memberNumber });
        extraFields.push({ key: 'allowAutoPay', value: externalFields.payBillFields.allowAutoPay });
        extraFields.push({ key: 'allowPayment', value: externalFields.payBillFields.allowPayment });
        extraFields.push({ key: 'hasExistingSchedPmt', value: externalFields.payBillFields.hasExistingSchedPmt });
        extraFields.push({
          key: 'existingSchedPmtAmtString',
          value: externalFields.payBillFields.existingSchedPmtAmtString,
        });
        extraFields.push({
          key: 'existingSchedPmtDateString',
          value: externalFields.payBillFields.existingSchedPmtDateString,
        });
        extraFields.push({
          key: 'existingSchedPmtConfNbr',
          value: externalFields.payBillFields.existingSchedPmtConfNbr,
        });
        extraFields.push({ key: 'insMinDue', value: externalFields.payBillFields.insMinDue });
        extraFields.push({ key: 'policyRenewal', value: externalFields.payBillFields.policyRenewal });
        extraFields.push({ key: 'insCurrentTerm', value: externalFields.payBillFields.insCurrentTerm });
        extraFields.push({ key: 'useSystemAgnosticCall', value: externalFields.payBillFields.useSystemAgnosticCall });
        extraFields.push({ key: 'cancelSchedPmtExecuted', value: externalFields.payBillFields.cancelSchedPmtExecuted });
        extraFields.push({ key: 'hasLapseAccidents', value: externalFields.payBillFields.hasLapseAccidents });
        extraFields.push({ key: 'clientId', value: externalFields.payBillFields.clientId });
      }
    }
    return extraFields;
  };

  public getInsuranceMode = (productType) => {
    let modeValue: MvcRedirectMode | null = null;

    switch (productType) {
      // auto
      case ProductType.AUTO:
      case ProductType.CLASSIC: {
        modeValue = MvcRedirectMode.AUTO;
        break;
      }
      // home
      case ProductType.HOME:
      case ProductType.CONDO:
      case ProductType.RENTAL_DWELLING:
      case ProductType.TENANT: {
        modeValue = MvcRedirectMode.HOME;
        break;
      }
      case ProductType.EARTHQUAKE:
      case ProductType.CALIFORNIA_EARTHQUAKE: {
        modeValue = MvcRedirectMode.HOME;
        break;
      }
      case ProductType.WATERCRAFT: {
        modeValue = MvcRedirectMode.WATERCRAFT;
        break;
      }
      case ProductType.UMBRELLA: 
      case ProductType.PERSONAL_UMBRELLA: {
        modeValue = MvcRedirectMode.PERSONAL_UMBRELLA;
        break;
      }
    }
    return modeValue;
  };
}

export const utility = new Utility();
