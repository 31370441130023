import {
  TravelBookingContractApp,
  TravelBookingRequestApp,
  TravelBookingResponseApp,
  travelBookingResponseFixture
} from 'shared';
import { RemotePost } from '../fetchers';

export const TravelBookingRemoteApp = (endpoint: string): TravelBookingContractApp =>
  RemotePost<TravelBookingRequestApp, TravelBookingResponseApp>(endpoint);

export const TravelBookingStubApp =
  (_endpoint: string): TravelBookingContractApp => async () => travelBookingResponseFixture;
