import { withStyles, Theme } from '@material-ui/core';
import { Button, Headline, Label } from '@clublabs/ace-component-library';
import { FunctionComponent, useEffect, useState } from 'react';
import { ServiceError, WebUtils } from '../../utils';
import { ClubCodes, ErrorTypes } from '../../shared';
import history from '../../Router/history';
import { useGlobalState } from '../../shared/state';
import { useSelector } from 'react-redux';
import { AnalyticsState } from 'redux/store/Reducers/analytics.reducer';
import { RootState } from 'redux/store/rootReducer';
import { tagMembershipCancelledView } from '../Common/TaggingHelper';

interface ErrorProps {
  error: ServiceError;
  clubCode?: ClubCodes;
  type?: ErrorTypes;
  classes?: any;
}

enum Destination {
  Rejoin = 'rejoin',
  Homepage = 'homepage',
}

const errorScreenStyles = (theme: Theme): any => ({
  root: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {

  },
  description: {
    marginTop: '24px',
    marginBottom: '24px',
  },    
  buttonPrimary: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  buttonSecondary: {
    marginBottom: '24px',
  },
});

const MembershipCancelledError = (props: any) => {
  const [config] = useGlobalState('config');
  const [primaryBtnLoading, setPrimaryBtnLoading] = useState(false);
  const [secondaryBtnLoading, setSecondaryBtnLoading] = useState(false);

  const analyticsState = useSelector<RootState, AnalyticsState>((state) => state.analyticsState as AnalyticsState);

  useEffect(() => {
    tagMembershipCancelledView(analyticsState.model);
  }, []); 

  const getMembershipJoinUrl = (config: any, clubCode: ClubCodes): string => {
    const membershipJoinUrl = (config?.MembershipJoinAWSEnabled?.includes(clubCode) || config?.MembershipJoinAWSEnabled?.includes('ALL'))
      ? config?.MembershipURLs?.membershipJoinAWSURL
      : config?.MembershipURLs?.membershipJoinURL;
    return membershipJoinUrl ?? '';
  };

  const handleRedirect = (destination: Destination) => {
    switch (destination) {
      case Destination.Rejoin: {
        setPrimaryBtnLoading(true);
        WebUtils.externalLinkHandler(getMembershipJoinUrl(config, props.clubCode), false, true).catch(() => {});
        setPrimaryBtnLoading(false);
        break;
      }
      case Destination.Homepage: 
      default: {
        setSecondaryBtnLoading(true);
        WebUtils.externalLinkHandler(config?.AemHomeURL ?? '', false, true).catch(() => {});
        setSecondaryBtnLoading(false);
      }
    }
  }

  return (
    <>
      <Headline id="headline-membership-cancelled" className={props.classes.title}>Your membership has been cancelled</Headline>
      <Label id={'subHeadLine-membership-cancelled'} className={props.classes.description}>You can join AAA to restore your member benefits.</Label>
      <Button
        id="error-screen-btn-rejoin"
        onClick={() => handleRedirect(Destination.Rejoin)}
        className={props.classes.buttonPrimary}
        loading={primaryBtnLoading}
        >
        Join AAA again
      </Button>

      <Button
        id="error-screen-btn-homepage"
        onClick={() => handleRedirect(Destination.Homepage)}
        color="secondary"
        className={props.classes.buttonSecondary}
        loading={secondaryBtnLoading}
        >
        Back to homepage
      </Button>
    </>
  );
}

const Error: FunctionComponent<ErrorProps> = (props: ErrorProps) => {
  let content;
  const errorType = props.type || history.location?.state?.type;
  switch(errorType) {
    case ErrorTypes.MembershipCancelled: {
      content = <MembershipCancelledError { ...props } />;
      break;
    }
    case ErrorTypes.GenericError:
    default: {
      content = <div>{CustomErrorMessage(props.error)}</div>;
    }
  }
  return (
    <div className={props.classes.root}>
      {content}
    </div>
  );
};

const CustomErrorMessage = (error: ServiceError) => {
  let result;

  if (error) {
    switch (error.ComponentErrorCode) {
      case '401': {
        result = 'Error Code: 401 -  Not Authorized';
        break;
      }
      case '403': {
        result = 'Error Code: 403 -  Missing authorization token in header';
        break;
      }
      case '500': {
        result = 'Error Code: 500 - Fatal autorization error';
        break;
      }
      default:
        break;
    }
  }
  return result;
};
export default withStyles(errorScreenStyles)(Error);
