import { IconFlags } from 'shared';
import { ACEIcon, IconType } from '@clublabs/ace-component-library';
import SellBlack from '../../assets/images/sell_black_24dp.svg';
import React from 'react';

export const iconHelper = (flag?: IconFlags): JSX.Element => {
  switch (flag) {
    case IconFlags.IS_PASTDUE:
      return <ACEIcon type={IconType.HOUR_GLASS} style={{ color: '#ea700e' }} />;
    case IconFlags.IS_RECURRING:
      return <ACEIcon type={IconType.AUTO_PAY} />;
    case IconFlags.SELL_BLACK:
      return <img src={SellBlack} alt="Icon" />;
    default:
      return <React.Fragment />;
  }
};
