import { NotificationType } from '../common/enums';
import { Notification } from '../models/notificationsModel';
import { ProductType } from '../contracts/ProductType';

export const membershipNotificationFixtureApp: Notification = {
  type: NotificationType.BILLING_DUE,
  title: 'Membership',
  productIdLabel: '',
  productId: '62544412345671234',
  productStatus: 'VALD',
  message: '',
  productType: ProductType.MEMBERSHIP,
  showRecurringIconFlag: false,
  paymentEnabled: true,
  paymentType: '',
  date: '',
  amount: '1.00',
  payBillUrl: '',
  updatePaymentAccountsUrl: '',
  isAutoPay: false,
  payPlan: '',
  lastFourDigits: '',
  cancellationEnabled: false,
  isPastDue: false,
  showUpdatePaymentAccountsLink: false,
  highlightCreditCardNum: false,
  rank: 1,
  canSubmitPayment: true,
  primaryUser: '',
  externalFields: undefined,
};

export const insuranceNotificationFixtureApp: Notification = {
  type: NotificationType.BILLING_INFO,
  title: 'auto insurance',
  productIdLabel: '',
  productId: 'TPA123456789',
  productStatus: 'NORM',
  message: '',
  productType: ProductType.AUTO,
  showRecurringIconFlag: false,
  paymentEnabled: false,
  paymentType: '',
  date: '',
  amount: '0.00',
  payBillUrl: '',
  updatePaymentAccountsUrl: '',
  isAutoPay: false,
  payPlan: '',
  lastFourDigits: '',
  cancellationEnabled: false,
  isPastDue: false,
  showUpdatePaymentAccountsLink: false,
  highlightCreditCardNum: false,
  rank: 1,
  canSubmitPayment: true,
  primaryUser: '',
  externalFields: undefined,
};

export const iQNotificationFixtureApp: Notification = {
  type: NotificationType.INSURANCE_QUESTIONNAIRE,
  title: 'IQ',
  productIdLabel: '',
  productId: 'TPA123456789',
  productStatus: 'NORM',
  message: '',
  productType: ProductType.AUTO,
  showRecurringIconFlag: false,
  paymentEnabled: false,
  paymentType: '',
  date: '',
  amount: '0.00',
  payBillUrl: '',
  updatePaymentAccountsUrl: '',
  isAutoPay: false,
  payPlan: '',
  lastFourDigits: '',
  cancellationEnabled: false,
  isPastDue: false,
  showUpdatePaymentAccountsLink: false,
  highlightCreditCardNum: false,
  rank: 1,
  canSubmitPayment: true,
  primaryUser: '',
  externalFields: undefined,
};

export const notificationFixtureApp: Notification[] = [
  membershipNotificationFixtureApp,
  iQNotificationFixtureApp,
  insuranceNotificationFixtureApp,
];
