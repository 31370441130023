import * as React from 'react';
import { Headline } from '@clublabs/ace-component-library';
import { withStyles } from '@material-ui/core';

export interface WelcomeProps {
  message?: string;
  classes?: any;
}

const styleClasses = (): {
  root: any;
} => {
  return {
    root: {
      marginTop: 38,
      '& h1': {
        fontWeight: '700',
      },
    },
  };
};

const Welcome: React.FunctionComponent<WelcomeProps> = ({ message, classes }) => {
  return (
    <div className={classes.root}>
      <Headline id="welcomeMessage">{message}</Headline>
    </div>
  );
};

export default withStyles(styleClasses, { withTheme: true })(Welcome);
