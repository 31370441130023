import React, { ReactElement } from 'react';
import cx from 'clsx';
import { Card, CardContent, CardActions, CardHeader, withStyles } from '@material-ui/core';
import { Body } from '@clublabs/ace-component-library';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { Subheadline } from '@clublabs/ace-component-library';
import awarenessCardStyles from './AwarenessCardStyles';
import { CardCodeEnum } from 'shared';
import { utility } from 'utils';

export interface CardHeaderOption {
  // used for kabob on the header
  key: string;
  display: string;
  onClick: (opt: CardHeaderOption, evt: React.SyntheticEvent) => void;
}
interface RequiredProps {
  id: string;
  title: any;
  body: any;
  dismissable: boolean; // card can be closed/dismissed by user or not
}
interface OptionalProps {
  classes: any;
  className: any;
  cardActions: React.ReactFragment;
  cardHeaderOptions: CardHeaderOption[];
  onClose: (evt: React.SyntheticEvent) => void;
  productId: string;
  cardCode: string;
}
export type AwarenessCardProps = RequiredProps & Partial<OptionalProps>;

export const getSubheaderEl = (cardCode, productId, className): ReactElement | null => {
  switch (cardCode) {
    case CardCodeEnum.ENROLL_AUTO_PAY_MEMBERSHIP:
      return <span className={className} data-di-mask>{`Member #: ${utility.formatMembership(productId)}`}</span>;
    case CardCodeEnum.ENROLL_AUTO_PAY_INSURANCE:
      return <span className={className} data-di-mask>{`Policy #: ${productId}`}</span>;
    default:
      return null;
  }
};

export const AwarenessCard: React.FunctionComponent<AwarenessCardProps> = ({
  id,
  title,
  body,
  dismissable,
  classes,
  className,
  cardActions,
  cardHeaderOptions,
  onClose,
  productId,
  cardCode,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const menuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card data-quid={`Card-${id}`} className={cx('Card', classes.root, className)} key={`Card-${id}`}>
      <CardHeader
        data-quid={`${id}-Header`}
        className={classes.cardHeader}
        key={`CardHeader-${id}`}
        title={<Subheadline id={`${id}-SubHeadLine`}>{title}</Subheadline>}
        subheader={getSubheaderEl(cardCode, productId, classes.cardSubHeader)}
        action={
          dismissable && (
            <div>
              {cardHeaderOptions && cardHeaderOptions.length > 0 ? (
                <div>
                  <IconButton data-quid={`${id}-OptionIcon`} aria-label="settings" onClick={menuClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    role={undefined}
                    transition
                    disablePortal
                    placement={'bottom-end'}
                    className={classes.popper}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={menuClose}>
                            <MenuList data-quid={`${id}-MenuList`} className={classes.menuList}>
                              {cardHeaderOptions.map((option) => (
                                <MenuItem
                                  data-quid={`${option.key}-MenuItem`}
                                  className={classes.menuItem}
                                  key={option.key}
                                  onClick={(e) => {
                                    option.onClick(option, e);
                                  }}
                                >
                                  {option.display}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              ) : (
                <CloseIcon data-quid={`${id}-CloseIcon`} className={classes.closeIcon} onClick={onClose} />
              )}
            </div>
          )
        }
      ></CardHeader>
      <CardContent data-quid={`${id}-CardContent`} className={classes.cardContent} key={`CardContent-${id}`}>
        <Body id="CardContent-Body" key={`CardContent-${id}-Body`}>
          {body}
        </Body>
      </CardContent>
      <CardActions
        data-quid={`${id}-CardActions`}
        className={classes.cardActions}
        disableSpacing={true}
        key={`CardActions-${id}`}
      >
        {cardActions}
      </CardActions>
    </Card>
  );
};
export default withStyles(awarenessCardStyles)(AwarenessCard);
