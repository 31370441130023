import { ACEPrimaryTheme, Spinner } from '@clublabs/ace-component-library';
import React, { useEffect, useState } from 'react';
import { Route, Router } from 'react-router-dom';
import { useGlobalState } from './shared/state';
import { readMaintenanceStatus, TIME_ZONE } from './services/maintenanceStatusService';
import { ClubCodes, ConfigResponseApp, MaintenanceStatusModel, networkBrowserErrors, DataRegions } from 'shared';
import './AppContainer.scss';
import App from './Components/App/App';
import { ConfigContext } from './Components/Common/Context';
import Error from './Components/Error/Error';
import ErrorComp from './Components/Error/ErrorComp';
import Maintenance from './Components/Maintenance/Maintenance';
import history from './Router/history';
import { appSources, configService } from 'remotes';
import { setErrorState } from 'redux/store/Actions/error.actions';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { WebUtils } from 'utils';
import { v4 as uuid } from 'uuid';

const AppContainer: React.FunctionComponent = () => {
  const [config, setConfig] = useGlobalState('config');
  const [source, setSource] = useGlobalState('appSources');
  const [maintenanceStatus, setMaintenanceStatus] = useGlobalState('maintenanceStatus');
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const [onPageShowCalled, setOnPageShowCalled] = useState(false);

  const checkAreaQueryParam = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const areaQueryParam = 'area';
    const areaStorage = sessionStorage.getItem(areaQueryParam);
    const areaQueryValue = queryParams.get(areaQueryParam) ?? '';

    if (areaStorage && areaQueryValue) {
      sessionStorage.removeItem(areaQueryParam);
    } else if (areaStorage) {
      sessionStorage.removeItem(areaQueryParam);
      window.location.href = WebUtils.addQueryParam(window.location.href, areaQueryParam, areaStorage);
    } else if (areaQueryValue) {
      sessionStorage.setItem(areaQueryParam, areaQueryValue);
    }
  };

  window.onpageshow = (_e) => {
    const domain = new URL(window.location.href);
    const queryParams = new URLSearchParams(window.location.search);

    const backend = queryParams.get('backend');
    if (backend) {
      sessionStorage.setItem('dataRegion', backend.toUpperCase());
    } else if (!sessionStorage.getItem('dataRegion')) {
      sessionStorage.setItem('dataRegion', DataRegions.A1);
    }

    // TODO: deprecate this code when moving to final production version
    // const v3 = queryParams.get('v3');
    // if (v3 !== null) {
    //   if (v3) {
    //     sessionStorage.setItem('apiVersion', 'v3');
    //   } else {
    //     sessionStorage.setItem('apiVersion', 'v1');
    //   }
    // }
    // end TODO

    if (!sessionStorage.getItem('loginUrl') && !queryParams.has('ts')) {
      const port = domain.port ? `:${domain.port}` : '';
      queryParams.append('ts', Date.now().toString());
      window.location.href = `${domain.protocol}//${domain.hostname}${port}?${queryParams.toString()}`;
      return;
    }
    setOnPageShowCalled(true);
  };

  useEffect(() => {
    if (onPageShowCalled) {
      const clubCode = (WebUtils.getClubCode() as ClubCodes) || ClubCodes.CA;
      if (!Object.values(ClubCodes).includes(clubCode)) {
        window.location.href = 'https://aaa.com/stop/';
        return;
      }
      sessionStorage.setItem('clubCode', clubCode);
      const configRequest = {
        aceMeta: {
          clubCode,
          correlationId: uuid(),
          sessionId: '',
          timestamp: '',
        },
      };
      checkAreaQueryParam();
      configService(configRequest)
        .then((config) => {
          setConfig({
            ...config.data,
          });
          setSource(appSources(config.data?.ApiEndpoints));
        })
        .catch((error) => {
          if (!networkBrowserErrors.includes(error.ComponentErrorCode)) {
            dispatch(setErrorState({ model: error }));
          }
        });
    }
  }, [setConfig, dispatch, onPageShowCalled, setSource]);

  useEffect(() => {
    if (config) {
      const date = moment().tz(TIME_ZONE); // TODO: get date from server not from client.
      const maintenanceStatus: MaintenanceStatusModel = readMaintenanceStatus(config, date);
      setMaintenanceStatus(maintenanceStatus);
      setInitialized(true);
    }
  }, [config, setMaintenanceStatus, dispatch]);

  const renderRouter = () => {
    if (config)
      return (
        <Router history={history}>
          <div data-quid="app-container" className="appContainer">
            <Route exact path="/" render={(routeProps) => <App />} />
            <Route exact path="/error" component={(props) => <Error error={props.history.location.state} />} />
          </div>
        </Router>
      );
  };

  if (maintenanceStatus && maintenanceStatus.showMaintenancePage) {
    const { helpPhoneNumber, roadsideUrl, headerLogoUrl } = maintenanceStatus;
    return (
      <Router history={history}>
        <div data-quid="maintenance-container" className="appContainer">
          <Route
            exact
            path="/"
            component={() => (
              <Maintenance helpPhoneNumber={helpPhoneNumber} roadsideUrl={roadsideUrl} headerLogoUrl={headerLogoUrl} />
            )}
          />
        </div>
      </Router>
    );
  }

  return (
    <ConfigContext.Provider value={config as ConfigResponseApp}>
      <div>
        {!initialized ? (
          <ACEPrimaryTheme>
            <div className="spinnerContainer">
              <Spinner id="spinner" variant="indeterminate" />
              <ErrorComp appConfig={config as ConfigResponseApp} />
            </div>
          </ACEPrimaryTheme>
        ) : (
          renderRouter()
        )}
      </div>
    </ConfigContext.Provider>
  );
};

export default AppContainer;
