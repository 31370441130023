import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const cardContainerStyles = (
  theme: Theme
): {
  root: any;
  rootEmptyClass: any;
  headLine: any;
  iconContainer: any;
  headLineText: any;
  collapse: any;
  showAll: any;
} => ({
  root: {
    backgroundColor: '#e0e2e9',
    padding: 14,
    display: 'flex',
    flexDirection: 'column',
    '& .MuiExpansionPanelDetails-root': {
      flexDirection: 'column',
      backgroundColor: '#e0e2e9',
    },
    '& .MuiExpansionPanelSummary-root': {
      alignSelf: 'center',
      paddingTop: 30,
    },
    '& .MuiExpansionPanel-root': {
      backgroundColor: '#e0e2e9',
    },
  },
  rootEmptyClass: {
    backgroundColor: '#ffffff',
    padding: 0,
  },
  headLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: 32,
      [theme.breakpoints.down('sm')]: {
        fontSize: 28,
      },
    },
  },
  headLineText: {
    paddingLeft: 5,
  },
  // can be used to add gradient
  collapse: {
    '& .MuiExpansionPanelSummary-root': {
      paddingTop: 0,
    },
    '& > div:nth-child(3)': {
      height: '24px',
      overflow: 'hidden',
      '& div[class*="CardHeader-root"]': {
        opacity: 0.3,
        '@media (max-device-width:767px)': {
          opacity: 0.1,
        },
      },
    },
    '& div[class*="CardsContainer-showAll"]': {
      '& > div': {
        boxShadow: '0px 7px 41px rgba(242, 243, 245, 0.8)',
      },
      boxShadow: '0px -14px 12px rgba(242, 243, 245, 0.8)',
      '& div:first-child[class*="MuiExpansionPanel-root"]': {
        boxShadow: '0px 7px 9px rgba(242, 243, 245, 0.8)',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      },
      '& div:first-child[role*="button"]': {
        backgroundColor: 'rgba(224, 226, 233, 0.2)',
        boxShadow: '0px -5px 10px rgba(224, 226, 233, 0.2)',
        '& div[class*="MuiExpansionPanelSummary-content"]': {
          height: 0,
        },
      },
    },
  },
  showAll: {},
});
