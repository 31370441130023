export enum SourceParam {
  apiVersion = 'apiVersion',
  customerData = 'customerData',
  productsData = 'productsData',
  config = 'config',
}
const SourceParams = {
  [SourceParam.apiVersion]: '',
  [SourceParam.customerData]: {},
  [SourceParam.productsData]: {},
  [SourceParam.config]: {},
};

export type GetSourceType = (s: string) => any;
export type SetSourceType = (k: string, s: any) => void;

export const SetSourceParam = (prop: string, val: any) => {
  const keys = Object.keys(SourceParams);
  if (keys.includes(prop)) (SourceParams as any)[prop] = val;
};

export const GetSourceParam = (prop: string): any => {
  const keys = Object.keys(SourceParams);
  if (keys.includes(prop)) return (SourceParams as any)[prop];
  return '';
};
