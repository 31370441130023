import { appSources } from './appSources';
import { ConfigRequestApp, WebLogLevel, ConfigResponseApp, ClubCodes, AceMeta } from '../shared';
import { WebUtils } from '../utils';

export const configService = async (request: { aceMeta: AceMeta }): Promise<ConfigResponseApp> => {
  const targetClubCode: ClubCodes = (request.aceMeta.clubCode as ClubCodes) ?? WebUtils.getClubCode() ?? ClubCodes.CA;
  const { sessionId, aceMeta } = WebUtils.getMetadata();
  const serviceRequest: ConfigRequestApp = { club: targetClubCode, sessionId, aceMeta: request.aceMeta };
  try {
    const configResponseV3 = await appSources().configV3(serviceRequest);
    return configResponseV3;

    // "OLD" config
    // const configResponse = await appSources().config(request);
    // return configResponse;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    WebUtils.logWeb({
      message: `Failed to fetch configurations for club ${targetClubCode}`,
      level: WebLogLevel.error,
      data: WebUtils.constructLogData({ error: { ...error, SessionId: sessionId } }),
      aceMeta,
    });
    throw error;
  }
};
