import * as React from 'react';
import { connect } from 'react-redux';
import Redux, { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core';
import { ClubCodes, ConfigResponseApp } from 'shared';
import { setUserState } from '../../redux/store/Actions/user.actions';
import { setAnalyticsState } from '../../redux/store/Actions/analytics.actions';
import { setErrorState } from '../../redux/store/Actions/error.actions';
import { RootState } from '../../redux/store/rootReducer';
import Cookies from 'universal-cookie';
import errorCompStyles from './ErrorCompStyles';
import { Body, Modal, ACEPrimaryTheme, ACEIcon, IconType, Subtitle, Button } from '@clublabs/ace-component-library';
import { tagErrorModal, tagErrorModalLink, tagLoginUrlSessionExpired } from '../Common/TaggingHelper';
import { ServiceError, WebUtils } from 'utils';

interface RequiredProps {
  appConfig: ConfigResponseApp;
}

interface OptionalProps {
  classes?: any;
}

const mapStateToProps = (rootState: RootState) => {
  return {
    userState: rootState.userState,
    analytics: rootState.analyticsState,
    errors: rootState.errorState,
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return bindActionCreators(
    {
      setUserState,
      setAnalyticsState,
      setErrorState,
    },
    dispatch
  );
};

export type ErrorCompProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps &
  Partial<OptionalProps>;

export class ErrorComp extends React.Component<ErrorCompProps> {
  public cookies: Cookies;

  constructor(props: ErrorCompProps) {
    super(props);
    this.cookies = new Cookies();
  }

  private hasErrorToShow = () => {
    return Boolean(this.props.errors && this.props.errors.model);
  };

  private isErrorDueToNoAuthorization = () => {
    const errorCode = this.props.errors && this.props.errors.model.ComponentErrorCode;
    return Boolean(errorCode === '401' || errorCode === '403');
  };

  public closeModal = () => {
    if (this.props.setErrorState) {
      this.props.setErrorState({ model: null as unknown as ServiceError });
    }
  };

  public render() {
    if (this.hasErrorToShow()) {
      const model = this.props.errors && this.props.errors.model;
      switch (model && model.ComponentErrorCode) {
        case '401':
        case '403': {
          // to turn OFF Enterprise Login Integration, add the following in application.json
          // "FeatureFlags": [ "AuthenticationTokenRenewal" ],
          const loginUrl: string = tagLoginUrlSessionExpired(WebUtils.getLoginUrl(this.props.appConfig));
          this.cookies.remove('aceut');
          WebUtils.externalLinkHandler(loginUrl, false, false, this.props?.userState?.customerId, this.props?.userState?.membershipNumber).catch(() => {});
          break;
        }
        // case "500": {
        //   result = 'Error Code: 500 - Fatal autorization error';
        //   break;
        // }
        default: {
          // result = `Error Code: ${model && model.ComponentErrorCode} - ${model && model.ErrorMessage}`;
          break;
        }
      }
    }

    let sessionId;
    if (this.hasErrorToShow() && this.props.errors) {
      try {
        // When ServiceError is returned api.ts, the entire object is serialized to model.ErrorMessage...
        sessionId = JSON.parse(this.props.errors.model.ErrorMessage).sessionId;
      } catch (e) {
        // ...but if sessionId is not available via Errormessage, get it directly from the object
        sessionId = this.props.errors.model.SessionId;
      }
      //tag all refid's

      if (sessionId) {
        if (this.props.userState?.pageLoadSuccessful) {
          this.props.analytics && tagErrorModal(this.props.analytics?.model, sessionId);
        } else {
          this.props.userState &&
            tagErrorModal(
              {
                customerid: this.props.userState.customerId,
                clubcode: this.props.userState.clubCode,
                sessionId: this.props.userState.sessionId,
                state: '', //this won't be available until a successful customer details api call is made
                channel: '',
                isauthenticated: true,
                page: '',
                error: '',
                refid: '',
                bills_due: [],
                cta: '',
                flow: '',
                mem_myaccountalert: '',
                mem_autopay: false,
                mem_billplan: '',
                mem_level: '',
                mem_role: '',
              },
              sessionId
            );
        }
      } else {
        tagErrorModalLink(
          {
            sessionId:
              this.props.userState && this.props.userState.sessionId ? this.props.userState.sessionId : 'no-session-id',
            customerid:
              this.props.userState && this.props.userState.customerId
                ? this.props.userState.customerId
                : 'no-customer-id',
            state: '',
            mem_billplan: '',
            mem_level: '',
            mem_role: '',
            mem_myaccountalert: '',
            mem_autopay: false,
            bills_due: [],
            isauthenticated: true,
            channel: 'my account',
            flow: '',
            clubcode: this.props.userState?.clubCode as ClubCodes,
            page: 'my account:manage my account',
            error: 'my account:manage my account-internal general error',
          },
          'generic-error'
        );
      }
    }
    return (
      <>
        {this.hasErrorToShow() && !this.isErrorDueToNoAuthorization() && (
          <ACEPrimaryTheme>
            <Modal
              id="error-modal"
              title={
                <p id="ModalTitle-error-modal" className={this.props.classes.title}>
                  <ACEIcon type={IconType.WARNING} />
                  Your action couldn’t be completed
                </p>
              }
              className={this.props.classes.root}
              openModal={this.hasErrorToShow()}
              onClose={this.closeModal}
              disableBackdropClick={true}
              content={
                <>
                  <div id="error-body" className={this.props.classes.content}>
                    <div className={this.props.classes.messageContainer}>
                      <Body id="error-message" data-quid="error-message">
                        We’re sorry - we are unable to process your request at this time because of an internal error.
                        You can try again later. If the problem persists, please contact us.
                      </Body>
                    </div>
                  </div>
                  {this.props.errors && sessionId && (
                    <div className={this.props.classes.refIdContainer}>
                      <Subtitle id="error-comp-subtitle-ref-id">Reference ID:</Subtitle>
                      <Body id="error-comp-body-corr-id" data-quid="reference-id">
                        {sessionId}
                      </Body>
                    </div>
                  )}
                  <Button
                    id="contact-us-btn"
                    className={this.props.classes.button}
                    onClick={() => WebUtils.externalLinkHandler(
                        this.props.appConfig.contactUsURL, true, false, 
                        this.props?.userState?.customerId, 
                        this.props?.userState?.membershipNumber,
                      ).catch(() => {})
                    }
                    color="secondary"
                  >
                    Contact us
                  </Button>
                </>
              }
            />
          </ACEPrimaryTheme>
        )}
      </>
    );
  }
}

// for unit test
export const ErrorCompWithStyles = withStyles(errorCompStyles, { withTheme: true })(ErrorComp);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(errorCompStyles, { withTheme: true })(ErrorComp));
