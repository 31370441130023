import React from 'react';
import Redux, { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../../redux/store/rootReducer';
import { setErrorState } from '../../../redux/store/Actions/error.actions';
import { WebUtils, ServiceError, Timer } from 'utils';
import {
  CardCodeEnum,
  AwarenessCardOption,
  UpdateUserPrefRequestApp,
  UpdateUserPrefResponseApp,
  AwarenessCard as AwarenessCardModel,
  ConfigProperties,
  WebLogLevel,
  ApiRefIdSuffix,
  FormPostKeyValue,
  MvcRedirectKey,
  MvcRedirectMode,
  ProductType,
} from 'shared';
import AwarenessCard from '../AwarenessCard';
import { Button } from '@clublabs/ace-component-library';
import { appSources } from 'remotes';
import { ErrorState } from '../../../redux/store/Reducers/error.reducer';
import { setCardContainerState } from '../../../redux/store/Actions/cardcontainer.actions';
import { CardContainerState } from '../../../redux/store/Reducers/cardcontainer.reducer';

interface RequiredProps {
  cardCode: CardCodeEnum;
  id: string;
  title: any;
  body: any;
  dismissable: boolean;
  config: ConfigProperties;
  onClose: (card: { code: CardCodeEnum; prodId: string }) => void;
}
interface OptionalProps {
  productId: string;
  options: AwarenessCardOption[];
}

const mapStateToProps = (rootState: RootState) => {
  return {
    userState: rootState.userState,
    errors: rootState.errorState,
    cardContainerState: rootState.cardContainerState,
  };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return bindActionCreators(
    {
      setErrorState,
      setCardContainerState,
    },
    dispatch
  );
};

export type EnrollAutoPayCardProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps &
  Partial<OptionalProps>;
export interface EnrollAutoPayCardState {
  isHidden: boolean;
}

export class EnrollAutoPayCard extends React.Component<EnrollAutoPayCardProps, EnrollAutoPayCardState> {
  constructor(props: EnrollAutoPayCardProps) {
    super(props);
    this.state = {
      isHidden: false,
    };
  }

  hide = (value) => {
    this.setState({ isHidden: value });
  };

  handleOptionsClick = (option) => {
    // call updateUserPref
    const { sessionId, aceMeta } = WebUtils.getMetadata();

    const request: UpdateUserPrefRequestApp = {
      aceMeta,
      data: {
        productId: this.props.productId,
        prefCode: this.props.cardCode.toString(),
        prefSelection: option.key,
      },
    };
    const updateUserPrefTimer = new Timer('updateUserPrefWeb - enrollAutoPayCard', sessionId);
    const start = updateUserPrefTimer.startTimer();
    appSources()
      .updateUserPreferece(request)
      .then((response: UpdateUserPrefResponseApp) => {
        WebUtils.logMetrics(
          'updateUserPrefWeb - enrollAutoPayCard',
          WebUtils.constructLogData(
            { str: start, end: updateUserPrefTimer.endTimer(), status: 'success' },
            this.props.userState?.customerId,
            this.props.userState?.membershipNumber,
          ),
          aceMeta
        );
        if (response) {
          //remove from allCards redux state
          const allCards =
            this.props.cardContainerState && (this.props.cardContainerState.allCards as AwarenessCardModel[]);
          this.props.setCardContainerState &&
            this.props.setCardContainerState({
              ...this.props.cardContainerState,
              allCards: allCards && allCards.filter((c) => c.code !== this.props.cardCode),
            } as CardContainerState);
          this.hide(true);
          this.props.onClose({ code: this.props.cardCode, prodId: this.props.productId || '' });
        }
      })
      .catch((error: ServiceError) => {
        WebUtils.logMetrics(
          'updateUserPrefWeb - enrollAutoPayCard',
          WebUtils.constructLogData(
            { str: start, end: updateUserPrefTimer.endTimer(), status: 'fail' },
            this.props.userState?.customerId,
            this.props.userState?.membershipNumber,
          ),
          aceMeta,
          WebLogLevel.error
        );
        if (error && this.props.setErrorState) {
          error.SessionId = `${sessionId}-${ApiRefIdSuffix.AwarenessCards}`;
          this.props.setErrorState({ model: error } as ErrorState);
          WebUtils.logWeb({
            message: 'Failed to update user preference for Enroll Auto Pay awareness card',
            level: WebLogLevel.error,
            data: WebUtils.constructLogData({ error }, this.props?.userState?.customerId, this.props?.userState?.membershipNumber),
            aceMeta,
          });
        }
      });
  };

  mapHeaderOptions = (options) => {
    return options.map((option) => {
      return { onClick: this.handleOptionsClick, ...option };
    });
  };

  enrollNowMembershipClick = () => {
    const manageAutoPayURL = this.props.config.MembershipURLs && this.props.config.MembershipURLs.manageAutoPayURL;
    if (manageAutoPayURL) {
      WebUtils.externalLinkHandler(manageAutoPayURL, false, false, this.props?.userState?.customerId, this.props?.userState?.membershipNumber).catch(() => {});
    }
  };

  enrollNowInsuranceClick = () => {
    // api call to fetch insurance redirect payload
    const userState = this.props.userState;
    const product =
      userState &&
      userState.products &&
      userState.products.find((product) => product.productId === this.props.productId);
    const membershipProduct =
      userState && userState.products && userState.products.find((p) => p.type === ProductType.MEMBERSHIP);
    const billingSummary = product && product.billingSummary;

    const extraFields: FormPostKeyValue[] = [
      { key: 'key', value: MvcRedirectKey.ONLINE_BILL_PAY },
      { key: 'fullPolicyNumber', value: this.props.productId },
      { key: 'mode', value: MvcRedirectMode.ENROLLLINK },
      { key: 'customerID', value: userState?.customerId },
      { key: 'memberNumber', value: membershipProduct?.productId },
      { key: 'allowAutoPay', value: 'true' },
      { key: 'allowPayment', value: 'false' },
      { key: 'hasExistingSchedPmt', value: billingSummary?.hasPaymentScheduled },
      {
        key: 'existingSchedPmtAmtString',
        value: billingSummary?.hasPaymentScheduled ? billingSummary?.amount : '0.00',
      },
      {
        key: 'existingSchedPmtDateString',
        value: billingSummary?.hasPaymentScheduled ? billingSummary?.billingDate : '',
      },
      { key: 'existingSchedPmtConfNbr', value: '' },
      { key: 'insMinDue', value: billingSummary?.hasMinimumAmountDue ? 'true' : 'false' },
      { key: 'policyRenewal', value: billingSummary?.isInRenewal ? 'true' : 'false' },
      {
        key: 'insCurrentTerm',
        value:
          billingSummary && billingSummary.billPlan?.length >= 4
            ? billingSummary?.billPlan.substring(3) === '9'
              ? '9'
              : '10'
            : '',
      },
      { key: 'useSystemAgnosticCall', value: true },
      { key: 'cancelSchedPmtExecuted', value: false },
      { key: 'hasLapseAccidents', value: '' },
    ];

    WebUtils.FormPost(
      extraFields, this.props.config.InsuranceURLs.onlineBillPayRedirectURL, 
      this.props.userState?.customerId, this.props.userState?.membershipNumber,
    );
  };
  

  enrollNowClickLegacy = (id: any) => {
    const isMembership = id && id.toLowerCase().includes('membership');
    return isMembership ? this.enrollNowMembershipClick : this.enrollNowInsuranceClick;
  };

  enrollNowClickAWS = () => WebUtils.externalLinkHandler(this.props.config.manageAutoPayURL, false, false, this.props.userState?.customerId, this.props.userState?.membershipNumber).catch(() => {});

  enrollNowClick = (config: any, id: any) => config.autoPayAWSEnabled ? this.enrollNowClickAWS : this.enrollNowClickLegacy(id);

  public render() {
    return (
      !this.state.isHidden && (
        <AwarenessCard
          id={this.props.id}
          key={`AwarenessCard-${this.props.id}`}
          productId={this.props.productId}
          title={this.props.title}
          body={this.props.body}
          dismissable={this.props.dismissable}
          cardHeaderOptions={this.mapHeaderOptions(this.props.options)}
          cardActions={[
            <Button
              id="awareness-card1-cta"
              onClick={this.enrollNowClick(this.props.config, this.props.id)}
              key={`CardAction-Card-${this.props.id}-CardAction-EnrollNow`}
            >
              Enroll Now
            </Button>,
          ]}
          cardCode={this.props.cardCode}
        ></AwarenessCard>
      )
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnrollAutoPayCard);
