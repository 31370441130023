import { createLogger, format, transports } from 'winston';

const { combine, timestamp, label, printf } = format;

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

const logFormat = printf(({ level, message, label, defaultMeta, timestamp }) => {
  return `${timestamp} [${label}: ${JSON.stringify(defaultMeta, getCircularReplacer())}] ${level}: ${JSON.stringify(
    message,
    getCircularReplacer()
  )}`;
});

class Logger {
  private logger;

  constructor() {
    this.logger = createLogger({      
      format: combine(label({ label: 'App Logger' }), timestamp(), logFormat),
      transports: [new transports.Console()],
    });
  }

  private logHelper(level: string, metadata: object, message: string) {
    this.logger.log({
      level: level,
      defaultMeta: metadata,
      message: message,
    });
  }

  public debug(message: string, metadata: object) {
    this.logHelper('debug', metadata, message);
  }

  public warn(message: string, metadata: object) {
    this.logHelper('warn', metadata, message);
  }

  public error(message: string, metadata: object) {
    this.logHelper('error', metadata, message);
  }

  public info(message: string, metadata: object) {
    this.logHelper('info', metadata, message);
  }
}

export const logger = new Logger();
