import jwtDecode from 'jwt-decode';
import { AceutToken } from 'shared';

export class CookieHelper {
  static aceutCookieName = 'aceut';

  static aceuCookieName = 'aceu';

  static zipcodeCookieName = 'zipcode';

  static apiGatewayKeyCookieName = 'apigatewaykey';

  static hasChangedUsernameCookieName = 'haschangedusername';

  static getCookie(name: string) {
    const value = `; ${window.document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
  }

  static deleteCookie(name: string, path = '/') {
    document.cookie = `${name}=s;Max-Age=0;path=${path}`;
  }

  static getClubCodeFromZipcodeCookie(): string | null {
    const zipcodeCookie = CookieHelper.getCookie('zipcode');

    if (zipcodeCookie) {
      const zipcodeCookieArray = zipcodeCookie.split('|');
      let zipcodeResult: any = zipcodeCookieArray[2] ? zipcodeCookieArray[2] : null;

      if (zipcodeResult || zipcodeResult !== null) {
        zipcodeResult = zipcodeResult.trim(zipcodeResult) * 1;
        zipcodeResult = `000${zipcodeResult}`.slice(-3);
      }
      return zipcodeResult;
    }

    return null;
  }

  static getAceutToken(): AceutToken | null {
    const aceutCookie = CookieHelper.getCookie('aceut');

    if (aceutCookie) {
      const parsedAceut = jwtDecode(aceutCookie) as AceutToken;
      return parsedAceut;
    }

    return null;
  }

  static getZipcodeFromZipcodeCookie(): string | null {
    const zipcodeCookie = CookieHelper.getCookie('zipcode');

    if (zipcodeCookie) {
      const zipcodeCookieArray = zipcodeCookie.replace('"', '').split('|');
      const zipcodeResult: string = zipcodeCookieArray[0];
      return zipcodeResult;
    }

    return null;
  }

  static getApiGatewayKeyCookie(): string | undefined {
    return CookieHelper.getCookie('apigatewaykey');
  }
}
