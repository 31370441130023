import { ProductType } from 'shared/contracts/ProductType';
import { CustomerDetails } from 'shared/models/customerDetailsModel';

export const customerDetailsWithClaimsFixture: CustomerDetails = {
  welcomeMessage: 'Nedah, thank you for your 8 years of loyalty',
  customerId: '876465020062904',
  person: {
    gender: 'F',
    dateOfBirth: '1967-07-13',
    residenceAddress: {
      address: {
        streetAddress: '15014 BEECHURST DR',
        city: 'HOUSTON',
        state: 'TX',
        zipCode: '77062',
        zipPlus4: '2333',
        countryCode: '',
      },
    },
  },
  membershipInfo: {
    membershipType: 'Classic',
    clubJoinYear: '2015',
    roleCode: '00',
  },
  preferredEmailAddress: {
    emailAddress: 'kalanski.natalie@aaa-calif.com',
    lastUpdateDate: '2023-06-14',
  },
  numberOfProducts: 3,
  products: [
    {
      type: ProductType.MEMBERSHIP,
      productName: 'Classic membership',
      productId: '6202520752179804',
      productIdDesc: 'Member #',
      productExpiration: 'August 6, 2023',
      productEffectiveDate: '2015-07-07',
      productStatus: 'VALD',
      productRole: 'PR',
      allowMakePolicyChanges: true,
      allowSubmitPayment: true,
      allowSubmitIQ: true,
      allowViewPolicy: true,
      allowViewPOI: true,
      sourceSystem: 'MRD',
      billPlan: 'AM',
      isPolicyCancelled: false,
      isPolicyReinstatable: false,
      hasAutoPay: false,
      isInRenewal: true,
      isInArrears: false,
      hasMinimumAmountDue: true,
      hasPIT: false,
      pitReturnMessage: 'PAYMENT NOT FOUND',
      billingSummary: {
        productId: '252 07521798 00',
        productIdLast3Digits: ' 00',
        productType: 'Membership',
        productTypeDetail: 'CLASSIC',
        status: 'VALD',
        statusDescription: 'Valid',
        billPlan: 'AM',
        billPlanDescription: 'Annual One time Payment',
        hasAutoPay: false,
        currentBillHasAutopay: false,
        totalBalance: '94.00',
        minimumDue: '94.00',
        isInRenewal: true,
        isInArrears: false,
        amount: '94.00',
        billingDate: '2023-08-06',
        last4Digits: '',
        hasBalanceDue: true,
        hasPaymentScheduled: false,
        hasPastDue: false,
        hasPaymentInTransit: false,
        hasMinimumAmountDue: true,
        isMonthly: false,
        accountType: '',
        isCreditCardExpiring: false,
        isCreditCardInvalid: false,
        isNoCardOnFile: false,
        paymentsInTransit: {
          pitPayments: [],
          pitReturnMessage: 'PAYMENT NOT FOUND',
          pitTotalPaymentAmount: '0.00',
        },
        isPolicyCancelled: false,
        isPolicyReinstatable: false,
        lastMoneyDate: '',
        reinstatementMinimumDue: '',
        policyExpiryDate: '',
        isPendingCancellation: true,
        isPendingNonRenewal: true,
        lastPaymentAccountInfo: {
          accountType: 'C',
          accountLastFourDigits: '1773',
          accountHolderName: 'TEST CARD',
          accountExpirationDate: '2025-10-01',
          cardType: 'MCN',
          cardDebitOrCreditIndicator: 'D',
          billableFlag: '',
          eligibleFlag: '',
          authorizationPending: '',
          panStatusIndicator: '',
          IsCreditCardExpiring: '',
          isNoCardOnFile: '',
          isCreditCardInvalid: false,
        },
      },
      rank: 1,
    },
    {
      type: ProductType.AUTO,
      productName: 'Auto insurance policy',
      productId: 'TPA037774008',
      productIdDesc: 'Policy #',
      productExpiration: 'August 6, 2023',
      productEffectiveDate: '',
      productStatus: 'NORM',
      productRole: 'PH',
      allowMakePolicyChanges: true,
      allowSubmitPayment: true,
      allowSubmitIQ: true,
      allowViewPolicy: true,
      allowViewPOI: true,
      sourceSystem: 'HUON',
      billPlan: 'TXAF',
      isPolicyCancelled: false,
      isPolicyReinstatable: true,
      hasAutoPay: true,
      isInRenewal: false,
      isInArrears: false,
      hasMinimumAmountDue: false,
      hasPIT: false,
      hasClaims: false,
      pitReturnMessage: 'PAYMENT NOT FOUND',
      billingSummary: {
        productId: 'TPA037774008',
        productIdLast3Digits: '008',
        productType: 'Auto',
        productTypeDetail: 'PPA',
        status: 'NORM',
        statusDescription: 'NORM',
        billPlan: 'TXAF',
        billPlanDescription: 'Paid in Full Plan',
        hasAutoPay: true,
        currentBillHasAutopay: false,
        totalBalance: '0.00',
        minimumDue: '0.00',
        isInRenewal: false,
        isInArrears: false,
        amount: '0.00',
        billingDate: '',
        last4Digits: '',
        hasBalanceDue: false,
        hasPaymentScheduled: false,
        hasPastDue: false,
        hasPaymentInTransit: false,
        hasMinimumAmountDue: false,
        isMonthly: false,
        accountType: '',
        isCreditCardExpiring: false,
        isCreditCardInvalid: false,
        isNoCardOnFile: false,
        paymentsInTransit: {
          pitPayments: [],
          pitReturnMessage: 'PAYMENT NOT FOUND',
          pitTotalPaymentAmount: '0.00',
        },
        isPolicyCancelled: false,
        isPolicyReinstatable: true,
        lastMoneyDate: '',
        reinstatementMinimumDue: '',
        policyExpiryDate: '2023-08-06',
        isPendingCancellation: false,
        cnpDate: '',
        isPendingNonRenewal: false,
        trnpDate: '',
        lastPaymentAccountInfo: {
          accountType: 'D',
          accountLastFourDigits: '1773',
          accountHolderName: 'TEST CARD',
          accountExpirationDate: '2025-10-01',
          cardType: 'MCN',
          cardDebitOrCreditIndicator: 'D',
          billableFlag: 'Y',
          eligibleFlag: 'E',
          authorizationPending: 'N',
          panStatusIndicator: 'A',
          IsCreditCardExpiring: '',
          isNoCardOnFile: false,
          isCreditCardInvalid: false,
        },
      },
      rank: 3,
    },
    {
      type: ProductType.HOME,
      productName: 'Home insurance policy',
      productId: 'THO037752210',
      productIdDesc: 'Policy #',
      productExpiration: 'August 1, 2023',
      productEffectiveDate: '',
      productStatus: 'NORM',
      productRole: 'PH',
      allowMakePolicyChanges: true,
      allowSubmitPayment: true,
      allowSubmitIQ: true,
      allowViewPolicy: true,
      allowViewPOI: true,
      sourceSystem: 'HUON',
      billPlan: 'TXHI',
      isPolicyCancelled: false,
      isPolicyReinstatable: false,
      hasAutoPay: false,
      isInRenewal: true,
      isInArrears: false,
      hasMinimumAmountDue: true,
      hasPIT: false,
      pitReturnMessage: 'PAYMENT NOT FOUND',
      hasClaims: true,
      billingSummary: {
        productId: 'THO037752210',
        productIdLast3Digits: '210',
        productType: 'Home',
        productTypeDetail: 'HOB',
        status: 'NORM',
        statusDescription: 'NORM',
        billPlan: 'TXHI',
        billPlanDescription: 'Three(3) Payment Plan',
        hasAutoPay: false,
        currentBillHasAutopay: false,
        totalBalance: '6228.20',
        minimumDue: '2074.00',
        isInRenewal: true,
        isInArrears: false,
        amount: '2074.00',
        billingDate: '2023-08-13',
        last4Digits: '',
        hasBalanceDue: true,
        hasPaymentScheduled: false,
        hasPastDue: false,
        hasPaymentInTransit: false,
        hasMinimumAmountDue: true,
        isMonthly: false,
        accountType: '',
        isCreditCardExpiring: false,
        isCreditCardInvalid: false,
        isNoCardOnFile: true,
        paymentsInTransit: {
          pitPayments: [],
          pitReturnMessage: 'PAYMENT NOT FOUND',
          pitTotalPaymentAmount: '0.00',
        },
        isPolicyCancelled: false,
        isPolicyReinstatable: false,
        lastMoneyDate: '',
        reinstatementMinimumDue: '',
        policyExpiryDate: '2023-08-01',
        isPendingCancellation: false,
        cnpDate: '',
        isPendingNonRenewal: false,
        trnpDate: '',
        lastPaymentAccountInfo: {
          accountType: 'C',
          accountLastFourDigits: '7777',
          accountHolderName: 'TEST',
          accountExpirationDate: '2023-12-01',
          cardType: 'VSN',
          cardDebitOrCreditIndicator: 'D',
          billableFlag: '',
          eligibleFlag: '',
          authorizationPending: '',
          panStatusIndicator: '',
          IsCreditCardExpiring: false,
          isNoCardOnFile: true,
          isCreditCardInvalid: false,
        },
      },
      rank: 4,
    },
  ],
  user: {
    customerId: '876465020062904',
    customerName: 'NEDAH',
    clubCode: '252',
    permissions: [0],
    sessionId: '',
  },
  emptyAuthorizations: false,
};
