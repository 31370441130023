import { Login, AuthenticateData } from '../contracts/Login';
import { ClubCodes } from '../common/enums';
import { v4 as uuid } from 'uuid';

export const loginFixture: Login = {
  customerId: '116310020099992',
  clubCode: ClubCodes.TX,
};

const aceutToken =
  'eyJraWQiOiJIcnlEM1plSXE5TjRJZGhUNWRuQjNrSHdsRE4yR2pjXzNsZFRxMF90cDZrIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULlFzMXl1Y3ZNNXpJcEJLa2VwdEFLRURPOERvVWUwdUNtdm5TZzl1ZFZFSDgiLCJpc3MiOiJodHRwczovL2FjZS1pZGVudGl0eS5va3RhcHJldmlldy5jb20vb2F1dGgyL2F1c21vZnF1bzYyY0h0WU5rMGg3IiwiYXVkIjoiMG9hbW45NWNyNmZKODBZdFYwaDciLCJpYXQiOjE2NzM5MjM4NDcsImV4cCI6MTY3MzkyNzQ0NywiY2lkIjoiMG9hMTZ6YXcwNGx4VTNjcFowaDgiLCJ1aWQiOiIwMHUxNDdvd2pmbG1kMmJESzBoOCIsInNjcCI6WyJwcm9maWxlIiwiY2x1YkNvZGUiLCJzY29wZSIsIm9wZW5pZCIsImN1c3RvbWVySWQiXSwiYXV0aF90aW1lIjoxNjczODk4NjQ3LCJzdWIiOiJDQUNfNTExNzIyNzFfTWljQGFhYS1jYWxpZi5jb20iLCJzZWxmUmVjb3ZlcnkiOmZhbHNlLCJjbHViQ29kZSI6IjAwNCIsInNjb3BlIjpbImd1aWRld2lyZS5lZGdlLnBvbGljeS5DQUEwNDAwODcwNTguQUxMIiwiZ3VpZGV3aXJlLmVkZ2UucG9saWN5LkNBQzA3OTY0MjAwNC5BTEwiLCJndWlkZXdpcmUuZWRnZS5wb2xpY3kuQ0hPMDc5NjE2OTc0LkFMTCIsImd1aWRld2lyZS5lZGdlLnBvbGljeS5DRUEwNzk2MTY5OTUuQUxMIiwiZ3VpZGV3aXJlLmVkZ2UucG9saWN5LkNBVzA3NjY1Mjc2NS5BTEwiXSwiY3VzdG9tZXJJZCI6IjY1ODExNTQxMDUyMDMzMyIsInByZWZlcnJlZF91c2VybmFtZSI6IkNBQ181MTE3MjI3MV9NaWNAYWFhLWNhbGlmLmNvbSJ9.cAekKc_qVoEQAhA_wM9SLXw3p7a-uH8onulkF1vNBHJFv5BpKvlmJ2muUA1dvlxmgPvnIiDjBE7eHELPECZMmZ-byZWQj24XO-wyKLZ6Q77Q7wLJKzWofv_YhLwvgqmqqLEXwseNhbco0kGdR1kXSj1OwksW2quKkqZqVbkwjPsVFKTZ88kN0L-tw7emnWD74Knta6uXoOy-PXizpfSt-PLPO1bhNUX53S0O6nZNXrvqayoyWF44GBbOf-6UpODSOFVbbUvJ-mPnTnjDRX-Ci94SmLnCMxc9jJNr7NJVujmrQGWHQeVRfiuskcj-IobaFN6uqeq7qaJ2PVypRRNQRw';
const cid = '0oa129yke1d9rRLGR357';

const url = new URL(`${window.location.href}`);
url.searchParams.append("token", aceutToken);
url.searchParams.append("cid", cid);

export const authenticateFixture: AuthenticateData = {
  locationSearch: url.search,
  sessionId: uuid(),
  cToken: 'some-c-token',
};
