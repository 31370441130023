import { logger } from './logger';
import { ServiceError } from './error';

export const handleError = (
  name: string,
  error: Error,
  input: Object,
  sessionId: string,
  suffix?: any,
  dothrow = false
): ServiceError => {
  const serviceError: ServiceError = new ServiceError('High', 'ErrorCode', '', 'Name', [], 'SomeRefId', 'CustomerId');

  if (error) {
    if (suffix && sessionId) {
      sessionId += '-' + suffix;
    }
    serviceError.SessionId = sessionId;
    serviceError.CustomerId =
      /* httpContext.get('customerId') != null 
      ? httpContext.get('customerId') http context not available on client : */ 'no-customer-id';
    serviceError.ComponentName = name;
    serviceError.ComponentStack.push(name);
    serviceError.ComponentErrorCode = error.name;
    serviceError.ErrorMessage = error.message;

    logger.error('handleError', serviceError);
  }

  if (dothrow) throw new Error('');

  return serviceError;
};
