import React from 'react';
import Redux from 'redux';
import { connect } from 'react-redux';
import { Card, CardContent, CardActions, CardHeader, withStyles } from '@material-ui/core';
import { Button } from '@clublabs/ace-component-library';
import { Notification, ConfigResponseApp } from 'shared';
import notificationCardStyles, { StyleProps } from './NotificationCardStyles';
import { iconHelper } from '../Common/IconHelper';
import { NotificationHandler } from '../Common/NotificationHandlers';
import { RootState } from '../../redux/store/rootReducer';

export interface RequiredProps {
  cardData: Notification;
  config: ConfigResponseApp;
}

type OptionalProps = StyleProps;

const mapStateToProps = (rootState: RootState) => {
  return {
    rootState: rootState,
  };
};
const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
  return {
    payClick: (args) => NotificationHandler.handlePayClick(dispatch, args),
  };
};

type IQCardProps = Partial<ReturnType<typeof mapStateToProps>> &
  Partial<ReturnType<typeof mapDispatchToProps>> &
  RequiredProps &
  OptionalProps;

const IQCard: React.FunctionComponent<IQCardProps> = (props) => {
  return (
    <Card
      data-quid="notification-card-basic"
      className={props.classes.notificationCard}
      key={`${props.cardData.productId}-IQ`}
    >
      <CardHeader
        className={props.classes.cardHeader}
        title={props.cardData.title}
        subheader={<span data-di-mask>{`${props.cardData.productIdLabel} #: ${props.cardData.productId}`}</span>}
      ></CardHeader>
      <CardContent className={props.classes.cardContent}>
        {props.cardData.message && (
          <div id="notification-alert" className={props.classes.notificationAlert}>
            <div id="notification-alert-icon" className={props.classes.notificationAlertIcon}>
              {iconHelper(props.cardData.iconFlag)}
            </div>
            <div className={props.classes.notificationAlertMessage}>
              <div data-quid="notification-alert-message">{props.cardData.message}</div>
            </div>
          </div>
        )}
      </CardContent>
      <CardActions className={props.classes.verticalActions}>
        <Button
          id="notification-cta"
          className={props.classes.cardAction}
          onClick={() =>
            props.payClick &&
            props.payClick({ notification: props.cardData, config: props.config, rootState: props.rootState })
          }
        >
          Complete questionnaire
        </Button>
      </CardActions>
    </Card>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(notificationCardStyles, { withTheme: true })(IQCard));
