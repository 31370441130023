import { TravelBookingResponseApp } from "shared/models/travelBookingModel";
import { aceMetaFixture } from "./aceMetaFixture";

export const travelBookingResponseFixture: TravelBookingResponseApp = {
    aceMeta: aceMetaFixture,
    data: {
        travelReservations: [
            {
                start: "2022-12-01",
                end: "2022-12-10",
                product: "Car",
            }
        ]
    }
}