import { useRef } from 'react';
import { Blade, BladeContent, BladeIconType } from '@clublabs/ace-component-library';
import { withStyles } from '@material-ui/core';
import { WebUtils } from 'utils';
import { ConfigResponseApp, ClubCodes } from 'shared';

export interface ProfileTabProps {
  manageAutoPayURL: string;
  manageContactInfoURL: string;
  managePasswordURL: string;
  managePaymentAccountsURL: string;
  manageCommPrefsURL: string;
  logoutURL: string;
  homeURL: string;
  manageUsernameConfig: Array<string>;
  ManageAddressChange: Array<string>;
  manageAddressURL: string;
  config: ConfigResponseApp;
  classes: any;
  clubCode: ClubCodes;
}

export interface OptionalProps {
  classes?: ReturnType<typeof profileTabStyles>;
}

const profileTabStyles = (): {
  root: any;
  logout: any;
} => ({
  root: {},
  logout: {
    '& .Subheadline': {
      color: '#4470bf',
    },
  },
});

const ProfileTabContent = (props: ProfileTabProps) => {
  const links = {
    logout: props.config.logoutURL,
    home: props.config.HomeURL,
  };
  const { manageUsernameConfig, ManageAddressChange } = props;
  const isManageUsernameEnabled =
    manageUsernameConfig?.includes(props.clubCode) || manageUsernameConfig?.includes('ALL');
  const isManageAddressChangeEnabled =
    ManageAddressChange?.includes(props.clubCode) || ManageAddressChange?.includes('ALL');

  const allowClickFlag = useRef(true);

  const onClick = (url: string) => (_event) => {
    if (allowClickFlag.current) {
      allowClickFlag.current = false;
      WebUtils.externalLinkHandler(url).catch(() => allowClickFlag.current = true);
    }
  }

  return (
    <div data-quid="profile-tab" className={props.classes && props.classes.root}>
      <Blade
        key="manage-contact-info"
        id="manage-contact-info"
        onClick={onClick(props.manageContactInfoURL)}
      >
        <BladeContent
          id="manage-contact-info-content"
          mainTitle="Manage contact information"
          icon={BladeIconType.RIGHT_ARROW}
          keyboardAccessible={true}
          titleAccess={`Navigate to manage contact information`}
          onClick={onClick(props.manageContactInfoURL)}
        />
      </Blade>
      {/* URL was not provided EN-2706, once provided set this handler {WebUtils.externalLinkHandler(this.props.manageAddressURL)} and update ManageAddressChangeURL config*/}
      {isManageAddressChangeEnabled && (
        <Blade
          key="manage-address"
          id="manage-address"
          onClick={onClick(props.manageAddressURL)}
        >
          <BladeContent 
            id="manage-address-content" 
            mainTitle="Manage address" 
            icon={BladeIconType.RIGHT_ARROW} 
            keyboardAccessible={true}
            titleAccess={`Navigate to manage address`}
            onClick={onClick(props.manageAddressURL)}
          />
        </Blade>
      )}
      <Blade
        key="manage-password"
        id="manage-password"
        onClick={onClick(props.managePasswordURL)}
      >
        <BladeContent
          id="manage-password-content"
          mainTitle={isManageUsernameEnabled ? 'Manage username or password' : 'Manage password'}
          icon={BladeIconType.RIGHT_ARROW}
          keyboardAccessible={true}
          titleAccess={`Navigate to ${isManageUsernameEnabled ? 'Manage username or password' : 'Manage password'}`}
          onClick={onClick(props.managePasswordURL)}
        />
      </Blade>
      <Blade
        key="manage-payment-accounts"
        id="manage-payment-accounts"
        onClick={onClick(props.managePaymentAccountsURL)}
      >
        <BladeContent
          id="manage-payment-accounts-content"
          mainTitle="Manage saved payment accounts"
          icon={BladeIconType.RIGHT_ARROW}
          keyboardAccessible={true}
          titleAccess={"Navigate to manage saved payment accounts"}
          onClick={onClick(props.managePaymentAccountsURL)}
        />
      </Blade>
      {
        props.config.autoPayAWSEnabled &&
          <Blade
            key="manage-auto-pay"
            id="manage-auto-pay"
            onClick={onClick(props.manageAutoPayURL)}
          >
            <BladeContent
              id="manage-auto-pay-content"
              mainTitle="Manage Auto Pay"
              icon={BladeIconType.RIGHT_ARROW}
              keyboardAccessible={true}
              titleAccess={"Navigate to Manage Auto Pay"}
              onClick={onClick(props.manageAutoPayURL)}
            />
          </Blade>
      }
      <Blade
        key="manage-comm-prefs"
        id="manage-comm-prefs"
        onClick={onClick(props.manageCommPrefsURL)}
      >
        <BladeContent
          id="manage-comm-prefs-content"
          mainTitle="Manage communication preferences"
          icon={BladeIconType.RIGHT_ARROW}
          keyboardAccessible={true}
          titleAccess={"Navigate to manage communication preferences"}
          onClick={onClick(props.manageCommPrefsURL)}
        />
      </Blade>
      <Blade key="log-out" id="log-out" 
        onClick={() => WebUtils.handleLogout(links.logout, links.home)}>
        <BladeContent
          id="log-out-content"
          mainTitle="Log out"
          className={props.classes && props.classes.logout}
          keyboardAccessible={true}
          titleAccess={"Navigate to log out"}
          onClick={() => WebUtils.handleLogout(links.logout, links.home)}
        />
      </Blade>
    </div>

  );
}

export default withStyles(profileTabStyles, { withTheme: true })(ProfileTabContent);
