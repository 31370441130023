import * as actions from '../Actions/tabcontainer.actions';
import { Tab } from '@clublabs/ace-component-library';

export interface TabContainerState {
  activeTab: Tab;
}

export const initialState = {
  activeTab: { id: 0 },
};

export const tabContainerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    default:
      return state;
  }
};
