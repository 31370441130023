export enum ProductType {
  DEFAULT = 'DEFAULT',
  MEMBERSHIP = 'MEMBERSHIP',
  AUTO = 'AUTO',
  HOME = 'HOME',
  CONDO = 'CONDO',
  TENANT = 'TENANT',
  RENTAL_DWELLING = 'RENTAL_DWELLING',
  LIFE = 'LIFE',
  WATERCRAFT = 'WATERCRAFT',
  PUP = 'PERSONAL_UMBRELLA',
  PERSONAL_UMBRELLA = 'PERSONAL_UMBRELLA',
  UMBRELLA = 'UMBRELLA',
  CALIFORNIA_EARTHQUAKE = 'CALIFORNIA_EARTHQUAKE',
  EARTHQUAKE = 'EARTHQUAKE',
  CLASSIC = 'CLASSIC',
  INSURANCE_QUESTIONNAIRE = 'INSURANCE_QUESTIONNAIRE',
  PMID = 'PMID',
}

export enum ProductCategory {
  HOME = 'HOME',
  AUTO = 'AUTO',
  OTHER = ''
}
