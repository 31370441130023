import { CardCodeEnum } from '../common/enums';
import {
  AwarenessCardRequestApp,
  AwarenessCardResponseApp,
  AwarenessCard,
  AwarenessCardOption,
} from '../models/awarenessCardModel';
import { aceMetaFixture } from './aceMetaFixture';

const autoPayAwarenessCardOptions: AwarenessCardOption[] = [
  {
    key: '90_DAY',
    display: 'Remind me in 90 Days',
  },
  {
    key: 'NEVER',
    display: 'Don’t remind me again',
  },
];

export const AutoPayAwarenessCardModelFixture: AwarenessCard = {
  metadata: {},
  code: CardCodeEnum.ENROLL_AUTO_PAY_INSURANCE,
  productId: '12345678',
  title: {
    text: 'Save yourself some time',
  },
  body: {
    text: "Enroll in AAA Auto Pay and you won't have to worry about missing a payment",
  },
  dismissable: true,
  options: autoPayAwarenessCardOptions,
};

// App
export const awarenessCardRequestAppFixture: AwarenessCardRequestApp = {
  aceMeta: aceMetaFixture,  
};

export const awarenessCardResponseAppFixture: AwarenessCardResponseApp = {
  aceMeta: aceMetaFixture,
  data: {
    awarenessCards: [AutoPayAwarenessCardModelFixture],
  }
};
