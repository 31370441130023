import React from 'react';
import { Card, CardContent, CardHeader, withStyles } from '@material-ui/core';
import { Body } from '@clublabs/ace-component-library';
import { Subheadline } from '@clublabs/ace-component-library';
import awarenessCardStyles from './AwarenessCardStyles';
import { BiError } from 'react-icons/bi';

export interface ErrorCardProps {
  billingError?: boolean;
  policyError?: boolean;
  customerError?: boolean;
}

export const ErrorCard: React.FunctionComponent<ErrorCardProps> = ({ billingError, policyError, customerError }) => {
  const createText = () => {
    let header = 'Unable to retrieve billing info';
    let message =
      'We ran into a technical issue retrieving your billing and policy details. Please try again later or contact us for assistance with your billing information.';

    if (billingError && policyError) {
      header = 'Unable to retrieve account info';
      message =
        'We ran into a technical issue retrieving some of your AAA products and/or billing information. Please try again later or contact us for assistance.';
    } else if (policyError) {
      header = 'Unable to retrieve account info';
      message =
        'We ran into a technical issue retrieving information about some of your AAA services and/or products. Please try again later or contact us for assistance.';
    } else if (customerError) {
      header = 'Your action could not be completed';
      message =
        'We are sorry. We are unable to process your request at this time because of an internal error. You can try later. If the problem persists, please contact us.';
    }

    return {
      header,
      message,
    };
  };

  return (
    <div style={{ padding: '14px', backgroundColor: '#e0e2e9' }}>
      <Card data-quid="ErrorCard" className="Card">
        <CardHeader
          data-quid="ErrorCardHeader"
          title={<Subheadline id="ErroSubHeadLine">{createText().header}</Subheadline>}
        ></CardHeader>
        <CardContent data-quid="ErrorCardContent">
          <Body id="ErrorCardBody">
            <div style={{ display: 'flex' }}>
              <div style={{ paddingRight: '10px' }}>
                <BiError size={32} color="#D93C1C" />
              </div>
              <div style={{ textAlign: 'justify' }}>
                <p>{createText().message}</p>
              </div>
            </div>
          </Body>
        </CardContent>
      </Card>
    </div>
  );
};
export default withStyles(awarenessCardStyles)(ErrorCard);
