import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import { rootReducer } from '../src/redux/store/rootReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const storeProvider = ({ children, initialState = {} }) => {
  const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
  return <Provider store={store}>{children}</Provider>;
};
export default storeProvider;
