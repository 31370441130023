import { FaShieldAlt } from 'react-icons/fa';

export interface PolicyNumberHintProps {
  transitionEffectiveDate?: string;
  transitionProductId?: string;
}

export const PolicyNumberHint = ({ transitionEffectiveDate, transitionProductId }: PolicyNumberHintProps) => {
  return (
    <div
      style={{
        padding: '15px',
        margin: '10px 0 25px 0',
        backgroundColor: '#e3f8f0',
        fontSize: '.9em',
        border: 'none',
        textAlign: 'center',
        borderRadius: '10px',
        display: 'flex',
      }}
      data-testid="policy-number-hint"
    >
      <div style={{ paddingRight: '5px' }}>
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: 'white',
            height: '35px',
            width: '35px',
          }}
        >
          <FaShieldAlt color="#1a9f6c" style={{ height: '35px', width: '25px' }} />
        </div>
      </div>
      <div style={{ paddingTop: '3px', textAlign: 'justify' }}>
        <p>
          Effective {transitionEffectiveDate}, your new assigned policy number will be{' '}
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {transitionProductId}
          </span>
        </p>
      </div>
    </div>
  );
};
