import configDev from './dev.json';
import configDevIntegration from './dev-integration.json';
import configUat from './uat.json';
import configQa from './qa.json';
import configStage from './stage.json';
import configProd from './prod.json';

configDev.api.host = configDev.api.host.replace('<stack_name>', process.env.REACT_APP_DEV_ENDPOINT?.trim() || '');

export enum Environments {
  localDev = 'localdev',
  dev = 'dev',
  qa = 'qa',
  uat = 'uat',
  stage = 'stg',
  prod = 'prod',
}
const configs: any = {
  localdev: configDev,
  dev: configDevIntegration,
  uat: configUat,
  qa: configQa,
  stg: configStage,
  prod: configProd,
};
class Loader {
  static getConfig = (env: Environments): any => {
    if (env && configs[env]) {
      return configs[env];
    }
    throw new Error(`unsupported env: ${env}`);
  };
}

export default Loader;

export const getEnvironmentFromHost = (hostname: string): Environments => {
  if (hostname.search('localhost') > -1 || hostname.search('ma.app.ace.aaa.com') > -1) {
    return Environments.localDev;
  } else {
    if (hostname.includes('appdev1') || hostname.includes('app.dev') || hostname.includes('appdev2')) {
      return Environments.dev;
    } else if (hostname.includes('appqa1') || hostname.includes('app.qa') || hostname.includes('appqa2')) {
      return Environments.qa;
    } else if (hostname.includes('appuat1') || hostname.includes('app.uat') || hostname.includes('appuat2')) {
      return Environments.uat;
    } else if (hostname.includes('appstage') || hostname.includes('app.stage') || hostname.includes('app.stg')) {
      return Environments.stage;
    } else if (hostname.includes('app.')) {
      return Environments.prod;
    } else {
      return Environments.dev;
    }
  }
};
