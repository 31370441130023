import * as React from 'react';
import {
  AwarenessCard as AwarenessCardModel,
  CardCodeEnum,
  ClubCodes,
  ConfigResponseApp,
  Notification,
  PITNotificationModel,
} from 'shared';
import EnrollAutoPayCard from './CardTypes/EnrollAutoPayCard';
import PaperlessCard from './CardTypes/PaperlessCard';
import AutoQuoteCard from './CardTypes/AutoQuoteCard';
import BillingCard from '../NotificationCard/BillingCard';
import ACGCard from '../NotificationCard/ACGCard';
import IQCard from '../NotificationCard/IQCard';
import PaymentInTransitCard from '../NotificationCard/PaymentInTransitCard';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface RequiredProps {
  cardData: AwarenessCardModel | Notification;
  config: ConfigResponseApp;
  onClose: (card: { code: CardCodeEnum; prodId: string }) => void;
  state: string;
  clubCode: ClubCodes;
}

interface OptionalProps {
  classes?: any;
  membershipExpDate?: string;
  membershipNumber?: string;
}

type CardFactoryProps = RequiredProps & Partial<OptionalProps>;

export class CardFactory extends React.Component<CardFactoryProps> {
  private constructor(props) {
    super(props);
  }

  public render() {
    switch (this.props.cardData.code) {
      case CardCodeEnum.ENROLL_AUTOPAY:
      case CardCodeEnum.ENROLL_AUTO_PAY_INSURANCE:
      case CardCodeEnum.ENROLL_AUTO_PAY_MEMBERSHIP: {
        const cardData = this.props.cardData as AwarenessCardModel;
        return (
          <TransitionGroup timeout={500}>
            <CSSTransition timeout={500} key={`CSSTransition-${cardData.code}-${cardData.productId}`}>
              <EnrollAutoPayCard
                id={`${cardData.code}-${cardData.productId}`}
                title={cardData.title.text}
                body={cardData.body.text}
                dismissable={cardData.dismissable}
                options={cardData.options}
                productId={cardData.productId}
                cardCode={cardData.code}
                onClose={this.props.onClose}
                config={this.props.config}
              />
            </CSSTransition>
          </TransitionGroup>
        );
      }
      case CardCodeEnum.ENROLL_PAPERLESS: {
        const cardData = this.props.cardData as AwarenessCardModel;
        return (
          <TransitionGroup timeout={500}>
            <CSSTransition timeout={500} key={`CSSTransition-${cardData.code}-${cardData.productId}`}>
              <PaperlessCard
                id={`${cardData.code}-${cardData.productId}`}
                title={cardData.title.text}
                body={cardData.body.text}
                buttonText="Enroll Now"
                dismissable={cardData.dismissable}
                options={cardData.options}
                cardCode={cardData.code}
                ctaURL={
                  this.props.config.InsuranceURLs && this.props.config.InsuranceURLs.paperlessBillingQuickOptInURL
                }
                onClose={this.props.onClose}
              />
            </CSSTransition>
          </TransitionGroup>
        );
      }
      case CardCodeEnum.ENROLL_PAPERLESS_MAGAZINE: {
        const cardData = this.props.cardData as AwarenessCardModel;
        return (
          <TransitionGroup timeout={500}>
            <CSSTransition timeout={500} key={`CSSTransition-${cardData.code}-${cardData.productId}`}>
              <PaperlessCard
                id={`${cardData.code}-${cardData.productId}`}
                title={cardData.title.text}
                body={cardData.body.text}
                buttonText="Opt out"
                dismissable={cardData.dismissable}
                options={cardData.options}
                cardCode={cardData.code}
                ctaURL={this.props.config.InsuranceURLs && this.props.config.InsuranceURLs.paperlessMagazineURL}
                onClose={this.props.onClose}
              />
            </CSSTransition>
          </TransitionGroup>
        );
      }
      case CardCodeEnum.ENROLL_PAPERLESS_MEMBERCARD: {
        const cardData = this.props.cardData as AwarenessCardModel;
        return (
          <TransitionGroup timeout={500}>
            <CSSTransition timeout={500} key={`CSSTransition-${cardData.code}-${cardData.productId}`}>
              <PaperlessCard
                id={`${cardData.code}-${cardData.productId}`}
                title={cardData.title.text}
                body={cardData.body.text}
                buttonText="Opt out"
                dismissable={cardData.dismissable}
                options={cardData.options}
                cardCode={cardData.code}
                ctaURL={this.props.config.InsuranceURLs && this.props.config.InsuranceURLs.paperlessMemberCardOptInURL}
                onClose={this.props.onClose}
              />
            </CSSTransition>
          </TransitionGroup>
        );
      }
      case CardCodeEnum.CREDIT_CARD: {
        const cardData = this.props.cardData as AwarenessCardModel;
        const buildTitle = () => {
          const splitTitle = cardData.title.text.split('®');

          if (splitTitle.length > 1) {
            const trademark = <sup style={{ verticalAlign: 'super', fontSize: 'smaller' }}>®</sup>;

            return (
              <>
                {splitTitle[0]}
                {trademark}
                {splitTitle[1]}
              </>
            );
          } else {
            return splitTitle;
          }
        };

        return (
          <TransitionGroup timeout={500}>
            <CSSTransition timeout={500} key={`CSSTransition-${cardData.code}-${cardData.productId}`}>
              <PaperlessCard
                id={`${cardData.code}-${cardData.productId}`}
                title={buildTitle()}
                body={cardData.body.text}
                buttonText="Learn more"
                dismissable={cardData.dismissable}
                options={cardData.options}
                cardCode={cardData.code}
                ctaURL={this.props.config.PartnerURLs && this.props.config.PartnerURLs.creditCardURL}
                onClose={this.props.onClose}
              />
            </CSSTransition>
          </TransitionGroup>
        );
      }
      case CardCodeEnum.PMID: {
        const cardData = this.props.cardData as AwarenessCardModel;
        
        return (
          <TransitionGroup timeout={500}>
            <CSSTransition timeout={500} key={`CSSTransition-${cardData.code}-${cardData.productId}`}>
              <PaperlessCard
                id={`${cardData.code}-${cardData.productId}`}
                title={cardData.title.text}
                body={cardData.body.text}
                buttonText="Activate Now"
                dismissable={cardData.dismissable}
                options={cardData.options}
                cardCode={cardData.code}
                ctaURL={this.props.config.PartnerURLs && this.props.config.PartnerURLs.pmidURL}
                onClose={this.props.onClose}
              />
            </CSSTransition>
          </TransitionGroup>
        );
      }
      case CardCodeEnum.GET_AUTO_QUOTE: {
        const cardData = this.props.cardData as AwarenessCardModel;
        return (
          <TransitionGroup timeout={500}>
            <CSSTransition timeout={500} key={`CSSTransition-${cardData.code}-${cardData.productId}`}>
              <AutoQuoteCard
                id={`${cardData.code}-${cardData.productId}`}
                title={cardData.title.text}
                body={cardData.body.text}
                buttonText="Get a quote"
                dismissable={cardData.dismissable}
                options={cardData.options}
                cardCode={cardData.code}
                ctaURL={this.props.config.InsuranceURLs && this.props.config.getAutoQuoteURL}
                onClose={this.props.onClose}
                state={this.props.state}
                config={this.props.config}
              />
            </CSSTransition>
          </TransitionGroup>
        );
      }
      case CardCodeEnum.IQ: {
        const cardData = this.props.cardData as Notification;
        return <IQCard cardData={cardData} config={this.props.config} />;
      }
      case CardCodeEnum.ACG: {
        const acgConfig = this.props.config && this.props.config.insuranceNotificationDetails;
        return acgConfig ? <ACGCard acgConfig={acgConfig} /> : <React.Fragment />;
      }
      case CardCodeEnum.BILLING: {
        const cardData = this.props.cardData as Notification;
        return <BillingCard cardData={cardData} config={this.props.config} />;
      }
      case CardCodeEnum.PAYMENT_IN_TRANSIT: {
        const cardData = this.props.cardData as PITNotificationModel;
        return <PaymentInTransitCard cardData={cardData} config={this.props.config} />;
      }
      default:
        return null;
    }
  }
}

export default CardFactory;
