import React from 'react';
import { Card, CardContent, CardActions, CardHeader, withStyles } from '@material-ui/core';
import { Button, Body } from '@clublabs/ace-component-library';
import notificationCardStyles, { StyleProps } from './NotificationCardStyles';
import { WebUtils } from 'utils';

export interface RequiredProps {
  acgConfig: any;
}

type OptionalProps = StyleProps;

const ACGCard: React.FunctionComponent<RequiredProps & OptionalProps> = (props) => {
  return (
    <Card data-quid="acg-notification" className={props.classes.notificationCard}>
      <CardHeader className={props.classes.cardHeader} title="Why don't I see my policy?"></CardHeader>
      <CardContent className={props.classes.cardContent}>
        <Body id="acg-content">{props.acgConfig && props.acgConfig.message}</Body>
      </CardContent>
      <CardActions className={props.classes.cardActions}>
        <Button
          id="acg-pay-bill"
          className={props.classes.cardAction}
          onClick={() => WebUtils.externalLinkHandler(props.acgConfig && props.acgConfig.paybillURL, true).catch(() => {})}
        >
          Pay bill
        </Button>
        <Button
          color="secondary"
          id="call-insurance-service"
          className={props.classes.cardAction}
          onClick={() => {
            window.location.href = `tel:${props.acgConfig && props.acgConfig.insuranceSvcPhone}`;
          }}
        >
          Call insurance services
        </Button>
      </CardActions>
    </Card>
  );
};

export default withStyles(notificationCardStyles, { withTheme: true })(ACGCard);
