import { ProductsContractApp } from 'shared/sources/ProductsContractApp';
import { RemoteGetFull } from '../fetchers';
import { productV3 } from 'shared/fixtures/productV3Fixture';

// TODO: update request and response types
export const ProductsRemoteApp = (endpoint: string): ProductsContractApp => {
  return async (request) => {
    const serviceResp = await RemoteGetFull<any, any>(endpoint)(request);
    const response = {
      aceMeta: {
        region: serviceResp.headers?.['x-ace-aws-region'],
        testRegion: serviceResp.headers?.['x-ace-test-region'],
        correlationId: serviceResp.headers?.['x-ace-correlation-id'],
        sessionId: serviceResp.headers?.['x-ace-session-id'],
        requestId: serviceResp.headers?.['x-ace-request-id'],
      }, 
      ...serviceResp.data,
    };
    return response;
  }
}

export const ProductsStubApp =
  (_endpoint: string): ProductsContractApp =>
  async () => (productV3);
