import { logger } from './logger';

export interface IMetrics {
  source: string;
  description?: string;
  start?: string;
  end?: string;
  responseTime?: number;
  sessionId?: string;
  customerId?: string;
  policyNumber?: string;
}
export const handleMetrics = (metrics: IMetrics) => {
  logger.info('METRICS', metrics);
};
