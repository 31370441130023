import { IconFlags, ClubCodes, MvcRedirectKey, MvcRedirectMode } from '../common/enums';
import { AceMeta } from '../contracts/AceMeta';
import { HttpResponseHandler } from '../contracts/HttpResponseHandler';
import { Login } from '../contracts/Login';

export interface MembershipOptionsRequestApp {
  aceMeta: AceMeta;
  login?: Login;
}

export interface MembershipOptionsResponseApp {
  aceMeta: AceMeta;
  httpResponseHandler: HttpResponseHandler;
  membershipOptions: MembershipOption[];
}

export interface MembershipOptionsRequestService {
  customerId: string;
  clubCode: ClubCodes;
}

export interface MembershipOptionsResponseService {
  membershipOptions: MembershipOption[];
}

export interface MembershipOptionsRequestEnterprise {
  customerId: string;
}

export interface MembershipOptionsResponseEnterprise {
  membershipOptions: MembershipOption[];
}

export const MembershipOptionsDisplays = {
  PAY_MY_BILL: 'Pay my bill',
  MANAGE_MEMBERSHIP: 'Manage my membership',
  GET_MEMBERSHIP_CARD: 'Get membership card',
  MANAGE_AUTOMATIC_PAYMENTS: 'Manage Auto Pay',
  ENROLL_AUTOMATIC_PAYMENTS: 'Enroll in Auto Pay',
  CHANGE_MY_CONTACT_INFORMATION: 'Change my contact information',
  CHANGE_MY_MEMBERSHIP_TYPE: 'Change my membership type',
  COMPARE_MEMBERSHIP_TYPES: 'Compare membership types',
  UPDATE_FAMILY_MEMBERS: 'Update family members',
  ADD_AN_RV_OR_MOTORCYCLE: 'Add or Remove RV/Motorcycle',
  VIEW_MEMBER_GUIDE: 'View member guide',
  DONT_SEE: "Don't see what you're looking for?",
};

export enum Operation {
  REDIRECT_URL,
  SHOW_MESSAGE,
}

export interface MembershipOption {
  id: string;
  value: string;
  display: string;
  newWindow: boolean;
  visible?: boolean;
  flags?: IconFlags[];
  subDisplay?: any[];
  subDisplayIndented?: boolean;
  externalFields?: MembershipExternalFields;
}

export interface MembershipExternalFields {
  key: MvcRedirectKey;
  policyNumber?: string;
  mode?: MvcRedirectMode;
  clubCode?: ClubCodes;
}
