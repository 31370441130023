import { AnalyticsModel, AnalyticsModelKeys } from '../models/analyticsModel';
import { CustomerDetailsResponseService, CustomerDetailsProduct } from '../models/customerDetailsModel';
import { BillingSummaryDetail } from '../models/billingSummaryModel';
import { ProductType } from '../contracts/ProductType';
import { MembershipTypeTemp, RoleCodes } from '../common/enums';
import moment from 'moment';

export const createAnalytics = (customerDetailsResponseService: CustomerDetailsResponseService): string => {
  const customerDetails = customerDetailsResponseService.customerDetails;
  const membershipProduct = customerDetails.products.find(
    (product) => product && product.type.toUpperCase() === ProductType.MEMBERSHIP
  );
  let cookieVal;

  // build analytics model
  let hasAutoPay = false;
  if (membershipProduct && membershipProduct.hasAutoPay) {
    hasAutoPay = membershipProduct.hasAutoPay;
  }
  let isInRenewal = false;
  if (membershipProduct && membershipProduct.isInRenewal) {
    isInRenewal = membershipProduct.isInRenewal;
  }

  let model: AnalyticsModel;
  if (customerDetails.person && customerDetails.membershipInfo) {
    model = {
      productCount: customerDetails.numberOfProducts,
      membershipType: mapMembershipLevel(customerDetails.membershipInfo.membershipType),
      hasAutoPay: hasAutoPay,
      isInRenewal: isInRenewal,
      roleCode: mapRoleCode(customerDetails.membershipInfo.roleCode),
      gender: mapGender(customerDetails.person.gender),
      ageRange1: mapAgeRange1(customerDetails.person.dateOfBirth),
      ageRange2: mapAgeRange2(customerDetails.person.dateOfBirth),
      hasEmailAddress: customerDetails?.preferredEmailAddress?.emailAddress?.length > 0,
      emailLastUpdate: mapEmailLastUpdate(customerDetails.preferredEmailAddress.lastUpdateDate),
      yearsAsAAAMember: mapYearsAsAAAMember(customerDetails.membershipInfo.clubJoinYear),
      hasAutoInsurance:
        customerDetails.products.find((product) => product.type === ProductType.AUTO) !== undefined ? true : false,
      hasAutoInsuranceAutoPay: mapProductAutopay(customerDetails.products, ProductType.AUTO),
      hasHomeInsuranceAutoPay: mapProductAutopay(customerDetails.products, ProductType.HOME),
      numberOfAutoPolicies: mapNumberOfAutoInsurance(customerDetails.products),
      hasHomeInsurance: mapHasHomeInsurance(customerDetails.products),
      numberOfHomePolicies: mapNumberOfHomeInsurance(customerDetails.products),
    };
    // map to key:val pairs and generate cookie val
    cookieVal = mapModelToCookieVal(model);
  }

  return cookieVal;
};

export const mapMembershipLevel = (membershipType: string): string => {
  if (!membershipType) return 'cls';

  const membershipEnum = MembershipTypeTemp.findByCode(membershipType);
  if (membershipEnum && membershipEnum.abbr && membershipEnum.abbr !== 'unknown')
    return membershipEnum.abbr.toLowerCase();

  return 'cls';
};

export const mapRoleCode = (membershipRoleCode: string): string => {
  let roleCode = '';

  switch (membershipRoleCode) {
    case RoleCodes.PRIMARY:
      roleCode = 'pri';
      break;
    case RoleCodes.ADULT_ASSOCIATE:
      roleCode = 'adt';
      break;
    case RoleCodes.DEPENDENT_ASSOCIATE_01:
    case RoleCodes.DEPENDENT_ASSOCIATE_02:
    case RoleCodes.DEPENDENT_ASSOCIATE_03:
    case RoleCodes.DEPENDENT_ASSOCIATE_04:
    case RoleCodes.DEPENDENT_ASSOCIATE_05:
      roleCode = 'dep';
      break;
  }

  return roleCode;
};

export const mapGender = (customerGender: string): any => {
  switch (customerGender) {
    case 'M':
      return true;
    case 'F':
      return false;
    default:
      return '';
  }
};

export const mapAgeRange1 = (dob: string): number => {
  const memberAge = getAge(dob);

  if (memberAge === 0) {
    return 0;
  } else if (memberAge <= 35) {
    return 1;
  } else if (memberAge >= 36 && memberAge <= 49) {
    return 2;
  } else if (memberAge >= 50 && memberAge <= 64) {
    return 3;
  } else if (memberAge >= 64) {
    return 4;
  } else {
    return 0;
  }
};

export const mapAgeRange2 = (dob: string): number => {
  const memberAge = getAge(dob);
  let val = -1;
  if (memberAge === 0) {
    val = 0;
  } else if (memberAge <= 17) {
    val = 1;
  } else if (memberAge >= 18 && memberAge <= 24) {
    val = 2;
  } else if (memberAge >= 25 && memberAge <= 34) {
    val = 3;
  } else if (memberAge >= 35 && memberAge <= 44) {
    val = 4;
  } else if (memberAge >= 45 && memberAge <= 54) {
    val = 5;
  } else if (memberAge >= 55 && memberAge <= 64) {
    val = 6;
  } else if (memberAge >= 65 && memberAge <= 74) {
    val = 7;
  } else if (memberAge >= 75 && memberAge <= 79) {
    val = 8;
  } else if (memberAge >= 80) {
    val = 9;
  }
  return val;
};

export const getAge = (dob: string): number => {
  if (!dob) return 0;

  const currentDate = moment();
  const dateOfBirth = moment(dob);

  return currentDate.diff(dateOfBirth, 'years');
};

export const mapEmailLastUpdate = (date: string): number => {
  if (!date) return 0;

  const currentDate = moment();
  const lastUpdateDays = currentDate.diff(moment(date), 'days');
  let val = -1;
  if (lastUpdateDays <= 30) {
    val = 1;
  } else if (lastUpdateDays >= 31 && lastUpdateDays <= 90) {
    val = 2;
  } else if (lastUpdateDays >= 91 && lastUpdateDays <= 180) {
    val = 3;
  } else if (lastUpdateDays >= 181 && lastUpdateDays <= 360) {
    val = 4;
  } else if (lastUpdateDays >= 361 && lastUpdateDays <= 720) {
    val = 5;
  } else if (lastUpdateDays >= 721) {
    val = 6;
  }
  return val;
};

export const mapYearsAsAAAMember = (clubJoinYear: string) => {
  if (!clubJoinYear) return 0;

  const currentYear = moment();
  const joinYear = moment(clubJoinYear);
  const yearsSinceJoinYear = currentYear.diff(joinYear, 'months');
  let val = -1;

  if (yearsSinceJoinYear < 12) {
    val = 1;
  } else if (yearsSinceJoinYear >= 12 && yearsSinceJoinYear < 24) {
    val = 2;
  } else if (yearsSinceJoinYear >= 24 && yearsSinceJoinYear < 72) {
    val = 3;
  } else if (yearsSinceJoinYear >= 72 && yearsSinceJoinYear < 96) {
    val = 4;
  } else if (yearsSinceJoinYear >= 96 && yearsSinceJoinYear < 120) {
    val = 5;
  } else if (yearsSinceJoinYear >= 120) {
    val = 6;
  }
  return val;
};

export const mapHasHomeInsurance = (custDetailsProducts: CustomerDetailsProduct[]): boolean => {
  const homeProduct = custDetailsProducts.find(
    (product) =>
      product.type === ProductType.HOME ||
      product.type === ProductType.CONDO ||
      product.type === ProductType.TENANT ||
      product.type === ProductType.RENTAL_DWELLING
  );

  return homeProduct !== undefined;
};
// Deprecated --
export const mapHasHomeInsuranceAutoPay = (billingSummaryProducts: BillingSummaryDetail[]): boolean => {
  const homeProduct = billingSummaryProducts.find(
    (product) =>
      product &&
      product.productType &&
      product.productType.toLowerCase() === ProductType.HOME.toLowerCase() &&
      product.hasAutoPay
  );

  return homeProduct !== undefined ? true : false;
};

export const mapProductAutopay = (products: CustomerDetailsProduct[], productType: ProductType): boolean => {
  const hasAutoPay = products.find(
    (product) =>
      product &&
      product.type &&
      productType &&
      product.type.toLowerCase() === productType.toLowerCase() &&
      product.hasAutoPay
  );
  return hasAutoPay ? true : false;
};

export const mapNumberOfAutoInsurance = (custDetailsProducts: CustomerDetailsProduct[]): number => {
  const autoProducts = custDetailsProducts.filter(
    (product) => product && product.type && product.type.toLowerCase() === ProductType.AUTO.toLowerCase()
  );

  return autoProducts !== undefined ? autoProducts.length : 0;
};

export const mapNumberOfHomeInsurance = (custDetailsProducts: CustomerDetailsProduct[]): number => {
  const homeProducts = custDetailsProducts.filter(
    (product) => product && product.type && product.type.toLowerCase() === ProductType.HOME.toLowerCase()
  );

  return homeProducts !== undefined ? homeProducts.length : 0;
};

const mapModelToCookieVal = (model: AnalyticsModel): string => {
  const cookieValArray: string[] = [];
  let cookieVal = '';

  for (const key in model) {
    if (model.hasOwnProperty(key)) {
      cookieValArray.push(`${AnalyticsModelKeys[key]}:${model[key]}`);
    }
  }

  cookieVal = cookieValArray.join('$');

  return cookieVal;
};
