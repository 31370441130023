import { combineReducers } from 'redux';
import { userReducer, UserState } from './Reducers/user.reducer';
import { analyticsReducer, AnalyticsState } from './Reducers/analytics.reducer';
import { errorReducer, ErrorState } from './Reducers/error.reducer';
import { CardContainerState, cardContainerReducer } from './Reducers/cardcontainer.reducer';
import { TabContainerState, tabContainerReducer } from './Reducers/tabcontainer.reducer';
import { ModalState, modalReducer } from './Reducers/modal.reducer';

export interface RootState {
  userState: UserState;
  analyticsState: AnalyticsState;
  errorState: ErrorState;
  cardContainerState?: CardContainerState;
  tabContainerState?: TabContainerState;
  modalState?: ModalState,
}
export const rootReducer = combineReducers({
  userState: userReducer,
  analyticsState: analyticsReducer,
  errorState: errorReducer,
  cardContainerState: cardContainerReducer,
  tabContainerState: tabContainerReducer,
  modalState: modalReducer,
});
