import * as actions from '../Actions/error.actions';
import { ServiceError } from 'utils';

export interface ErrorState {
  model: ServiceError;
}

export const initialState = {
  model: null,
};

export const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ERROR:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
