import { appSources, httpInstance } from 'remotes';
import { CookieHelper, handleError, Timer } from 'utils';
import { AceMeta } from 'shared/contracts/AceMeta';
import { RedirectResponseApp } from 'shared/models/redirectModel';
import { tagLogoutReturnUrl } from '../Components/Common/TaggingHelper';
import { v4 as uuid } from 'uuid';
import Cookies from 'universal-cookie';
import { ConfigResponseApp, ClubCodes, clubcode as clubCodeHelper, WebLogLevel, State, DataRegions } from 'shared';
import { FormPostKeyValue } from 'shared/contracts/FormPostKeyValue';
import { LoggerRequestApp, LogType } from 'shared/sources/LoggerContractApp';
import LogCannon from '@clublabs/log-cannon';

const sessionIdKey = 'log-cannon-session-id';
const clubCodeKey = 'clubCode';

const whiteListedQueryParams = ['area', 'pmed', 'cid', 'jid', 'intcmp', 'zipcode'];

export const addTimestampToUrl = (url, addTimestamp = true) => {
  const result = new URL(url);

  if (addTimestamp) {
    const today = Date.now();
    result.searchParams.append('ts', today.toString());
  }

  const region = sessionStorage.getItem('dataRegion');
  if (region) {
    result.searchParams.append('backend', region);
  }

  return result.toString();
};

const getReferrerUrl = (host, configReferrerUrl) => {
  let referrerUrl = configReferrerUrl;
  const imperva_rx = /apps2-preprod-(a|b)/i;
  const imperva_subdomain = imperva_rx.exec(host);

  if (imperva_subdomain && imperva_subdomain.length > 0) {
    referrerUrl = referrerUrl.replace('apps2', imperva_subdomain[0]);
  }

  return referrerUrl;
};

const getLoginUrl = (appConfig: ConfigResponseApp) => {
  if (!appConfig) {
    handleError('WebUtils', new Error('invalid or missing configuration file'), {}, getSessionId());
    return '';
  }

  if (appConfig.authFlow && appConfig.LoginUrl && appConfig.ReferrerURL) {
    const host = window.location.host;
    const clubCode: ClubCodes = getClubCode() || ClubCodes.CA;
    const clubName = clubCodeHelper.findByClubCode(clubCode)?.domain;
    // special case: clubcode is valid, but not "ours"
    if (!clubName) return '';

    const authFlow = appConfig.authFlow || 'token';
    const configLoginUrl = appConfig.LoginUrl;
    const configReferrerUrl = getReferrerUrl(host, appConfig.ReferrerURL).replace('<clubName>', clubName);
    const searchParams = window.location.search ? decodeURIComponent(window.location.search) : ''; //todo: whitelist query params

    const referrerUrl = `${configReferrerUrl}${searchParams}`;
    const encodedReferrerUrl = encodeURIComponent(referrerUrl);
    const encodedReturnUrl = encodeURIComponent(referrerUrl);

    let loginUrl = configLoginUrl
      .replace('<clubName>', clubName)
      .replace('<authFlow>', authFlow)
      .replace('<encodedReferrerUrl>', encodedReferrerUrl)
      .replace('<encodedReturnUrl>', encodedReturnUrl);

    // adding whitelisted params to the login url
    const referenceUrl = window.location.search;
    const queryParams = new URLSearchParams(referenceUrl);
    for (const queryVar of whiteListedQueryParams) {
      if (queryParams.has(queryVar)) {
        loginUrl = WebUtils.addQueryParam(loginUrl, queryVar, queryParams.get(queryVar) ?? '');
      }
    }
    return loginUrl;
  } else {
    return '';
  }
};

const FormPost = (
  formPostKeyValues: FormPostKeyValue[],
  action: string,
  customerId?: string,
  membershipNumber?: string
): boolean => {
  // converting XXXmvc -> XXX2mvc if a region is B1
  const region = sessionStorage.getItem('dataRegion');
  if (region === DataRegions.B1) {
    let actionUrl = new URL(action);
    const regex = /([^\d]+)mvc\./i;
    actionUrl.host = actionUrl.host.replace(regex, '$12mvc.');
    action = actionUrl.toString();
  }

  const url = `${action}&data=${encodeURIComponent(JSON.stringify(formPostKeyValues))}`;
  const { sessionId, aceMeta } = WebUtils.getMetadata({
    customerId,
    membershipNumber,
    clubCode: membershipNumber?.substring(3, 6)
  });
  const request = { aceMeta, url: encodeURIComponent(url) };
  //TODO: add spinner since this is xhr request
  const redirectTimer = new Timer('redirectWeb', sessionId);
  const start = redirectTimer.startTimer();
  const policyInfo = formPostKeyValues.find(payLoad => payLoad.key === 'fullPolicyNumber' || payLoad.key === 'policyNumber');
  LogCannon.blast('redirect - start', { aceMeta, start: start.start, target: action, policyNumber: policyInfo?.value });
  appSources()
    .redirect(request)
    .then((resp: RedirectResponseApp) => {
      const { end, responseTime } = redirectTimer.endTimer();
      LogCannon.blast('redirect - end', { aceMeta, metrics: { start: start.start, end, responseTime }, target: action, policyNumber: policyInfo?.value, status: 'success' });
      clearSessionId();
      const params = new URLSearchParams(resp.data.url);
      const formData = JSON.parse(decodeURIComponent(params.get('data') || '[]'));
      const form = document.createElement('form');
      document.body.appendChild(form);
      form.id = 'testForm';
      form.method = 'POST';
      form.action = action;
      formData.forEach((data) => {
        const input = document.createElement('input');
        input.type = 'text';
        input.name = data.key;
        input.value = data.value;
        form.appendChild(input);
      });
      form.submit();
    })
    .catch((error) => {
      const { end, responseTime } = redirectTimer.endTimer();
      LogCannon.error('redirect - end', { aceMeta, metrics: { start: start.start, end, responseTime }, target: action, policyNumber: policyInfo?.value, status: 'fail', error });
    });
  return true;
};

const FormPostRedirect = (payload: any, url: string, customerId?: string, membershipNumber?: string): boolean => {
  const { aceMeta, sessionId } = getMetadata({ customerId, membershipNumber, clubCode: membershipNumber?.substring(3, 6) });
  const request = { aceMeta, url: encodeURIComponent(url) };
  const redirectTimer = new Timer('formRedirect', sessionId);
  const start = redirectTimer.startTimer();
  const policyNumber = payload?.policyNumber;
  LogCannon.blast('redirect - start', { aceMeta, start: start.start, target: url, policyNumber });
  appSources()
    .redirect(request)
    .then((resp: RedirectResponseApp) => {
      const { end, responseTime } = redirectTimer.endTimer();
      LogCannon.blast('redirect - end', {
        aceMeta,
        metrics: {
          start: start.start,
          end,
          responseTime
        },
        target: url,
        policyNumber,
        status: 'success'
      });
      clearSessionId();

      httpInstance.post(url, payload).then((resp) => {
        let url = resp.data.redirectUrl;
        if (resp.status === 200 && url) {
          window.location.href = url;
        }
      });
    })
    .catch((error) => {
      const { end, responseTime } = redirectTimer.endTimer();
      LogCannon.error('redirect - end', { aceMeta, metrics: { start: start.start, end, responseTime }, target: url, policyNumber, status: 'fail', error });
    });
  return true;
};

const externalLinkHandler = async (
  url: string,
  newWindow: boolean = false,
  bypassRedirect: boolean = false,
  customerId?: string,
  membershipNumber?: string,
  addTimestamp: boolean = true
) => {
  if (bypassRedirect) {
    const fullUrl = WebUtils.addTimestampToUrl(url, addTimestamp);
    newWindow ? window.open(fullUrl, '_blank') : window.location.assign(fullUrl);
  } else {
    const { aceMeta, sessionId } = getMetadata({
      customerId,
      membershipNumber,
      clubCode: membershipNumber?.substring(3, 6)
    });
    const request = { aceMeta: aceMeta, url: encodeURIComponent(url) };
    //TODO: add spinner since this is xhr request
    const redirectTimer = new Timer('redirectWeb', sessionId);
    const start = redirectTimer.startTimer();
    LogCannon.blast('redirect - start', { aceMeta, start: start.start, target: url });
    return appSources()
      .redirect(request)
      .then((resp: RedirectResponseApp) => {
        const { end, responseTime } = redirectTimer.endTimer();
        LogCannon.blast('redirect - end', { aceMeta, metrics: { start: start.start, end, responseTime }, target: url, status: 'success' });
        if (!newWindow) {
          clearSessionId();
        }
        const fullUrl = WebUtils.addTimestampToUrl(resp.data.url, addTimestamp);
        newWindow ? window.open(fullUrl, '_blank') : window.location.assign(fullUrl);
      })
      .catch((error) => {
        const { end, responseTime } = redirectTimer.endTimer();
        LogCannon.error('redirect - end', { aceMeta, metrics: { start: start.start, end, responseTime }, target: url, status: 'fail', error });
        throw error;
      });
  }
};

const logMetrics = (
  message: string,
  data: {
    str: object;
    end: object;
    status: string;
    customerId: string;
    membershipNumber: string;
  },
  aceMeta: AceMeta,
  level?: WebLogLevel
) => {
  const request: LoggerRequestApp = {
    level: level || WebLogLevel.info,
    logType: LogType.METRICS,
    aceMeta,
    message,
    data,
  };
  LogCannon.blast(message, request);
};

const logWeb = (data: { message: string; level: WebLogLevel; aceMeta: AceMeta; data: { [key: string]: any } }) => {
  const request: LoggerRequestApp = {
    ...data,
    logType: LogType.WEB,
  };
  if (data.level === WebLogLevel.error) {
    LogCannon.error(data?.message, request);
  } else {
    LogCannon.blast(data?.message, request);
  }
};

const constructLogData = (payload?: any, customerId: string = '', membershipNumber: string = ''): any => {
  const logData = {
    ...payload,
    customerId,
    membershipNumber,
  };
  return logData;
};

const base64ToByteArray = (base64String: string): Uint8Array => {
  const pdfdata: string = atob(base64String);
  const len = pdfdata ? pdfdata.length : 0;
  const bytes: Array<number> = new Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = pdfdata.charCodeAt(i);
  }
  return new Uint8Array(bytes);
};

const isECNonPA = (clubcode: string | ClubCodes, stateOfResidence: string | State): boolean => {
  return clubcode === ClubCodes.EC && stateOfResidence !== State.PA;
};

const handleLogout = (logoutURL: string, homeURL: string, customerId?: string, membershipNumber?: string) => {
  const url = tagLogoutReturnUrl(logoutURL, homeURL);
  clearSessionId();
  clearClubCode();
  sessionStorage.removeItem('loginUrl');
  WebUtils.externalLinkHandler(url, false, true, customerId, membershipNumber).catch(() => { });
};

const getSessionId = () => {
  let sessionId = sessionStorage.getItem(sessionIdKey);
  if (sessionId) {
    return sessionId;
  }
  sessionId = uuid();
  sessionStorage.setItem(sessionIdKey, sessionId);
  return sessionId;
};

const clearSessionId = () => {
  sessionStorage.removeItem(sessionIdKey);
};

const getClubCode = (): ClubCodes => {
  const clubCode = (sessionStorage.getItem(clubCodeKey) ||
    CookieHelper.getClubCodeFromZipcodeCookie() ||
    getClubCodeFromRegionCookie()) as ClubCodes;
  return clubCode;
};

const clearClubCode = (): void => {
  sessionStorage.removeItem(clubCodeKey);
};

const getClubCodeFromRegionCookie = () => {
  const cookies = new Cookies();
  let clubCode;
  const domain = window.location.hostname;
  // aaa-region cookie name shoule be in configuration ? ? ?
  const clubFromCookie = cookies.get('aaa-region') || 'calif';
  const clubDomain = domain.split('.')[1];
  let club = clubCodeHelper.findByDomain(clubDomain);
  if (club === undefined) {
    club = clubCodeHelper.findByDomain(clubFromCookie);
  }
  clubCode = club.code;
  return clubCode;
};

const getMetadata = (overrideValues?: any): { sessionId: string; aceMeta: AceMeta } => {
  const sessionId: string = getSessionId();
  const aceMeta: AceMeta = {
    sessionId,
    timestamp: new Date().toString(),
    useMock: false,
    customerId: '',
    membershipNumber: '',
    clubCode: '',
    testRegion: sessionStorage.getItem('dataRegion'),
    ...overrideValues,
  };
  return { sessionId, aceMeta };
};

const convertDate = (membershipExpDate: string) => {
  return new Date(membershipExpDate).toISOString().split('T')[0];
};

const isEnabledForClub = (config: any[], club: string) => {
  if (!club) return false;
  if (Object.values(ClubCodes).indexOf(club as ClubCodes) === -1) return false;
  return config?.includes('ALL') || config?.includes(club);
};

const addQueryParam = (url: string, queryParam: string, value: string) => {
  const query = `${queryParam}=${value}`;
  return url += url.includes('?') ? `&${query}` : `?${query}`;
}

export const WebUtils = {
  base64ToByteArray,
  constructLogData,
  addTimestampToUrl,
  getLoginUrl,
  FormPost,
  externalLinkHandler,
  logMetrics,
  logWeb,
  isECNonPA,
  handleLogout,
  getClubCode,
  clearClubCode,
  getClubCodeFromRegionCookie,
  getMetadata,
  getSessionId,
  convertDate,
  isEnabledForClub,
  FormPostRedirect,
  addQueryParam
};
