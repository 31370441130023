import { AppEndpoints } from 'shared';
import apiConfigLoader, { Environments, getEnvironmentFromHost } from '../apis/Loader';

const getApi = (api: string): string => {
  const env: Environments = getEnvironmentFromHost(window.location.hostname);
  const apiConfig = apiConfigLoader.getConfig(env);
  return `${apiConfig.api.host}/${apiConfig.api.endpoint[api]}`;
};

export const appEndpoints: AppEndpoints = {
  awarenessCards: getApi('awarenessCards'),
  config: getApi('config'),
  customerV2: getApi('customerV2'),
  customerDetails: getApi('customer'),
  insuranceOptions: getApi('insuranceOptions'),
  membershipOptions: getApi('membershipOptions'),
  notifications: getApi('notification'),
  redirect: getApi('redirect'),
  travelBooking: getApi('travelBooking'),
  userPreference: getApi('userpreference'),
  customer: getApi('customer-info'),
  products: getApi('products'),
  appConfigV3: getApi('configAppV3'),
};
