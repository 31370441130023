import {
  InsuranceOptionsRequestService,
  InsuranceOptionsResponseService,
  InsuranceOption,
  InsuranceOptionsRequestApp,
  InsuranceOptionsResponseApp,
} from '../models/insuranceOptionsModel';
import { ClubCodes, MvcRedirectKey, MvcRedirectMode } from '../common/enums';
import { loginFixture } from '../fixtures/loginFixture';
import { httpResponseHandlerFixture } from '../fixtures/httpResponseHandlerFixture';

//aut insurance fixture
export const autoInsuranceOptionsFixtureBeforeFormFields: InsuranceOption[] = [
  {
    id: 'PAY_MY_BILL',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
    display: 'Pay my bill',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.ONLINE_BILL_PAY,
    },
  },
  {
    id: 'GET_PROOF_OF_INSURANCE',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Get proof of insurance cards',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
    },
  },
  {
    id: 'COMPLETE_ANNUAL_INSURANCE_QUESTIONNAIRE',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Complete annual insurance questionnaire',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
    },
  },
  {
    id: 'GET_CLAIM_STATUS',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Get claim status',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
    },
  },
  {
    id: 'CHANGE_POLICY',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'View & manage policy',
    subDisplay: ['Change coverages', 'Add or replace a vehicle', 'Remove a vehicle', 'Change a loan or lease company'],
    subDisplayIndented: true,
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
    },
  },
  {
    id: 'CHANGE_MY_CONTACT_INFORMATION',
    value: '',
    display: 'Change my contact information',
    subDisplay: [],
    newWindow: false,
  },
  // {
  //   "id": 7,
  //   "value": "",
  //   "display": "Manage payment plan & Auto Pay",
  //   "subDisplay": [],
  //   "newWindow": false
  // },
  {
    id: 'MANAGE_PAYMENT_PLAN',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Manage payment plan',
    subDisplay: [],
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
    },
  },
  {
    id: 'ENROLL_AUTO_PAY',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
    display: 'Enroll in Auto Pay',
    subDisplay: ['When you enroll, you will also be prompted to pay your current bill'],
    subDisplayIndented: true,
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.ONLINE_BILL_PAY,
    },
  },
  {
    id: 'VIEW_PAYMENT_AND_BILLING_HISTORY',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Billing_Summary',
    display: 'View billing & payment history',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.BILLING_SUMMARY,
    },
  },
  {
    id: 'VIEW_POLICY_DETAILS',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'View policy',
    subDisplay: ['Print policy details', 'View driver & vehicle details'],
    subDisplayIndented: true,
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
    },
  },
  {
    id: 'VIEW_ALL_INSURANCE_DISCOUNTS',
    value: '',
    display: 'View all AAA insurance discounts',
    newWindow: false,
  },
  {
    id: 'DONT_SEE',
    value: '',
    display: "Don't see what you're looking for",
    newWindow: false,
  },
];

export const autoInsuranceOptionsFixtureBillPayFormFields: InsuranceOption[] = [
  {
    id: 'PAY_MY_BILL',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
    display: 'Pay my bill',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.ONLINE_BILL_PAY,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.PAYANDENROLLLINK,
      payBillFields: {
        primaryEmail: 'bruce@email.com',
        customerID: '116310020099992',
        memberNumber: '6202521234567800',
        allowAutoPay: 'true',
        allowPayment: 'true',
        hasExistingSchedPmt: false,
        existingSchedPmtAmtString: '0.00',
        existingSchedPmtDateString: '',
        existingSchedPmtConfNbr: '',
        insMinDue: 'true',
        policyRenewal: 'false',
        insCurrentTerm: '10',
        useSystemAgnosticCall: true,
        cancelSchedPmtExecuted: false,
        clientId: '987654321',
        hasLapseAccidents: '',
      },
    },
  },
  {
    id: 'GET_PROOF_OF_INSURANCE',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Get proof of insurance cards',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'COMPLETE_ANNUAL_INSURANCE_QUESTIONNAIRE',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Complete annual insurance questionnaire',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'GET_CLAIM_STATUS',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Get claim status',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'CHANGE_POLICY',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'View & manage policy',
    subDisplay: ['Change coverages', 'Add or replace a vehicle', 'Remove a vehicle', 'Change a loan or lease company'],
    subDisplayIndented: true,
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'CHANGE_MY_CONTACT_INFORMATION',
    value: '',
    display: 'Change my contact information',
    subDisplay: [],
    newWindow: false,
  },
  {
    id: 'MANAGE_PAYMENT_PLAN',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Manage payment plan',
    subDisplay: [],
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'ENROLL_AUTO_PAY',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
    display: 'Enroll in Auto Pay',
    subDisplay: ['When you enroll, you will also be prompted to pay your current bill'],
    subDisplayIndented: true,
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.ONLINE_BILL_PAY,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.ENROLLLINK,
      payBillFields: {
        primaryEmail: 'bruce@email.com',
        customerID: '116310020099992',
        memberNumber: '6202521234567800',
        allowAutoPay: 'true',
        allowPayment: 'true',
        hasExistingSchedPmt: false,
        existingSchedPmtAmtString: '0.00',
        existingSchedPmtDateString: '',
        existingSchedPmtConfNbr: '',
        insMinDue: 'true',
        policyRenewal: 'false',
        insCurrentTerm: '10',
        useSystemAgnosticCall: true,
        cancelSchedPmtExecuted: false,
        clientId: '987654321',
        hasLapseAccidents: '',
      },
    },
  },
  {
    id: 'VIEW_PAYMENT_AND_BILLING_HISTORY',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Billing_Summary',
    display: 'View billing & payment history',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.BILLING_SUMMARY,
      policyNumber: 'TPA123456789',
      clubCode: ClubCodes.TX,
    },
  },
  {
    id: 'VIEW_POLICY_DETAILS',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'View policy',
    subDisplay: ['Print policy details', 'View driver & vehicle details'],
    subDisplayIndented: true,
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'VIEW_ALL_INSURANCE_DISCOUNTS',
    value: '',
    display: 'View all AAA insurance discounts',
    newWindow: false,
  },
  {
    id: 'DONT_SEE',
    value: '',
    display: "Don't see what you're looking for",
    newWindow: false,
  },
];

export const autoInsuranceOptionsFixture: InsuranceOption[] = [
  {
    id: 'PAY_MY_BILL',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
    display: 'Pay my bill',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.ONLINE_BILL_PAY,
      policyNumber: 'TPA123456789',
    },
  },
  {
    id: 'GET_PROOF_OF_INSURANCE',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Get proof of insurance cards',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'COMPLETE_ANNUAL_INSURANCE_QUESTIONNAIRE',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Complete annual insurance questionnaire',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'GET_CLAIM_STATUS',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Get claim status',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'CHANGE_POLICY',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'View & manage policy',
    subDisplay: ['Change coverages', 'Add or replace a vehicle', 'Remove a vehicle', 'Change a loan or lease company'],
    subDisplayIndented: true,
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'CHANGE_MY_CONTACT_INFORMATION',
    value: '',
    display: 'Change my contact information',
    subDisplay: [],
    newWindow: false,
  },
  // {
  //   "id": 7,
  //   "value": "",
  //   "display": "Manage payment plan & Auto Pay",
  //   "subDisplay": [],
  //   "newWindow": false
  // },
  {
    id: 'MANAGE_PAYMENT_PLAN',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'Manage payment plan',
    subDisplay: [],
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'ENROLL_AUTO_PAY',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Online_Bill_Pay',
    display: 'Enroll in Auto Pay',
    subDisplay: ['When you enroll, you will also be prompted to pay your current bill'],
    subDisplayIndented: true,
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.ONLINE_BILL_PAY,
      policyNumber: 'TPA123456789',
    },
  },
  {
    id: 'VIEW_PAYMENT_AND_BILLING_HISTORY',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Billing_Summary',
    display: 'View billing & payment history',
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.BILLING_SUMMARY,
      policyNumber: 'TPA123456789',
      clubCode: ClubCodes.TX,
    },
  },
  {
    id: 'VIEW_POLICY_DETAILS',
    value: 'https://apps.texas.aaa.com/aceapps/InternalRedirect/proxy/Policy_Details',
    display: 'View policy',
    subDisplay: ['Print policy details', 'View driver & vehicle details'],
    subDisplayIndented: true,
    newWindow: false,
    externalFields: {
      key: MvcRedirectKey.POLICY_DETAILS,
      policyNumber: 'TPA123456789',
      mode: MvcRedirectMode.AUTO,
    },
  },
  {
    id: 'VIEW_ALL_INSURANCE_DISCOUNTS',
    value: '',
    display: 'View all AAA insurance discounts',
    newWindow: false,
  },
  {
    id: 'DONT_SEE',
    value: '',
    display: "Don't see what you're looking for",
    newWindow: false,
  },
];

export const homeInsuranceOptionsFixture: InsuranceOption[] = [
  {
    id: 'PAY_MY_BILL',
    value: '',
    display: 'Pay my bill',
    newWindow: false,
  },
  {
    id: 'GET_PROOF_OF_INSURANCE',
    value: '',
    display: 'Change my contact information',
    subDisplay: [],
    newWindow: false,
  },
  // {
  //   "id": "",
  //   "value": "",
  //   "display": "Manage payment plan & Auto Pay",
  //   "subDisplay": [],
  //   "newWindow": false
  // },
  {
    id: 'VIEW_PAYMENT_AND_BILLING_HISTORY',
    value: '',
    display: 'View billing & payment history',
    newWindow: false,
  },
  {
    id: 'VIEW_POLICY_DETAILS',
    value: '',
    display: 'View Policy',
    subDisplay: ['Print policy details'],
    subDisplayIndented: true,
    newWindow: false,
  },
  {
    id: 'VIEW_ALL_INSURANCE_DISCOUNTS',
    value: '',
    display: 'View all AAA insurance discounts',
    newWindow: false,
  },
  {
    id: 'DONT_SEE',
    value: '',
    display: "Don't see what you're looking for",
    newWindow: false,
  },
];

export const watercraftInsuranceOptionsFixture: InsuranceOption[] = [
  {
    id: 'PAY_MY_BILL',
    value: '',
    display: 'Pay my bill',
    newWindow: false,
  },
  {
    id: 'GET_PROOF_OF_INSURANCE',
    value: '',
    display: 'Change my contact information',
    subDisplay: [],
    newWindow: false,
  },
  // {
  //   "id": "",
  //   "value": "",
  //   "display": "Manage payment plan & Auto Pay",
  //   "subDisplay": [],
  //   "newWindow": false
  // },
  {
    id: 'VIEW_PAYMENT_AND_BILLING_HISTORY',
    value: '',
    display: 'View billing & payment history',
    newWindow: false,
  },
  {
    id: 'VIEW_ALL_INSURANCE_DISCOUNTS',
    value: '',
    display: 'View all AAA insurance discounts',
    newWindow: false,
  },
  {
    id: 'DONT_SEE',
    value: '',
    display: "Don't see what you're looking for",
    newWindow: false,
  },
];

export const umbrellaInsuranceOptionsFixture: InsuranceOption[] = [
  {
    id: 'PAY_MY_BILL',
    value: '',
    display: 'Pay my bill',
    newWindow: false,
  },
  {
    id: 'CHANGE_MY_CONTACT_INFORMATION',
    value: '',
    display: 'Change my contact information',
    subDisplay: [],
    newWindow: false,
  },
  // {
  //   "id": "",
  //   "value": "",
  //   "display": "Manage payment plan & Auto Pay",
  //   "subDisplay": [],
  //   "newWindow": false
  // },
  // {
  //   "id": 4,
  //   "value": "",
  //   "display": "View payment history",
  //   "newWindow": false
  // },
  // {
  //   "id": 5,
  //   "value": "",
  //   "display": "View billing history",
  //   "newWindow": false
  // },
  {
    id: 'DONT_SEE',
    value: '',
    display: "Don't see what you're looking for",
    newWindow: false,
  },
];

export const CEAInsuranceOptionsFixture: InsuranceOption[] = [
  {
    id: 'PAY_MY_BILL',
    value: '',
    display: 'Pay my bill',
    newWindow: false,
  },
  {
    id: 'GET_PROOF_OF_INSURANCE',
    value: '',
    display: 'Change my contact information',
    subDisplay: ['One of our specialists can assist you.', '1-000-000-0000'],
    newWindow: false,
  },
  // {
  //   "id": "",
  //   "value": "",
  //   "display": "Manage payment plan & Auto Pay",
  //   "subDisplay": [
  //     "One of our specialists can assist you.",
  //     "1-000-000-0000"
  //   ],
  //   "newWindow": false
  // },
  {
    id: 'VIEW_PAYMENT_AND_BILLING_HISTORY',
    value: '',
    display: 'View billing & payment history',
    newWindow: false,
  },
  {
    id: 'VIEW_ALL_INSURANCE_DISCOUNTS',
    value: '',
    display: 'View all AAA insurance discounts',
    newWindow: false,
  },
  {
    id: 'DONT_SEE',
    value: '',
    display: "Don't see what you're looking for",
    newWindow: false,
  },
];

export const classicInsuranceOptionsFixture: InsuranceOption[] = [
  {
    id: 'PAY_MY_BILL',
    value: '',
    display: 'Pay my bill',
    newWindow: false,
  },
  {
    id: 'GET_PROOF_OF_INSURANCE',
    value: '',
    display: 'Get claim status',
    newWindow: false,
  },
  {
    id: 'COMPLETE_ANNUAL_INSURANCE_QUESTIONNAIRE',
    value: '',
    display: 'Change my contact information',
    subDisplay: ['One of our specialists can assist you.', '1-000-000-0000'],
    newWindow: false,
  },
  {
    id: 'MANAGE_PAYMENT_PLAN',
    value: '',
    display: 'Manage payment plan',
    subDisplay: ['One of our specialists can assist you.', '1-000-000-0000'],
    newWindow: false,
  },
  // {
  //   "id": 5,
  //   "value": "",
  //   "display": "Manage auto pay",
  //   "subDisplay": [
  //     "One of our specialists can assist you.",
  //     "1-000-000-0000"
  //   ],
  //   "newWindow": false
  // },
  {
    id: 'VIEW_PAYMENT_AND_BILLING_HISTORY',
    value: '',
    display: 'View billing & payment history',
    newWindow: false,
  },
  {
    id: 'DONT_SEE',
    value: '',
    display: "Don't see what you're looking for",
    newWindow: false,
  },
];

export const insuranceOptionsRequestAppFixture: InsuranceOptionsRequestApp = {
  aceMeta: {
    sessionId: 'some-correlation-id',
    timestamp: '2022-02-16T23:36:49.237Z',
    useMock: false
  },
  login: loginFixture,
  policyNumber: 'string',
  effectiveDate: '01/01/1901',
};

export const insuranceOptionsResponseAppFixture: InsuranceOptionsResponseApp = {
  aceMeta: {
    sessionId: 'some-correlation-id',
    timestamp: '2022-02-16T23:36:49.237Z',
    useMock: false
  },
  httpResponseHandler: httpResponseHandlerFixture,
  insuranceOptions: autoInsuranceOptionsFixture,
};

// generic service request fixture for all insurance options
export const insuranceOptionsRequestServiceFixture: InsuranceOptionsRequestService = {
  customerId: '116310020099992',
  clubCode: ClubCodes.TX,
  policyNumber: 'string',
  effectiveDate: '01/01/1901',
};

export const insuranceOptionsRequestBody = {
  login: {
    customerId: '116310020099992',
    clubCode: ClubCodes.TX,
  },
  policyNumber: 'string',
  effectiveDate: '01/01/1901',
};

export const autoInsuranceOptionsResponseServiceFixture: InsuranceOptionsResponseService = {
  insuranceOptions: autoInsuranceOptionsFixture,
};

export const homeInsuranceOptionsResponseServiceFixture: InsuranceOptionsResponseService = {
  insuranceOptions: homeInsuranceOptionsFixture,
};

export const watercraftInsuranceOptionsResponseServiceFixture: InsuranceOptionsResponseService = {
  insuranceOptions: watercraftInsuranceOptionsFixture,
};

export const umbrellaInsuranceOptionsResponseServiceFixture: InsuranceOptionsResponseService = {
  insuranceOptions: umbrellaInsuranceOptionsFixture,
};

export const CEAInsuranceOptionsResponseServiceFixture: InsuranceOptionsResponseService = {
  insuranceOptions: CEAInsuranceOptionsFixture,
};

export const classicInsuranceOptionsResponseServiceFixture: InsuranceOptionsResponseService = {
  insuranceOptions: classicInsuranceOptionsFixture,
};
